<template>
  <v-row no-gutters class="fill-height mr-n1 mr-sm-n1 mx-xl-n16 mx-lg-n16">
    <v-col
      v-if="UnplannedPostTrueOrFalse"
      lg="2"
      xl="2"
      class="hidden-md-and-down mr-2"
    >
      <UnplannedPost
        :cardItems="cardItems"
        @newPost="newPost"
        @OpenAndCloseUnplannedPost="OpenAndCloseUnplannedPost"
      /> </v-col
    ><v-dialog
      max-width="500"
      v-model="UnplannedPostTrueOrFalseMobileVersion"
      class="hidden-lg-and-up"
    >
      <UnplannedPostMobileVersion
        :cardItems="cardItems"
        @newPost="newPost"
        @OpenAndCloseUnplannedPostMobileVersion="
          OpenAndCloseUnplannedPostMobileVersion
        "
    /></v-dialog>
    <v-col>
      <div class="calendar-month">
        <div class="calendar-month-header">
          <CalendarDateSelector
            ref="CalendarDateSelector"
            class="calendar-month-header-selected-month"
            style="box-shadow: 4px 6px 6px rgba(0, 0, 0, 0.06); !important; "
            :current-date="today"
            :selected-date="selectedDate"
            @dateSelected="selectDate"
            @filter="cardItemsFilter"
            @OpenAndCloseUnplannedPost="OpenAndCloseUnplannedPost"
            @OpenAndCloseUnplannedPostMobileVersion="
              OpenAndCloseUnplannedPostMobileVersion
            "
          />
        </div>
        <CalendarWeekdays class="hidden-md-and-down pt-16" />
        <ol class="hidden-md-and-down days-grid ml-n6">
          <CalendarMonthDayItem
            v-for="day in days"
            :key="day.date"
            :day="day"
            :is-today="day.date === today"
            :cardItems="cardItems"
            @newPost="newPost"
          />
        </ol>

        <ol class="hidden-lg-and-up days-grid ml-n6 pt-16">
          <CalendarMonthDayItemVersionMobile
            v-for="day in days"
            :key="day.date"
            :day="day"
            :is-today="day.date === today"
            :cardItems="cardItems"
            @newPost="newPost"
          />
        </ol>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import CalendarMonthDayItem from "./CalendarMonthDayItem";
import CalendarMonthDayItemVersionMobile from "./CalendarMonthDayItemVersionMobile";
import CalendarDateIndicator from "./CalendarDateIndicator";
import CalendarDateSelector from "./CalendarDateSelector";
import CalendarWeekdays from "./CalendarWeekdays";
import UnplannedPost from "./UnplannedPost";
import UnplannedPostMobileVersion from "./UnplannedPostMobileVersion";
const fb = require("@/firebase/init.js");
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export default {
  name: "CalendarMonth",
  components: {
    CalendarMonthDayItem,
    CalendarMonthDayItemVersionMobile,
    CalendarDateIndicator,
    CalendarDateSelector,
    CalendarWeekdays,
    UnplannedPost,
    UnplannedPostMobileVersion,
  },
  data() {
    return {
      cardItems: [],
      cardItemsTemp: [],
      selectedDate: dayjs(),
      toFilterArrayTemp: [],
      UnplannedPostTrueOrFalse: false,
      UnplannedPostTrueOrFalseMobileVersion: false,
    };
  },
  created() {
    const self = this;
    fb.db
      .collection("clients")
      .doc(fb.firebase.auth().currentUser.uid)
      .collection("cards")
      .get()
      .then((snapshot) => {
        self.cardItems = [];
        snapshot.forEach((doc) => {
          let cardUnit = doc.data();
          cardUnit.id = doc.id;
          self.cardItems.push(cardUnit);
          self.cardItemsTemp.push(cardUnit);
        });
      })
      .catch((error) => {
        console.log("Error in created(): ", error);
      });
  },
  computed: {
    currentMonthDays() {
      return [...Array(this.numberOfDaysInMonth)].map((day, index) => {
        return {
          date: dayjs(`${this.year}-${this.month}-${index + 1}`).format(
            "YYYY-MM-DD"
          ),
          isCurrentMonth: true,
        };
      });
    },
    days() {
      return [
        ...this.previousMonthDays,
        ...this.currentMonthDays,
        ...this.nextMonthDays,
      ];
    },
    month() {
      return Number(this.selectedDate.format("M"));
    },
    nextMonthDays() {
      const lastDayOfTheMonthWeekday = this.getWeekday(
        `${this.year}-${this.month}-${this.currentMonthDays.length}`
      );
      const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, "month");
      const visibleNumberOfDaysFromNextMonth = lastDayOfTheMonthWeekday
        ? 7 - lastDayOfTheMonthWeekday
        : lastDayOfTheMonthWeekday;
      return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
        return {
          date: dayjs(
            `${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`
          ).format("YYYY-MM-DD"),
          isCurrentMonth: false,
        };
      });
    },
    numberOfDaysInMonth() {
      return dayjs(this.selectedDate).daysInMonth();
    },
    previousMonthDays() {
      const firstDayOfTheMonthWeekday = this.getWeekday(
        this.currentMonthDays[0].date
      );
      const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(
        1,
        "month"
      );
      // Cover first day of the month being sunday (firstDayOfTheMonthWeekday === 0)
      const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
        ? firstDayOfTheMonthWeekday - 1
        : 6;

      const previousMonthLastMondayDayOfMonth = dayjs(
        this.currentMonthDays[0].date
      )
        .subtract(visibleNumberOfDaysFromPreviousMonth, "day")
        .date();

      return [...Array(visibleNumberOfDaysFromPreviousMonth)].map(
        (day, index) => {
          return {
            date: dayjs(
              `${previousMonth.year()}-${previousMonth.month() + 1}-${
                previousMonthLastMondayDayOfMonth + index
              }`
            ).format("YYYY-MM-DD"),
            isCurrentMonth: false,
          };
        }
      );
    },
    today() {
      return dayjs().format("YYYY-MM-DD");
    },
    year() {
      return Number(this.selectedDate.format("YYYY"));
    },
  },

  methods: {
    cardItemsFilter(toFilterArray) {
      if (toFilterArray[0] === "reset") {
        this.toFilterArrayTemp = [];
        this.newPost();
      } else {
        this.toFilterArrayTemp = toFilterArray;
        this.newPost();
      }
    },
    getWeekday(date) {
      return dayjs(date).weekday();
    },
    newPost() {
      const self = this;
      if (this.toFilterArrayTemp.length > 0) {
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .collection("cards")
          .get()
          .then((snapshot) => {
            self.cardItems = [];
            snapshot.forEach((doc) => {
              let cardUnit = doc.data();
              cardUnit.id = doc.id;
              self.cardItems.push(cardUnit);
              self.cardItemsTemp.push(cardUnit);
              let cardItemsFilter = self.cardItems;
              for (
                let index = 0;
                index < this.toFilterArrayTemp.length;
                index++
              ) {
                if (this.toFilterArrayTemp[index] === false) {
                } else {
                  if (index === 0) {
                    cardItemsFilter = cardItemsFilter.filter(
                      (item) =>
                        item.emailInstagram === this.toFilterArrayTemp[index]
                    );
                  } else if (index === 1) {
                    cardItemsFilter = cardItemsFilter.filter(
                      (item) => item.categorie === this.toFilterArrayTemp[index]
                    );
                  } else if (index === 2) {
                    cardItemsFilter = cardItemsFilter.filter(
                      (item) => item.format === this.toFilterArrayTemp[index]
                    );
                  }
                }
              }
              this.cardItems = cardItemsFilter;
            });
          })
          .catch((error) => {
            console.log("Error in newPost: ", error);
          });
      } else {
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .collection("cards")
          .get()
          .then((snapshot) => {
            self.cardItems = [];
            snapshot.forEach((doc) => {
              let cardUnit = doc.data();
              cardUnit.id = doc.id;
              self.cardItems.push(cardUnit);
              self.cardItemsTemp.push(cardUnit);
            });
          })
          .catch((error) => {
            console.log("Error in newPost: ", error);
          });
      }
    },
    OpenAndCloseUnplannedPost() {
      if (this.UnplannedPostTrueOrFalse) {
        this.$refs.CalendarDateSelector.openAndCloseTimerSand();
      }
      this.UnplannedPostTrueOrFalse = !this.UnplannedPostTrueOrFalse;
    },
    OpenAndCloseUnplannedPostMobileVersion() {
      this.UnplannedPostTrueOrFalseMobileVersion =
        !this.UnplannedPostTrueOrFalseMobileVersion;
    },
    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },
  },
};
</script>
<style scoped>
.calendar-month {
  position: relative;
  background-color: #fafafa;
  border: solid 1px #dfe0eb;
  border-top: solid 0px #dfe0eb;
}
.day-of-week {
  color: var(--grey-800);
  font-size: 18px;
  background-color: #fafafa;
  padding-bottom: 5px;
  padding-top: 10px;
  text-align: right;
  padding-right: 5px;
}
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 100%;
  position: relative;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
}
</style>
