<template>
  <v-container class="">
    <v-sheet class="hidden-md-and-up" elevation="1"
      ><v-divider></v-divider
      ><CaptionViewBar
        ref="CaptionViewBarMobile"
        class="caption-view-bar-mobile"
        @filter="filter"
        @filterByNewest="filterByNewest"
        @filterByUse="filterByUse"
    /></v-sheet>
    <v-sheet style="background: #f5f5f5" class="mx-n3">
      <CaptionViewBar
        ref="CaptionViewBar"
        class="mb-n2 calendar-month hidden-sm-and-down"
        @filter="filter"
        @filterByNewest="filterByNewest"
        @filterByUse="filterByUse"
      />
      <v-col class="pt-16 hidden-sm-and-up" cols="12"><h4>Templates</h4></v-col>

      <v-row
        v-if="firstArray.length > 0"
        class="carrousel-mobile pt-3 hidden-sm-and-up mx-4"
        dense
      >
        <v-col cols="6">
          <v-card
            v-for="(item, index) in firstArrayMobile"
            :key="index"
            class="mt-2"
            elevation="1"
          >
            <v-img
              v-if="typeof item.url === 'string'"
              class="white--text align-end"
              min-height="90"
              max-height="180"
              :src="item.url"
            >
            </v-img>
            <v-carousel
              v-else-if="typeof item.url === 'object'"
              hide-delimiters
              show-arrows-on-hover
              height="180"
            >
              <template v-slot:next="{ on, attrs }">
                <v-btn class="" icon small v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-arrow-right</v-icon></v-btn
                >
              </template>
              <template v-slot:prev="{ on, attrs }">
                <v-btn class="" icon small v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-arrow-left</v-icon></v-btn
                >
              </template>

              <v-carousel-item
                v-for="(itemCarrousel, i) in item.url"
                :key="i"
                :src="itemCarrousel"
                reverse-transition="fade-transition"
                transition="fade-transition"
                min-height="90"
                max-height="180"
                class="white--text align-end"
              >
              </v-carousel-item>
            </v-carousel>
            <div v-if="typeof item.url === 'object'" class="mt-n6">
              <v-icon
                v-for="(itemCircle, i) in item.url"
                :key="i"
                color="white"
                class="mx-n2"
                >mdi-circle-small
              </v-icon>
            </div>
            <v-card-actions>
              <v-chip x-small color="cyan accent-1 ml-1" label>{{
                item.format
              }}</v-chip>
              <v-chip x-small color="deep-purple lighten-4" class="ml-1" label>
                {{ item.categorie }}
              </v-chip></v-card-actions
            >

            <v-card-text class="font-text-card caption mt-n3 ml-n1">
              {{ truncCaption(item.caption) }}</v-card-text
            >
            <v-card-actions class="mt-n3 pb-1 mr-2 pb-4"
              ><v-spacer></v-spacer>
              <ModifyCaption :item="item" />
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            v-for="(item, index) in secondArrayMobile"
            :key="index"
            elevation="1"
            class="mt-2"
          >
            <v-img
              v-if="typeof item.url === 'string'"
              class="white--text align-end"
              min-height="90"
              max-height="180"
              :src="item.url"
            >
            </v-img>
            <v-carousel
              v-else-if="typeof item.url === 'object'"
              hide-delimiters
              show-arrows-on-hover
              height="180"
            >
              <template v-slot:next="{ on, attrs }">
                <v-btn class="" icon small v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-arrow-right</v-icon></v-btn
                >
              </template>
              <template v-slot:prev="{ on, attrs }">
                <v-btn class="" icon small v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-arrow-left</v-icon></v-btn
                >
              </template>

              <v-carousel-item
                v-for="(itemCarrousel, i) in item.url"
                :key="i"
                :src="itemCarrousel"
                reverse-transition="fade-transition"
                transition="fade-transition"
                min-height="90"
                max-height="180"
                class="white--text align-end"
              >
              </v-carousel-item>
            </v-carousel>
            <div v-if="typeof item.url === 'object'" class="mt-n6">
              <v-icon
                v-for="(itemCircle, i) in item.url"
                :key="i"
                color="white"
                class="mx-n2"
                >mdi-circle-small
              </v-icon>
            </div>

            <v-card-actions>
              <v-chip x-small color="cyan accent-1 ml-1" label>{{
                item.format
              }}</v-chip>
              <v-chip x-small color="deep-purple lighten-4" class="ml-1" label>
                {{ item.categorie }}
              </v-chip></v-card-actions
            >

            <v-card-text class="font-text-card caption mt-n3 ml-n1">
              {{ truncCaption(item.caption) }}</v-card-text
            >
            <v-card-actions class="mt-n3 pb-1 mr-2 pb-4"
              ><v-spacer></v-spacer>
              <ModifyCaption :item="item" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="mx-n3 pl-6 pt-4 hidden-sm-and-up" no-gutters>
        <p>
          Vous n’avez pas encore d’élément utilisé, mais c’est ici qu’ils
          apparaitront dès que ce sera le cas.
          <a @click="filterByUse()" style="color: #6868a8"
            ><strong>Revenir en arrière</strong></a
          >
        </p>
      </v-row>
      <v-row
        v-if="firstArray.length > 0"
        class="carrousel mx-n3 pt-4 hidden-xs-only"
        no-gutters
      >
        <v-col cols="3" class="ml-md-n2"
          ><v-col v-for="(item, index) in firstArray" :key="index" class="mt-2">
            <v-card min-width="215" max-width="750" elevation="1" class="mx-1">
              <v-img
                v-if="typeof item.url === 'string'"
                class="white--text align-end"
                min-height="90"
                max-height="500"
                :src="item.url"
              >
              </v-img>
              <v-carousel
                v-else-if="typeof item.url === 'object'"
                hide-delimiters
                show-arrows-on-hover
                height="250"
              >
                <template v-slot:next="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </template>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-left</v-icon></v-btn
                  >
                </template>

                <v-carousel-item
                  v-for="(itemCarrousel, i) in item.url"
                  :key="i"
                  :src="itemCarrousel"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  min-height="90"
                  max-height="250"
                  class="white--text align-end"
                >
                </v-carousel-item>
              </v-carousel>
              <div v-if="typeof item.url === 'object'" class="mt-n6">
                <v-icon
                  v-for="(itemCircle, i) in item.url"
                  :key="i"
                  color="white"
                  class="mx-n2"
                  >mdi-circle-small
                </v-icon>
              </div>

              <v-card-subtitle class="">
                <v-row dense>
                  <v-chip
                    small
                    color="cyan accent-1"
                    class="hidden-md-and-down mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="hidden-md-and-down"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                  <v-chip
                    x-small
                    color=" cyan accent-1"
                    class="hidden-lg-and-up mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    x-small
                    color="deep-purple lighten-4"
                    class="hidden-lg-and-up"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="font-text-card caption mt-n2">
                {{ item.caption }}</v-card-text
              >
              <v-card-actions class="mt-n4 pb-2"
                ><v-spacer></v-spacer>
                <ModifyCaption :item="item" />
              </v-card-actions>
            </v-card> </v-col
        ></v-col>
        <v-col cols="3"
          ><v-col
            v-for="(item, index) in secondArray"
            :key="index"
            class="mt-2"
          >
            <v-card min-width="215" max-width="750" elevation="1" class="mx-1">
              <v-img
                v-if="typeof item.url === 'string'"
                class="white--text align-end"
                min-height="90"
                max-height="400"
                :src="item.url"
              >
              </v-img>
              <v-carousel
                v-else-if="typeof item.url === 'object'"
                hide-delimiters
                show-arrows-on-hover
                height="250"
              >
                <template v-slot:next="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </template>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-left</v-icon></v-btn
                  >
                </template>

                <v-carousel-item
                  v-for="(itemCarrousel, i) in item.url"
                  :key="i"
                  :src="itemCarrousel"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  min-height="90"
                  max-height="250"
                  class="white--text align-end"
                >
                </v-carousel-item>
              </v-carousel>
              <div v-if="typeof item.url === 'object'" class="mt-n6">
                <v-icon
                  v-for="(itemCircle, i) in item.url"
                  :key="i"
                  color="white"
                  class="mx-n2"
                  >mdi-circle-small
                </v-icon>
              </div>

              <v-card-subtitle class="">
                <v-row dense>
                  <v-chip
                    small
                    color="cyan accent-1"
                    class="hidden-md-and-down mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="hidden-md-and-down"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                  <v-chip
                    x-small
                    color=" cyan accent-1"
                    class="hidden-lg-and-up mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    x-small
                    color="deep-purple lighten-4"
                    class="hidden-lg-and-up"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="font-text-card caption mt-n2">
                {{ item.caption }}</v-card-text
              >
              <v-card-actions class="mt-n4 pb-2"
                ><v-spacer></v-spacer>
                <ModifyCaption :item="item" />
              </v-card-actions>
            </v-card> </v-col
        ></v-col>
        <v-col cols="3" class="ml-lg-1"
          ><v-col v-for="(item, index) in thirdArray" :key="index" class="mt-2">
            <v-card min-width="215" max-width="750" elevation="1" class="mx-1">
              <v-img
                v-if="typeof item.url === 'string'"
                class="white--text align-end"
                min-height="90"
                max-height="400"
                :src="item.url"
              >
              </v-img>
              <v-carousel
                v-else-if="typeof item.url === 'object'"
                hide-delimiters
                show-arrows-on-hover
                height="250"
              >
                <template v-slot:next="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </template>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-left</v-icon></v-btn
                  >
                </template>

                <v-carousel-item
                  v-for="(itemCarrousel, i) in item.url"
                  :key="i"
                  :src="itemCarrousel"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  min-height="90"
                  max-height="250"
                  class="white--text align-end"
                >
                </v-carousel-item>
              </v-carousel>
              <div v-if="typeof item.url === 'object'" class="mt-n6">
                <v-icon
                  v-for="(itemCircle, i) in item.url"
                  :key="i"
                  color="white"
                  class="mx-n2"
                  >mdi-circle-small
                </v-icon>
              </div>

              <v-card-subtitle class="">
                <v-row dense>
                  <v-chip
                    small
                    color="cyan accent-1"
                    class="hidden-md-and-down mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="hidden-md-and-down"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                  <v-chip
                    x-small
                    color=" cyan accent-1"
                    class="hidden-lg-and-up mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    x-small
                    color="deep-purple lighten-4"
                    class="hidden-lg-and-up"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="font-text-card caption mt-n2">
                {{ item.caption }}</v-card-text
              >
              <v-card-actions class="mt-n4 pb-2"
                ><v-spacer></v-spacer>
                <ModifyCaption :item="item" />
              </v-card-actions>
            </v-card> </v-col
        ></v-col>
        <v-col cols="3" class="ml-lg-1"
          ><v-col
            v-for="(item, index) in fourthArray"
            :key="index"
            class="mt-2"
          >
            <v-card min-width="215" max-width="750" elevation="1" class="mx-1">
              <v-img
                v-if="typeof item.url === 'string'"
                class="white--text align-end"
                min-height="90"
                max-height="400"
                :src="item.url"
              >
              </v-img>
              <v-carousel
                v-else-if="typeof item.url === 'object'"
                hide-delimiters
                show-arrows-on-hover
                height="250"
              >
                <template v-slot:next="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-right</v-icon></v-btn
                  >
                </template>
                <template v-slot:prev="{ on, attrs }">
                  <v-btn class="" icon small v-bind="attrs" v-on="on"
                    ><v-icon small>mdi-arrow-left</v-icon></v-btn
                  >
                </template>

                <v-carousel-item
                  v-for="(itemCarrousel, i) in item.url"
                  :key="i"
                  :src="itemCarrousel"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                  min-height="90"
                  max-height="250"
                  class="white--text align-end"
                >
                </v-carousel-item>
              </v-carousel>
              <div v-if="typeof item.url === 'object'" class="mt-n6">
                <v-icon
                  v-for="(itemCircle, i) in item.url"
                  :key="i"
                  color="white"
                  class="mx-n2"
                  >mdi-circle-small
                </v-icon>
              </div>

              <v-card-subtitle class="">
                <v-row dense>
                  <v-chip
                    small
                    color="cyan accent-1"
                    class="hidden-md-and-down mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="hidden-md-and-down"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                  <v-chip
                    x-small
                    color=" cyan accent-1"
                    class="hidden-lg-and-up mr-1"
                    label
                  >
                    {{ item.format }} </v-chip
                  ><v-chip
                    x-small
                    color="deep-purple lighten-4"
                    class="hidden-lg-and-up"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="font-text-card caption mt-n2">
                {{ item.caption }}</v-card-text
              >
              <v-card-actions class="mt-n4 pb-2"
                ><v-spacer></v-spacer>
                <ModifyCaption :item="item" />
              </v-card-actions>
            </v-card> </v-col
        ></v-col>
      </v-row>
      <v-row v-else class="carrousel mx-n3 pl-4 pt-4 hidden-xs-only" no-gutters>
        <p>
          Vous n’avez pas encore d’élément utilisé, mais c’est ici qu’ils
          apparaitront dès que ce sera le cas.
          <a @click="filterByUse()" style="color: #6868a8"
            ><strong>Revenir en arrière</strong></a
          >
        </p>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import ModifyCaption from "@/components/ModifyCaption.vue";
import CaptionViewBar from "./CaptionViewBar";
const fb = require("@/firebase/init.js");

export default {
  name: "CaptionView",
  components: {
    CaptionViewBar,
    ModifyCaption,
  },
  data() {
    return {
      captionList: [],
      firstArray: [],
      firstArrayMobile: [],
      secondArray: [],
      secondArrayMobile: [],
      thirdArray: [],
      fourthArray: [],
      recentOrReset: false,
      useOrReset: false,
    };
  },
  created() {
    const self = this;
    // Récupérération et répartition des captions stockées dans la banque de captions
    fb.db
      .collection("captions")
      .get()
      .then((snapshot) => {
        self.captionList = [];
        snapshot.forEach((doc) => {
          let imgUnit = doc.data();
          imgUnit.id = doc.id;
          self.captionList.push(imgUnit);
        });
      })
      .then(() => {
        let countMobile = 0;
        for (const [key, value] of Object.entries(self.captionList)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }
        let count = 0;
        for (const [key, value] of Object.entries(self.captionList)) {
          if (count === 0) {
            self.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            self.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            self.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            self.fourthArray.push(value);
            count = 0;
          }
        }
      })
      .catch((error) => {
        console.log("Error in created(): ", error);
      });
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    truncCaption(str) {
      return str.length > 160 ? str.substr(0, 160 - 1) + "..." : str;
    },
    filter(firstArrayToFilter) {
      this.firstArrayMobile = [];
      this.secondArrayMobile = [];
      this.firstArray = [];
      this.secondArray = [];
      this.thirdArray = [];
      this.fourthArray = [];
      let captionListTempBis = [];
      captionListTempBis = structuredClone(this.captionList);
      for (let index = 0; index < firstArrayToFilter.length; index++) {
        if (firstArrayToFilter[index] === false) {
        } else {
          if (index === 0) {
            captionListTempBis = captionListTempBis.filter(
              (item) => item.categorie === firstArrayToFilter[index]
            );
          } else if (index === 1) {
            captionListTempBis = captionListTempBis.filter(
              (item) => item.format === firstArrayToFilter[index]
            );
          }
        }
      }
      let countMobile = 0;
      for (const [key, value] of Object.entries(captionListTempBis)) {
        if (countMobile === 0) {
          this.firstArrayMobile.push(value);
          countMobile = 1;
        } else if (countMobile === 1) {
          this.secondArrayMobile.push(value);
          countMobile = 0;
        }
      }
      let count = 0;
      for (const [key, value] of Object.entries(captionListTempBis)) {
        if (count === 0) {
          this.firstArray.push(value);
          count = 1;
        } else if (count === 1) {
          this.secondArray.push(value);
          count = 2;
        } else if (count === 2) {
          this.thirdArray.push(value);
          count = 3;
        } else if (count === 3) {
          this.fourthArray.push(value);
          count = 0;
        }
      }
    },
    filterByNewest() {
      if (this.recentOrReset) {
        this.recentOrReset = false;
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];
        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];

        let countMobile = 0;
        for (const [key, value] of Object.entries(this.captionList)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }

        let count = 0;
        for (const [key, value] of Object.entries(this.captionList)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      } else {
        this.recentOrReset = true;
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];
        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];
        let imgListTempBis = [];
        imgListTempBis = structuredClone(this.captionList);
        function compare(a, b) {
          if (a.date.seconds < b.date.seconds) {
            return 1;
          }
          if (a.date.seconds > b.date.seconds) {
            return -1;
          }
          return 0;
        }
        imgListTempBis.sort(compare);

        let countMobile = 0;
        for (const [key, value] of Object.entries(imgListTempBis)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }
        let count = 0;
        for (const [key, value] of Object.entries(imgListTempBis)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      }
    },
    filterByUse() {
      if (this.useOrReset) {
        this.useOrReset = false;
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];
        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];

        let countMobile = 0;
        for (const [key, value] of Object.entries(this.captionList)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }

        let count = 0;
        for (const [key, value] of Object.entries(this.captionList)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      } else {
        this.useOrReset = true;
        let listOfImageUseFilter = [];
        for (let index = 0; index < this.captionList.length; index++) {
          for (let i = 0; i < this.user.tagsCaption.length; i++) {
            if (this.captionList[index].id === this.user.tagsCaption[i]) {
              listOfImageUseFilter.push(this.captionList[index]);
            }
          }
        }
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];

        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];
        let countMobile = 0;
        for (const [key, value] of Object.entries(listOfImageUseFilter)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }
        let count = 0;
        for (const [key, value] of Object.entries(listOfImageUseFilter)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.calendar-month {
  position: relative;
  background-color: white;
  height: 70px;
  border: solid 2px var(--grey-100);
  border-radius: 5px;
}
.carrousel {
  position: relative;
  background-color: #fafafa;
  border: inherit;
  border: solid 2px var(--grey-100);
}
.caption-view-bar-mobile {
  position: relative;
  background-color: white;
  height: 52px;
}
</style>
