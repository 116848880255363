<template>
  <v-container
    style="
      border: 1px solid #dfe0eb;
      border-radius: 3px;
      background-color: #ffffff;
    "
    class="mt-lg-16 mt-xl-16"
  >
    <v-form ref="form" v-model="valid">
      <v-row class="pt-12"></v-row>
      <v-row class="mr-4" justify="end">
        <v-btn
          x-small
          plain
          right
          class="text-none caption"
          @click="$router.push('/login')"
          >Oups j'ai déjà un compte!</v-btn
        >
      </v-row>
      <v-row class="pt-10">
        <v-col xl="4" lg="4" md="4" sm="1" cols="1"> </v-col>
        <v-col xl="4" lg="4" md="4" sm="10" cols="10">
          <v-row justify="center"><h3>Profil</h3></v-row>
          <v-row class="pt-8"><strong>Nom</strong></v-row>
          <v-row
            ><v-text-field
              ref="name"
              v-model="name"
              :rules="nameRules"
              placeholder="Doe"
              type="text"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="mt-n1"><strong>Prénom</strong></v-row>
          <v-row
            ><v-text-field
              ref="firstName"
              v-model="firstName"
              :rules="firstnameRules"
              placeholder="John"
              type="text"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="mt-n1"><strong>Mail</strong></v-row>
          <v-row
            ><v-text-field
              ref="email"
              v-model="email"
              :rules="emailRules"
              placeholder="john@doe.com"
              type="email"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="mt-n1"><strong>Adresse de facturation</strong></v-row>
          <v-row
            ><v-text-field
              ref="address"
              v-model="address"
              :rules="addressRules"
              placeholder="Nom et numéro de la rue"
              class="mt-1 mb-n3"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="pt-1"
            ><v-text-field
              ref="postalCode"
              v-model="postalCode"
              :rules="postalRules"
              placeholder="Code postal"
              class="mb-n3"
              type="text"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="pt-1"
            ><v-text-field
              ref="city"
              v-model="city"
              :rules="cityRules"
              placeholder="Ville"
              type="text"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="mt-n1"
            ><strong>Choix de mon mot de passe</strong></v-row
          >
          <v-row
            ><v-text-field
              ref="password"
              v-model="password"
              placeholder="Entrer votre mot de passe"
              :rules="passwordRules"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              :type="show ? 'text' : 'password'"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row
            ><v-text-field
              ref="passwordV"
              v-model="passwordV"
              placeholder="Confirmez votre mot de passe"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show1 = !show1"
              :type="show1 ? 'text' : 'password'"
              :rules="passwordConfirmRules"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="mt-n1">Informations Entreprise (optionnel)</v-row>
          <v-row
            ><v-text-field
              v-model="nameOfEnterprise"
              placeholder="Nom de société (optionnel)"
              outlined
              dense
            ></v-text-field>
          </v-row>
          <v-row
            ><v-text-field
              v-model="numberOfTva"
              placeholder="Numéro de société (optionnel)"
              outlined
              dense
            ></v-text-field>
          </v-row>
          <v-row class="pt-6"></v-row>
          <v-row justify="center"><h3>Compte lié</h3></v-row>
          <v-row class="pt-8 pb-4"><strong>Compte Instagram</strong></v-row>
          <v-form ref="formInsta" v-model="isFormValid"
            ><v-row>
              <v-text-field
                ref="emailInstagram"
                v-model="emailInstagram"
                :rules="accountRules"
                placeholder="@johnDoe15"
                type="email"
                :disabled="nbAccountInsta === 4"
                oncopy="return false"
                onpaste="return false"
                outlined
                dense
                required
              ></v-text-field> </v-row
          ></v-form>
          <v-form v-model="isFormValidBis"
            ><v-row v-if="nbAccountInsta >= 2 && nbAccountInsta < 4"
              ><v-text-field
                ref="emailInstagramBis"
                v-model="emailInstagramBis"
                :rules="accountRules"
                placeholder="@johnDoe15"
                type="email"
                :disabled="nbAccountInsta === 4"
                oncopy="return false"
                onpaste="return false"
                outlined
                dense
                required
              ></v-text-field>
              <v-btn
                small
                icon
                @click="nbAccountInsta--, (emailInstagramBis = null)"
                class="mt-1 ml-2 pl-3"
              >
                <v-icon size="30" dark left color="primary" class="ml-n3"
                  >mdi-minus-circle</v-icon
                ></v-btn
              >
            </v-row></v-form
          >
          <v-form v-model="isFormValidTer"
            ><v-row v-show="nbAccountInsta >= 3 && nbAccountInsta < 4"
              ><v-text-field
                ref="emailInstagramTer"
                v-model="emailInstagramTer"
                :rules="accountRules"
                placeholder="@johnDoe15"
                type="email"
                :disabled="nbAccountInsta === 4"
                oncopy="return false"
                onpaste="return false"
                class="pr-n6"
                outlined
                dense
                required
              ></v-text-field>
              <v-btn
                small
                icon
                @click="
                  nbAccountInsta--;
                  emailInstagramTer = null;
                "
                class="mt-1 ml-2 pl-3"
              >
                <v-icon size="30" dark left color="primary"
                  >mdi-minus-circle</v-icon
                ></v-btn
              >
            </v-row></v-form
          >
          <v-row class="mt-n2">
            <v-btn
              v-if="nbAccountInsta <= 1"
              small
              text
              class="text-none ml-n1"
              :disabled="!isFormValid"
              @click="nbAccountInsta++"
            >
              <v-icon size="30" dark left color="primary" class="mr-3"
                >mdi-plus-circle</v-icon
              >Ajouter un compte</v-btn
            >
            <v-btn
              v-else-if="nbAccountInsta > 1 && nbAccountInsta < 3"
              small
              text
              class="text-none ml-n1"
              :disabled="!isFormValidBis"
              @click="nbAccountInsta++"
            >
              <v-icon size="30" dark left color="primary" class="mr-3 mt-1"
                >mdi-plus-circle</v-icon
              >Ajouter un compte</v-btn
            >
            <v-btn
              v-else-if="nbAccountInsta > 2 && nbAccountInsta < 4"
              text
              class="text-none ml-n1"
              :disabled="!isFormValidTer"
              @click="nbAccountInsta++"
            >
              <v-icon size="30" dark left color="primary" class="mr-3 mt-1"
                >mdi-plus-circle</v-icon
              >Passer à l'offre business</v-btn
            >
            <v-btn
              v-else-if="nbAccountInsta >= 4"
              text
              class="text-none ml-n1"
              @click="cancelBusi()"
            >
              <v-icon size="30" dark left color="primary" class="mr-3"
                >mdi-minus-circle</v-icon
              >Annuler l'offre business</v-btn
            >
          </v-row>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="2" cols="1"></v-col>
      </v-row>
      <v-row class="pt-16"></v-row>
      <v-row justify="center"><h3>Choisir mon offre</h3></v-row>
      <v-row>
        <v-col md="6" offset-md="3">
          <p class="pt-6" ALIGN="center">
            Vous pouvez upgrader votre abonnement à tout moment. La plateforme
            facturera un pro-rata sur la base de ce que vous versez déjà dans
            votre abonnement actuel.
          </p></v-col
        ></v-row
      >
      <v-row dense class="mt-8 mb-n1 ml-14"> </v-row>
      <v-row justify="center" class="ml-sm-n8 ml-md-n8 ml-lg-n8 ml-xl-n8">
        <v-avatar
          v-show="billing === 'annual'"
          color="primary"
          size="49"
          class="white--text ml-n16 mt-8 hidden-xs-only"
          >-20%</v-avatar
        ><v-icon
          v-show="billing === 'annual'"
          color="primary"
          class="white--text mt-n8 mr-4 mb-n1 hidden-xs-only"
          >mdi-undo</v-icon
        >

        <v-btn-toggle v-model="billing" mandatory>
          <v-avatar
            v-show="billing === 'annual'"
            color="primary"
            size="39"
            class="white--text caption hidden-sm-and-up mt-n8 ml-n6"
            >-20%</v-avatar
          ><v-icon
            v-show="billing === 'annual'"
            color="primary"
            class="white--text hidden-sm-and-up mt-n10 pb-2 mr-n8"
            >mdi-undo mdi-rotate-45</v-icon
          >

          <v-btn
            v-if="billing === 'annual'"
            class="rounded text-none white--text mt-4 px-10 mr-3"
            value="annual"
            height="35"
            color="primary"
            @click="billing = 'annual'"
            >Annuel</v-btn
          >
          <v-btn
            v-else
            class="rounded text-none mt-4 px-10 ml-4 mb-7"
            value="annual"
            height="35"
            color="white"
            @click="billing = 'annual'"
            >Annuel</v-btn
          >
          <v-btn
            v-if="billing === 'monthly'"
            class="rounded text-none white--text mt-4 px-10 ml-6 mb-7"
            value="monthly"
            height="35"
            color="primary"
            @click="billing = 'monthly'"
            >Mensuel</v-btn
          ><v-btn
            v-else
            class="rounded text-none mt-4 px-10 ml-3"
            style="border: 1px solid #dfe0eb"
            value="monthly"
            height="35"
            color="white"
            @click="billing = 'monthly'"
            >Mensuel</v-btn
          >
        </v-btn-toggle>
      </v-row>
      <v-row
        v-if="billing === 'monthly'"
        class="mt-n7 hidden-sm-and-up"
      ></v-row>
      <v-row class="mt-16"></v-row>
      <v-row class="mt-8 px-4 ml-lg-16 ml-xl-1">
        <v-col xl="3" lg="1" md="1" sm="12" cols="12"></v-col>
        <v-col xl="2" lg="3" md="3" sm="12" cols="12">
          <v-row justify="center" class="mb-4">
            <v-card
              :color="colorSolo"
              min-width="250"
              max-width="280"
              :dark="darkSolo"
              min-height="328"
            >
              <v-card-title class="justify-center pt-10 text-h6">
                Offre Solopreneur
              </v-card-title>
              <v-row>
                <v-col class="hidden-sm-and-down" xl="2" lg="2" md="2"></v-col>
                <v-col xl="8" lg="8" md="8" sm="12" xs="12">
                  <v-row class="justify-center pt-4"
                    ><h4>Je souhaite poster sur</h4></v-row
                  >
                  <v-row class="justify-center"><h4>1 compte</h4></v-row>
                  <v-row
                    v-if="billing === 'annual'"
                    class="justify-center pt-4 police_fine"
                    >45€/mois</v-row
                  ><v-row v-else class="justify-center pt-4 police_fine"
                    >59€/mois</v-row
                  ><v-row
                    v-if="billing === 'annual'"
                    class="justify-center police_fine police_down mt-3"
                    >facturé annuellement</v-row
                  ><v-row
                    v-else
                    class="justify-center police_fine police_down mt-3"
                    >facturé mensuellement</v-row
                  >
                  <v-row
                    v-if="billing === 'monthly'"
                    class="justify-center police_fine police_down"
                    >(sans engagement)</v-row
                  ><v-row v-else class="justify-center police_fine police_down"
                    >&nbsp;</v-row
                  >
                </v-col>
                <v-col class="hidden-sm-and-down" xl="2" lg="2" md="2"></v-col>
              </v-row>
              <v-row class="justify-center pt-7">
                <v-card-actions>
                  <v-btn
                    v-show="colorSolo === 'white'"
                    class="text-none mx-auto px-5"
                    :disabled="loading"
                    :loading="loading"
                    elevation="0"
                    color="primary"
                    height="30"
                    @click="chooseColorOffer('solo')"
                    >Je choisis cette offre</v-btn
                  >
                </v-card-actions></v-row
              >
            </v-card>
          </v-row>
        </v-col>
        <v-col xl="2" lg="3" md="4" sm="12" cols="12">
          <v-row justify="center" class="mb-4">
            <v-card
              :color="colorMult"
              min-width="250"
              max-width="280"
              min-height="328"
              :dark="darkMult"
            >
              <v-card-title class="pt-10 text-h6 justify-center">
                Offre Multipreneur
              </v-card-title>
              <v-row>
                <v-col class="hidden-sm-and-down" xl="2" lg="2" md="2"></v-col>
                <v-col xl="8" lg="8" md="8" sm="12" xs="12">
                  <v-row class="justify-center pt-4"
                    ><h4>Je souhaite poster sur</h4></v-row
                  >
                  <v-row class="justify-center"><h4>2 à 3 comptes</h4></v-row>
                  <v-row
                    v-if="billing === 'annual'"
                    class="justify-center pt-4 police_fine"
                    >59€/mois</v-row
                  ><v-row v-else class="justify-center pt-4 police_fine"
                    >75€/mois</v-row
                  ><v-row
                    v-if="billing === 'annual'"
                    class="justify-center police_fine police_down mt-3"
                    >facturé annuellement</v-row
                  ><v-row
                    v-else
                    class="justify-center police_fine police_down mt-3"
                    >facturé mensuellement</v-row
                  >
                  <v-row
                    v-if="billing === 'monthly'"
                    class="justify-center police_fine police_down"
                    >(sans engagement)</v-row
                  ><v-row v-else class="justify-center police_fine police_down"
                    >&nbsp;</v-row
                  >
                </v-col>
                <v-col
                  class="hidden-sm-and-down"
                  xl="2"
                  lg="2"
                  md="2"
                  sm="auto"
                  xs="auto"
                ></v-col>
              </v-row>
              <v-row class="justify-center pt-7">
                <v-card-actions>
                  <v-btn
                    v-show="colorMult === 'white'"
                    class="text-none mx-auto px-5"
                    :disabled="loading"
                    :loading="loading"
                    elevation="0"
                    color="primary"
                    height="30"
                    @click="chooseColorOffer('mult')"
                    >Je choisis cette offre</v-btn
                  >
                </v-card-actions></v-row
              >
            </v-card>
          </v-row>
        </v-col>
        <v-col xl="2" lg="3" md="3" sm="12" cols="12">
          <v-row justify="center">
            <v-card
              :color="colorBusi"
              min-width="250"
              max-width="260"
              min-height="328"
              :dark="darkBusi"
            >
              <v-card-title class="pt-10 text-h6 justify-center">
                Offre Business
              </v-card-title>
              <v-row>
                <v-row justify="center" class="pt-4 police_down_align_center">
                  <v-card-text class="mx-4"
                    ><h4>
                      Je suis une agence ou un community manager et je souhaite
                      utiliser la plateforme pour mes clients
                    </h4></v-card-text
                  ></v-row
                >
              </v-row>
              <v-row class="justify-center pt-16 mt-5">
                <v-card-actions>
                  <v-btn
                    v-show="colorBusi === 'white'"
                    class="text-none mx-auto px-5"
                    :disabled="loading"
                    :loading="loading"
                    elevation="0"
                    color="primary"
                    height="30"
                    @click="chooseColorOffer('busi')"
                    >Être recontacté(e)</v-btn
                  >
                </v-card-actions></v-row
              >
            </v-card>
          </v-row>
        </v-col>
        <v-col xl="3" lg="0" md="1" sm="12" cols="12"></v-col>
      </v-row>
      <v-row v-if="offer" dense class="mt-6 mx-n4 ml-lg-n14 ml-xl-4">
        <v-col xl="3" lg="2" md="1" sm="12" cols="12"></v-col>
        <v-col xl="2" lg="3" md="3" sm="12" cols="12">
          <v-row justify="center" class="mb-4">
            <v-card elevation="0" min-width="200" max-width="250" class="pt-2">
              <v-list>
                <v-list-item three-line class="mt-n4">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-text-card"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Un calendrier de
                      <strong
                        >contenu stratégique et personnalisé chaque mois</strong
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Accès illimité aux
                      <strong
                        >templates de légendes de posts</strong
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3">
                      <v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >Accès illimité aux <strong>templates Canva</strong> pour
                      les posts</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Un <strong>planner drag & drop</strong> pour visualiser
                      son contenu en une vue</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Un nombre illimités de
                      <strong>brouillons de posts</strong></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Accès illimité à la
                      <strong
                        >bibliothèque d’image</strong
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      <strong>1 workspace</strong> pour un compte social
                      media</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-row>
        </v-col>
        <v-col xl="2" lg="3" md="4" sm="12" cols="12">
          <v-row justify="center" class="mb-4 ml-lg-n16 ml-xl-n6">
            <v-card elevation="0" min-width="200" max-width="250" class="pt-2">
              <v-list>
                <v-list-item three-line class="mt-n4">
                  <v-list-item-content>
                    <v-list-item-subtitle class="font-text-card"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Un calendrier de
                      <strong
                        >contenu stratégique et personnalisé chaque mois</strong
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Accès illimité aux
                      <strong
                        >templates de légendes de posts</strong
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3">
                      <v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >Accès illimité aux <strong>templates Canva</strong> pour
                      les posts</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Un <strong>planner drag & drop</strong> pour visualiser
                      son contenu en une vue</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Un nombre illimités de
                      <strong>brouillons de posts</strong></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      Accès illimité à la
                      <strong
                        >bibliothèque d’image</strong
                      ></v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="font-text-card mt-3"
                      ><v-icon small left color="primary"
                        >mdi-check-circle</v-icon
                      >
                      <strong>3 workspace</strong> pour un compte social
                      media</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-row>
        </v-col>
        <v-col xl="2" lg="3" md="3" sm="12" cols="12">
          <v-row justify="center"> </v-row>
        </v-col>
        <v-col xl="3" lg="1" md="1" sm="12" cols="12"></v-col>
      </v-row>
      <v-row class="justify-center pt-5">
        <v-col cols="5" class="hidden-md-and-down">
          <v-card-actions>
            <v-select
              v-model="answer"
              :items="itemsAnswer"
              :rules="answerRules"
              filled
              label="Comment avez-vous connu Prêt-à-Poster ?"
              required
            ></v-select> </v-card-actions
        ></v-col>
        <v-col cols="8" class="hidden-lg-and-up hidden-xs-only">
          <v-card-actions>
            <v-select
              v-model="answer"
              :items="itemsAnswer"
              :rules="answerRules"
              filled
              label="Comment avez-vous connu Prêt-à-Poster ?"
              required
            ></v-select></v-card-actions
        ></v-col>
        <v-col cols="12" class="hidden-sm-and-up">
          <v-card-actions>
            <v-select
              v-model="answer"
              :items="itemsAnswer"
              :rules="answerRules"
              filled
              label="Comment avez-vous connu Prêt-à-Poster ?"
              required
            ></v-select></v-card-actions></v-col
      ></v-row>
      <v-row class="justify-center pt-5">
        <v-card-actions>
          <v-btn
            class="text-none mx-auto px-14"
            color="primary"
            :disabled="!valid"
            :loading="loading"
            elevation="0"
            @click="subscriptionClient()"
            >Valider mon inscription</v-btn
          ></v-card-actions
        ></v-row
      >
      <v-row class="justify-center pt-2">
        <v-card-actions>
          <v-btn
            class="text-none mx-auto px-8"
            :disabled="loading"
            :loading="loading"
            text
            @click="$router.push('/login')"
            >Annuler</v-btn
          >
        </v-card-actions>
      </v-row>

      <p v-if="errorMsg" class="errorM">{{ errorMsg }}</p>
    </v-form>
  </v-container>
</template>

<script>
import emailjs from "@emailjs/browser";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { loadStripe } from "@stripe/stripe-js";
const fb = require("@/firebase/init.js");
export default {
  components: {
    StripeCheckout,
  },
  name: "Inscription",
  data() {
    this.publishableKey =
      "rk_test_51LUpHgCxGLgtuUQ0rjmW1GM72aqYTdiCesXH4hqbnuW4kCFLsN1HlHPhcs70rhbb05q7tALVr8HoNcn5R6qfC4MN000Kr53t6G";
    return {
      priceTemp: "price_1Lrd2BCxGLgtuUQ0AZaImdO3",
      address: null,
      answer: null,
      billing: "annual",
      city: null,
      colorSolo: "white",
      colorMult: "white",
      colorBusi: "white",
      darkSolo: false,
      darkMult: false,
      darkBusi: false,
      email: null,
      emailInstagram: null,
      emailInstagramBis: null,
      emailInstagramTer: null,
      errorMsg: null,
      firstName: null,
      id: null,
      isFormValid: false,
      isFormValidBis: false,
      isFormValidTer: false,
      itemsAnswer: [
        "Instagram",
        "YouTube",
        "Pinterest",
        "Bouche à Oreille",
        "Autres",
      ],
      loading: false,
      name: null,
      nameOfEnterprise: "",
      nbAccountInsta: 0,
      numberOfTva: "",
      offer: "solo",
      postalCode: null,
      password: null,
      passwordV: null,
      show1: false,
      show: false,
      valid: false,
      urlPaymentSuccess: "https://pret-a-poster.app/paymentSuccess",
      urlPaymentError: "https://pret-a-poster.web.app/paymentError",
      accountRules: [
        (v) =>
          !!v || "Le compte Instagram est nécessaire, exemple : @johnDoe15",
        (v) =>
          (v && v.length <= 30) ||
          "Le compte Instagram ne peut pas contenir plus de 30 caractères",
      ],
      addressRules: [
        (v) => !!v || "L'adresse est nécessaire",
        (v) =>
          (v && v.length <= 60) ||
          "L'adresse ne doit pas dépasser 60 caractères",
      ],
      answerRules: [
        (v) =>
          !!v || "Merci de répondre à la question pour terminer l'inscription",
      ],
      cityRules: [
        (v) => !!v || "Le nom de la ville est nécessaire",
        (v) =>
          (v && v.length <= 40) ||
          "Le nom de la ville ne peut pas dépasser 40 caractères",
      ],
      emailRules: [
        (v) => !!v || "L'email est nécessaire, exemple : john@doe.fr",
        (v) =>
          (v && v.length <= 40) ||
          "L'email ne peut pas contenir plus de 40 caractères",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) ||
            "L'email doit être valide, exemple : john@doe.fr"
          );
        },
      ],
      firstnameRules: [
        (v) => !!v || "Le prénom est nécessaire",
        (v) => (v && v.length <= 30) || "Ne peut pas dépasser 20 caractères",
      ],
      nameRules: [
        (v) => !!v || "Le nom est nécessaire",
        (v) => (v && v.length <= 30) || "Ne peut pas dépasser 20 caractères",
      ],
      passwordConfirmRules: [
        (v) => !!v || "Veuillez confimer le mot de passe",
        (v) => v === this.password || "Les mots de passe ne correspondent pas",
      ],
      passwordRules: [
        (v) => !!v || "Le mot de passe est nécessaire",
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit contenir au moins 6 caractères",
        (v) =>
          (v && v.length <= 40) ||
          "Le mot de passe ne peut pas contenir plus de 40 caractères",
      ],
      postalRules: [(v) => !!v || "Le code postal est nécessaire"],
    };
  },
  mounted() {
    let host = window.location.host;
    let success = "https://" + host + "/paymentSuccess";
    let error = "https://" + host + "/paymentError";
    this.urlPaymentSuccess = success;
    this.urlPaymentError = error;
  },
  watch: {
    emailInstagram: function (val) {
      if (this.isFormValid === false) {
        this.colorSolo = "white";
        this.offer = null;
        this.nbAccountInsta = 0;
      } else if (this.isFormValid === true) {
        this.nbAccountInsta = 1;
      } else {
        this.colorSolo = "white";
        this.offer = null;
        this.nbAccountInsta = 0;
      }
    },
    nbAccountInsta: function (val) {
      if (this.nbAccountInsta === 1) {
        this.colorSolo = "primary";
        this.colorMult = "white";
        this.colorBusi = "white";
        this.darkSolo = true;
        this.darkMult = false;
        this.darkBusi = false;
        this.offer = "solo";
      } else if (this.nbAccountInsta === 2) {
        this.colorMult = "primary";
        this.colorSolo = "white";
        this.colorBusi = "white";
        this.darkMult = true;
        this.darkSolo = false;
        this.darkBusi = false;
        this.offer = "mult";
      } else if (this.nbAccountInsta === 3) {
        this.colorMult = "primary";
        this.colorSolo = "white";
        this.colorBusi = "white";
        this.darkMult = true;
        this.darkSolo = false;
        this.darkBusi = false;
        this.offer = "mult";
      } else if (this.nbAccountInsta === 4) {
        this.colorBusi = "primary";
        this.colorSolo = "white";
        this.colorMult = "white";
        this.darkBusi = true;
        this.darkSolo = false;
        this.darkMult = false;
        this.offer = "busi";
      } else if (this.nbAccountInsta === 0) {
        this.colorSolo = "white";
        this.colorMult = "white";
        this.colorBusi = "white";
        this.darkSolo = false;
        this.darkMult = false;
        this.darkBusi = false;
        this.offer = null;
      } else {
        this.colorSolo = "white";
        this.colorMult = "white";
        this.colorBusi = "white";
        this.darkSolo = false;
        this.darkMult = false;
        this.darkBusi = false;
        this.offer = null;
      }
    },
  },
  methods: {
    async subscriptionClient() {
      this.loading = true;
      let self = this;
      let firstTags = [""];
      let emailInstagramAll = [];
      if (this.$refs.form.validate() && this.$refs.formInsta.validate()) {
        if (this.emailInstagram) {
          emailInstagramAll = [this.emailInstagram];
        }
        if (this.emailInstagramBis) {
          emailInstagramAll = [this.emailInstagram, this.emailInstagramBis];
        }
        if (this.emailInstagramTer) {
          emailInstagramAll = [
            this.emailInstagram,
            this.emailInstagramBis,
            this.emailInstagramTer,
          ];
        }
        if (this.offer == "solo") {
          if (this.billing == "monthly") {
            this.priceTemp = "price_1Lrd2BCxGLgtuUQ0AZaImdO3";
          } else {
            this.priceTemp = "price_1Lrd2BCxGLgtuUQ0zvDRtGLO";
          }
        } else if (this.offer == "mult") {
          if (this.billing == "monthly") {
            this.priceTemp = "price_1Lrd2ICxGLgtuUQ0WmcyeiG3";
          } else {
            this.priceTemp = "price_1Lrd2ICxGLgtuUQ0c7Gbdlvb";
          }
        } else {
        }
        if (this.offer == "busi") {
          fb.firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(function (data) {
              self.id = data.user.uid;
            })
            .then(() => {
              fb.db
                .collection("clients")
                .doc(self.id)
                .set({
                  answer: self.answer,
                  name: self.name,
                  firstName: self.firstName,
                  email: self.email,
                  emailInstagram: emailInstagramAll,
                  address: self.address,
                  postalCode: self.postalCode,
                  city: self.city,
                  nameOfEnterprise: self.nameOfEnterprise,
                  numberOfTva: self.numberOfTva,
                  billing: self.billing,
                  offer: self.offer,
                  createdOn: new Date(),
                  id: self.id,
                  paid: false,
                  tagsCaption: firstTags,
                  tagsImage: firstTags,
                  tagsImportUser: [],
                  totalStorageSizeUsed: 0,
                })
                .catch((err) => {
                  this.loading = false;
                  console.log(err);
                });
            })
            .then(() => {
              var templateParams = {
                firstName: "Prénom : " + self.firstName,
                name: "Nom : " + self.name,
                email: "email : " + self.email,
                offer: "Choix de l'offre : " + self.offer,
                billing: "Choix de la facturation : " + self.billing,
                id: "id : " + self.id,
                subject:
                  "Demande d'offre Business par " +
                  self.firstName +
                  " " +
                  self.name,
                message:
                  "Félicitation, vous avez un nouveau client qui souhaite être recontacté pour l'offre Business !",
              };
              emailjs
                .send(
                  "service_a05rae5",
                  "template_3f62fjc",
                  templateParams,
                  "1FewyxFa-JoEAEU3M"
                )
                .then(
                  function (response) {
                    console.log("SUCCESS!", response.status, response.text);
                    self.loading = false;
                    self.$router.push("/busiOffer");
                  },
                  function (error) {
                    self.loading = false;
                    console.log("FAILED...", error);
                    self.$router.push("/inscription");
                  }
                );
            })
            .catch((error) => {
              var errorCode = error.code;
              if (errorCode === "auth/email-already-in-use") {
                self.$store.commit("setSnackbar", {
                  color: "error",
                  timeout: 4000,
                  text: " Le compte " + this.email + " est déjà utilisé",
                });
              }
              self.loading = false;
            });
        } else {
          await fb.firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then(function (data) {
              self.id = data.user.uid;
              fb.db.collection("clients").doc(self.id).set({
                answer: self.answer,
                name: self.name,
                firstName: self.firstName,
                email: self.email,
                emailInstagram: emailInstagramAll,
                address: self.address,
                postalCode: self.postalCode,
                city: self.city,
                nameOfEnterprise: self.nameOfEnterprise,
                numberOfTva: self.numberOfTva,
                createdOn: new Date(),
                id: self.id,
                tagsCaption: firstTags,
                tagsImage: firstTags,
                tagsImportUser: [],
                totalStorageSizeUsed: 0,
              });
              const docRef = fb.db
                .collection("customers")
                .doc(self.id)
                .collection("checkout_sessions")
                .add({
                  price: self.priceTemp,
                  allow_promotion_codes: true,
                  tax_id_collection : {
                    enabled: true,
                  },
                  success_url: window.location.origin,
                  cancel_url: window.location.origin + "/paymentError",
                })
                .then((docRef) => {
                  docRef.onSnapshot(async (snap) => {
                    const { error, sessionId } = snap.data();
                    if (sessionId) {
                      const stripe = await loadStripe(
                        "pk_live_51LUpHgCxGLgtuUQ0OOH5mpWt6bCwUfNs3LgSdcf64LrqJ1km5ERmsqcPpyhAVUW21zECUbYr5XDRIKgHRHYMcelp003QhGsdO1"
                      );
                      await stripe.redirectToCheckout({ sessionId });
                      this.loading = false;
                    }
                  });
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                });
            })
            .catch((error) => {
              var errorCode = error.code;
              if (errorCode === "auth/email-already-in-use") {
                self.$store.commit("setSnackbar", {
                  color: "error",
                  timeout: 4000,
                  text: " Le compte " + this.email + " est déjà utilisé",
                });
              }
              this.loading = false;
            });
        }
      } else {
        self.$store.commit("setSnackbar", {
          color: "error",
          timeout: 4000,
          text: " Certaines informations sont manquantes ou invalides",
        });
        this.loading = false;
      }
    },
    cancelBusi() {
      if (this.emailInstagramBis != null) {
        if (this.emailInstagramTer != null) {
          this.nbAccountInsta = 3;
        } else {
          this.nbAccountInsta = 2;
        }
      } else {
        this.nbAccountInsta = 1;
      }
    },
    chooseColorOffer(offer) {
      if (offer === "solo") {
        this.offer === "solo";
        this.colorSolo = "primary";
        this.colorMult = "white";
        this.colorBusi = "white";
        this.nbAccountInsta = 1;
      } else if (offer === "mult") {
        this.offer === "mult";
        this.colorMult = "primary";
        this.colorSolo = "white";
        this.colorBusi = "white";
        if (this.nbAccountInsta === 4) {
          this.nbAccountInsta = this.nbAccountInsta - 1;
        } else {
          this.nbAccountInsta = 2;
        }
      } else if (offer === "busi") {
        this.offer === "busi";
        this.colorBusi = "primary";
        this.colorSolo = "white";
        this.colorMult = "white";
        this.nbAccountInsta = 4;
      } else {
      }
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border: 2px solid;
  border-color: #dfe0eb;
}
</style>
<style>
.police_fine {
  font-family: "Titillium Web", sans-serif;
}
.police_down {
  font-size: 9pt;
}
.police_down_align_center {
  text-align: center;
}
.errorM {
  text-align: center;
  color: red;
}
.font-text-card {
  line-height: inherit;
  line-height: 30px;
  font-size: inherit;
  font-size: 12px;
  text-align: start;
}
</style>
