<template>
  <li
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday,
      'calendar-day-move': postMove,
    }"
    @drop="onDrop($event, day.date)"
    @dragenter="postMove = !postMove"
    @dragleave="postMove = false"
    @dragover.prevent
    @mouseover="upHere = true"
    @mouseleave="upHere = false"
  >
    <span>{{ label }}</span>
    <v-window v-model="window" vertical>
      <v-window-item
        v-for="(cardItem, index) in cardItemsFilter"
        :key="cardItem.id"
      >
        <v-btn
          v-if="
            day.date === cardItem.list &&
            !prevIcon &&
            cardItemsFilter.length >= 2
          "
          icon
          x-small
          class="mb-1 mt-6"
          @click="dialog = !dialog"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-else-if="day.date === cardItem.list && prevIcon"
          icon
          @click="prev"
          class="mt-4"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
        <v-btn v-else icon x-small class="" @click="dialog = !dialog">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-card
          class="drag-el px-auto mb-2 transparent-card"
          min-width="140"
          max-width="220"
          max-height="160"
          elevation="0"
          draggable
          @dragstart="startDrag($event, cardItem)"
          @click="
            dataForModif(
              cardItem.caption,
              cardItem.emailInstagram,
              cardItem.categorie,
              cardItem.format,
              cardItem.id,
              index,
              cardItem.imgPost,
              flagErrorLoadImg
            )
          "
          ><v-row dense>
            <v-col align="start" class="ml-1"
              ><font size="1">{{ cardItem.emailInstagram }}</font></v-col
            >
            <v-col align="end" class="">
              <ModifPost
                ref="ModifPost"
                :day="day"
                :cardItem="cardItem"
                @newPost="newPost"
                @deleteFlag="deleteFlag"
              /> </v-col
          ></v-row>

          <v-hover
            v-if="
              typeof cardItem.imgPost === 'string' &&
              cardItemsFilter.length < 2 &&
              flagErrorLoadImg === false
            "
            v-slot="{ hover }"
          >
            <v-img
              class="white--text align-end"
              min-width="135"
              height="140px"
              max-height="170"
              :src="cardItem.imgPost"
              @error="imageLoadError"
              ><v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transparentImg darken-2 text-h2 white--text"
                  style="height: 60px"
                >
                  <v-spacer></v-spacer
                  >
                  <v-btn
                    has-bg
                    x-small
                    color="white"
                    class="mt-1 deep-purple--text text--primary-base"
                    >Modifier mon post</v-btn
                  >
              <v-spacer></v-spacer>
                </div> </v-expand-transition
              ><v-sheet min-height="50" max-height="55px" class="transparent"
                ><v-row dense>
                  <v-col cols="5" align="start" class="mt-n1"
                    ><v-chip
                      x-small
                      class="ml-1 pl-1 pr-14"
                      color="cyan accent-1"
                      label
                    >
                      {{ cardItem.format }}
                    </v-chip></v-col
                  ><v-col cols="6" align="start" class="mt-n1 ml-1"
                    ><v-chip
                      x-small
                      class="pl-1"
                      color="deep-purple lighten-4"
                      label
                    >
                      {{ cardItem.categorie }}
                    </v-chip></v-col
                  >
                </v-row>
                <v-list-item three-line class="mt-n8 ml-n3">
                  <v-list-item-subtitle class="font-text-card ml-1">{{
                    cardItem.caption
                  }}</v-list-item-subtitle></v-list-item
                >
              </v-sheet></v-img
            >
          </v-hover>
          <v-hover v-else-if="flagErrorLoadImg === true" v-slot="{ hover }">
            <v-img
              class="white--text align-end"
              min-width="135"
              height="140px"
              max-height="170"
              src="https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9"
              ><v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transparentImg darken-2 text-h2 white--text"
                  style="height: 60px"
                >
                  <v-spacer></v-spacer
                  ><v-btn 
                    has-bg
                    x-small
                    color="white"
                    class="mt-1 deep-purple--text text--primary-base"
                    >Modifier mon post</v-btn
                  ><v-spacer></v-spacer>
                </div> </v-expand-transition
              ><v-sheet min-height="50" max-height="55px" class="transparent"
                ><v-row dense>
                  <v-col cols="5" align="start" class="mt-n1"
                    ><v-chip
                      x-small
                      class="ml-1 pl-1 pr-14"
                      color="cyan accent-1"
                      label
                    >
                      {{ cardItem.format }}
                    </v-chip></v-col
                  ><v-col cols="6" align="start" class="mt-n1 ml-1"
                    ><v-chip
                      x-small
                      class="pl-1"
                      color="deep-purple lighten-4"
                      label
                    >
                      {{ cardItem.categorie }}
                    </v-chip></v-col
                  >
                </v-row>
                <v-list-item three-line class="mt-n8 ml-n3">
                  <v-list-item-subtitle class="font-text-card ml-1">{{
                    cardItem.caption
                  }}</v-list-item-subtitle></v-list-item
                >
              </v-sheet></v-img
            >
          </v-hover>
          <v-carousel
            v-else-if="
              typeof cardItem.imgPost === 'object' && cardItemsFilter.length < 2
            "
            hide-delimiters
            show-arrows-on-hover
            height="138px"
            max-height="138px"
            class="mb-16"
          >
            <template v-slot:next="{ on, attrs }">
              <v-btn class="" icon small v-bind="attrs" v-on="on"
                ><v-icon small>mdi-arrow-right</v-icon></v-btn
              >
            </template>
            <template v-slot:prev="{ on, attrs }">
              <v-btn class="" icon small v-bind="attrs" v-on="on"
                ><v-icon small>mdi-arrow-left</v-icon></v-btn
              >
            </template>

            <v-carousel-item
              v-for="(item, i) in cardItem.imgPost"
              :key="i"
              :src="item"
              reverse-transition="fade-transition"
              transition="fade-transition"
              height="100px"
              max-height="138px"
              class="white--text align-end mb-12"
            >
              <div class="mt-16"></div>
              <v-icon
                v-for="(item, i) in cardItem.imgPost"
                :key="i"
                color="white"
                class="mx-n2"
                >mdi-circle-small
              </v-icon>
              <v-sheet min-height="50" max-height="55px" class="transparent">
                <v-row dense class="">
                  <v-col cols="5" align="start" class="mt-n1"
                    ><v-chip
                      x-small
                      class="ml-1 pl-1 pr-14"
                      color="cyan accent-1"
                      label
                    >
                      {{ cardItem.format }}
                    </v-chip></v-col
                  ><v-col cols="6" align="start" class="mt-n1 ml-1"
                    ><v-chip
                      x-small
                      class="pl-1"
                      color="deep-purple lighten-4"
                      label
                    >
                      {{ cardItem.categorie }}
                    </v-chip></v-col
                  >
                </v-row>
                <v-list-item three-line class="mt-n8 ml-n3">
                  <v-list-item-subtitle class="font-text-card ml-1">{{
                    cardItem.caption
                  }}</v-list-item-subtitle></v-list-item
                >
              </v-sheet></v-carousel-item
            ></v-carousel
          >
        </v-card>
        <v-sheet
          v-if="day.date === cardItem.list && cardItemsFilter.length >= 2"
          min-height="50"
          max-height="55px"
          class="drag-el transparent mt-n2"
          draggable
          @dragstart="startDrag($event, cardItem)"
          @click="
            dataForModif(
              cardItem.caption,
              cardItem.emailInstagram,
              cardItem.categorie,
              cardItem.format,
              cardItem.id,
              index,
              cardItem.imgPostRaw
            )
          "
          ><v-row dense>
            <v-col cols="5" align="start" class="mt-n2"
              ><v-chip
                x-small
                class="ml-1 pl-1 pr-14"
                color="cyan accent-1"
                label
              >
                {{ cardItem.format }}
              </v-chip></v-col
            ><v-col cols="6" align="start" class="mt-n2 ml-1"
              ><v-chip x-small class="pl-1" color="deep-purple lighten-4" label>
                {{ cardItem.categorie }}
              </v-chip></v-col
            >
          </v-row>
          <v-list-item three-line class="mt-n8 ml-n3">
            <v-list-item-subtitle class="font-text-card ml-1">{{
              cardItem.caption
            }}</v-list-item-subtitle></v-list-item
          >
        </v-sheet>
        <v-btn
          icon
          v-if="day.date === cardItem.list && cardItemsFilter.length >= 2"
          @click="next"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-window-item>
    </v-window>
    <AddPost
      :upHere="upHere"
      :dialogBis="dialog"
      :day="day"
      :button="true"
      :cardItemsFilter="cardItemsFilter"
      @newPost="newPost"
    />
  </li>
</template>

<script>
import AddPost from "./AddPost";
import ModifPost from "./ModifPost";
import dayjs from "dayjs";
const fb = require("@/firebase/init.js");

export default {
  name: "CalendarMonthDayItem",
  components: {
    AddPost,
    ModifPost,
  },

  props: {
    day: {
      type: Object,
      required: true,
    },

    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
    cardItems: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    dialog: false,
    flagErrorLoadImg: false,
    postMove: false,
    prevIcon: false,
    upHere: false,
    window: null,
  }),
  created() {},
  computed: {
    cardItemsFilter() {
      return this.cardItems.filter((item) => item.list === this.day.date);
    },
    label() {
      return dayjs(this.day.date).format("D");
    },
  },
  methods: {
    dataForModif(caption, email, categorie, format, id, index, img, flag) {
      // Permet de récupérer les données de la card pour les fournir au composant ModifPost
      // Si le flag est activé dû à un lien corrompu, on met l'image générique.
      if (flag) {
        img =
          "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9";
      }
      this.$refs.ModifPost[index].dataForModif(
        caption,
        email,
        categorie,
        format,
        id,
        img
      );
    },
    deleteFlag() {
      // Flag remis à zéro. Le flag permet de déterminer si le lien d'une image est corrompu pour le remplacer par la générique
      this.flagErrorLoadImg = false;
    },
    imageLoadError() {
      // Flag activé, le lien de l'image est corrompu ou plus disponible. Le flag permet de déterminer si le lien d'une image est cassée pour le remplacer par la générique
      this.flagErrorLoadImg = true;
    },

    newPost() {
      // Envoi au parent CalendarMonth une demande de mise à jour des données
      this.$emit("newPost");
    },
    next() {
      // Fonction next qui permet d'évoluer quand plusieurs card sont présentes sur un même jour
      this.window =
        this.window + 1 === this.cardItemsFilter.length ? 0 : this.window + 1;
      if (this.window != 0) {
        this.prevIcon = true;
      } else {
        this.prevIcon = false;
      }
    },
    onDrop(evt, list) {
      // Fonction pour gérer le drag and drop
      const self = this;
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.cardItems.find((item) => item.id == itemID);
      this.postMove = false;
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .doc(item.id)
        .update({
          day: list,
          list: list,
        })
        .then(() => {})
        .catch((error) => {
          console.error("Error updated document: ", error);
        });
      this.$emit("newPost");
    },
    prev() {
      // Fonction previous qui permet d'évoluer quand plusieurs card sont présentes sur un même jour
      this.window =
        this.window - 1 < 0 ? this.cardItemsFilter.length - 1 : this.window - 1;
      if (this.window != 0) {
        this.prevIcon = true;
      } else {
        this.prevIcon = false;
      }
    },
    startDrag(evt, item) {
      // Fonction pour gérer le drag and drop
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
  },
};
</script>
<style scoped>
.calendar-day {
  position: relative;
  min-width: 147px;
  min-height: 200px;
  max-height: 200px;
  font-size: 12px;
  background-color: #fafafa;
  color: var(--grey-800);
  padding: 5px;
}
.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1px;
  width: var(--day-label-size);
  height: var(--day-label-size);
}
.calendar-day--not-current {
  background-color: #f5f5f5;
  color: var(--grey-300);
}
.calendar-day--today {
  padding-top: 4px;
}
.calendar-day--today > span {
  color: #fff;
  border-radius: 9999px;
  background-color: #6868a8;
}
.calendar-day-move {
  background-color: white;
  color: red;
  box-shadow: 3px 9px 9px 1px rgba(196, 196, 196, 0.73);
}
.font-text-card {
  line-height: inherit;
  line-height: 13px;
  font-size: inherit;
  font-size: 10px;
  text-align: start;
}
.transparent {
  background-color: white !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparentImg {
  background-color: transparent !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparent-card {
  background-color: #fafafa;
  opacity: 0.9;
  border-color: transparent !important;
}
</style>
