import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyB-hszFVXACx3m521gUs5RljIwigq0RxMM",
  authDomain: " pret-a-poster.firebaseapp.com",
  projectId: "pret-a-poster",
  storageBucket: "pret-a-poster.appspot.com",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// for router
firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      unsubscribe();
      if (!user) {
        resolve(false);
      } else if (user.isAnonymous == true) {
        resolve(false);
      } else if (user.isAnonymous == false) {
        resolve(true);
      }
    }, reject);
  });
};
//firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;
const storageRef = firebase.storage().ref();
const functions = firebase.functions();
export { db, auth, currentUser, storageRef, functions, firebase };
