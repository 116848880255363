var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"calendar-day",class:{
    'calendar-day--not-current': !_vm.day.isCurrentMonth,
    'calendar-day--today': _vm.isToday,
    'calendar-day-move': _vm.postMove,
  },on:{"drop":function($event){return _vm.onDrop($event, _vm.day.date)},"dragenter":function($event){$event.preventDefault();},"dragover":function($event){$event.preventDefault();}}},[_c('span',{on:{"click":function($event){return _vm.addPost(_vm.day, _vm.cardItemsFilter)}}},[_c('font',{attrs:{"size":"1"}},[_vm._v(_vm._s(_vm.label))])],1),_c('AddPost',{ref:"AddPost",attrs:{"day":_vm.day,"button":true,"cardItemsFilter":_vm.cardItemsFilter},on:{"newPost":_vm.newPost}}),_vm._l((_vm.cardItemsFilter),function(cardItem,index){return _c('div',{key:cardItem.id},[_c('ModifPost',{ref:"ModifPost",refInFor:true,attrs:{"day":_vm.day,"cardItem":cardItem,"button":false},on:{"newPost":_vm.newPost,"deleteFlag":_vm.deleteFlag}}),_c('div',{staticClass:"drag-el mt-7 mx-auto cardMobile",attrs:{"max-width":"90","max-height":"50","elevation":"1","draggable":""},on:{"dragstart":function($event){return _vm.startDrag($event, cardItem)},"click":function($event){return _vm.dataForModif(
          cardItem.caption,
          cardItem.emailInstagram,
          cardItem.categorie,
          cardItem.format,
          cardItem.id,
          index,
          cardItem.imgPost,
          _vm.flagErrorLoadImg
        )}}},[(typeof cardItem.imgPost === 'string')?_c('v-img',{attrs:{"max-height":"1","max-width":"1","src":cardItem.imgPost},on:{"error":_vm.imageLoadError}}):_c('v-img',{attrs:{"max-height":"1","max-width":"1","src":cardItem.imgPost[0]},on:{"error":_vm.imageLoadError}}),_c('v-row',{attrs:{"dense":""}},[_c('v-chip',{staticClass:"pl-1 format-width",attrs:{"x-small":"","color":"cyan accent-1","label":""}},[_vm._v(" "+_vm._s(cardItem.format)+" ")])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-chip',{staticClass:"pl-1",attrs:{"x-small":"","color":"format-width deep-purple lighten-4","label":""}},[_vm._v(" "+_vm._s(cardItem.categorie)+" ")])],1)],1)],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }