<template>
  <v-app>
    <v-app-bar app class="hidden-lg-and-up" color="white" elevation="0">
      <v-app-bar-nav-icon
        v-if="userActive"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <v-img
        alt="Logo"
        contain
        src="./assets/logo.png"
        @click="homeRoute()"
        style="cursor: pointer"
        width="50"
        height="50"
      ></v-img>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary absolute>
      <v-list nav dense class="mt-16">
        <v-list-item-title class="d-flex justify-center"
          >Menu</v-list-item-title
        >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
          class="mt-8"
        >
          <v-list-item>
            <v-list-item-title class="Titillium" @click="homeRoute()"
              >Ma Stratégie</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title class="Titillium" @click="captionsRoute()"
              >Templates</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title class="Titillium" @click="photosRoute()"
              >Photos</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title class="Titillium" @click="adminRoute()"
              >Paramètres de compte</v-list-item-title
            >
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title
              ><GenerateStrategy v-if="userActive" @newPost="newPost"
            /></v-list-item-title>
          </v-list-item>
          <v-divider class="mt-8"></v-divider>
          <v-list-item class="mt-2">
            <v-list-item-title class="Titillium" @click="signOut()"
              >Me déconnecter</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      style="box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06); !important;"
      class="hidden-md-and-down"
      color="white"
    >
      <v-spacer v-if="!userActive"></v-spacer>
      <div>
        <v-img
          alt="Logo"
          class="ml-1"
          style="cursor: pointer"
          contain
          src="./assets/logo.png"
          width="159"
          height="140"
          @click="homeRoute()"
        />
      </div>
      <v-spacer v-if="!userActive"></v-spacer>
      <v-tabs v-show="userActive" class="ml-8">
        <v-tab class="text-none" to="/" @click="homeRoute()"
          >Ma stratégie</v-tab
        >
        <v-tab class="text-none mx-2" to="/templates" @click="captionsRoute()"
          >Templates</v-tab
        >
        <v-tab class="text-none mx-2" to="/photos" @click="photosRoute()"
          >Photos</v-tab
        >

        <v-spacer></v-spacer
        ><GenerateStrategy
          v-if="userActive"
          :classForGenerate="classForGenerate"
          @newPost="newPost"
        />
        <v-tab
          v-if="userActive"
          class="text-none mx-2"
          to="/administration"
          @click="adminRoute()"
        >
          {{ user.firstName }}
        </v-tab>
      </v-tabs>
      <v-btn
        v-if="userActive"
        icon
        class="text-none ml-n2"
        @click="adminRoute()"
      >
        <v-avatar v-if="user.profilPhoto" color="primary" size="39">
          <img alt="Avatar" :src="user.profilPhoto"
        /></v-avatar>
        <v-avatar v-else-if="userActive" color="grey lighten-1" size="39">
          <v-icon>mdi-account</v-icon></v-avatar
        >
      </v-btn>
      <v-menu v-if="userActive" bottom class="pl-n10" offset-y>
        <template v-slot:activator="{ on, attrs }"
          ><v-btn icon v-bind="attrs" v-on="on" class="mx-1"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </template>
        <v-card height="300" width="250">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-center mt-5"
                  ><strong>Paramètres</strong></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-title class="d-flex justify-center mt-n4">
                <v-btn plain class="text-none" @click="adminRoute()"
                  >Administrer mon compte</v-btn
                ></v-list-item-title
              >
            </v-list-item>
            <v-divider class="mt-n1 mb-1"></v-divider>
            <v-list-item>
              <v-list-item-title class="d-flex justify-center mt-n4">
                <v-btn
                  plain
                  class="text-none"
                  href="https://help.pret-a-poster.io"
                  target="_blank"
                  >Aide</v-btn
                ></v-list-item-title
              >
            </v-list-item>
            <v-divider class="mt-n2"></v-divider>
            <v-list-item>
              <v-list-item-title class="d-flex justify-center"
                ><v-btn plain class="text-none" @click="signOut()"
                  >Me déconnecter</v-btn
                ></v-list-item-title
              >
            </v-list-item>
            <v-divider class="mt-1"></v-divider>
            <v-list-item>
              <v-list-item-title class="d-flex justify-center"
                ><v-btn plain class="text-none" @click="update()">
                  <v-icon>mdi-reload</v-icon>
                </v-btn></v-list-item-title
              ></v-list-item
            >
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main
      class="color-main pt-14 pt-sm-14 pt-xs-16 pt-md-16 pt-lg-10 pt-xl-10 mt-lg-10 mt-xl-10 pb-md-12 pb-lg-12 pb-xl-12"
      :class="{ otherColor: isSignIn, otherColor: isSignInBis }"
    >
      <Snackbar />
      <v-divider class="hidden-lg-and-up"></v-divider>
      <router-view
        ref="view"
        class="mt-n3 mt-md-4 mt-lg-4 mt-xl-4 px-0 px-lg-7"
        @newPost="newPost"
      />
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Snackbar from "@/components/Snackbar.vue";
import GenerateStrategy from "@/components/GenerateStrategy.vue";
const fb = require("@/firebase/init.js");
export default {
  name: "App",
  components: {
    GenerateStrategy,
    Snackbar,
  },

  data: () => ({
    classForGenerate: "text-none white--text mr-2 mt-3",
    drawer: false,
    group: null,
    profilPhoto: null,
    userActive: false,
  }),
  created() {
    fb.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const usersRef = fb.db.collection("customers").doc(user.uid);

        usersRef.get().then((docSnapshot) => {
          if (docSnapshot.exists) {
            usersRef.onSnapshot((doc) => {
              fb.firebase
                .firestore()
                .collection("customers")
                .doc(user.uid)
                .collection("subscriptions")
                .get()
                .then((snapshot) => {
                  snapshot.forEach((doc) => {
                    if (
                      doc.data().status == "active" ||
                      doc.data().status == "trialing"
                    ) {
                      this.userActive = true;
                    } else if (doc.data().status == "past_due") {
                      this.userActive = false;
                    } else {
                    }
                  });
                });
            });
          } else {
            fb.db
              .collection("clients")
              .doc(fb.firebase.auth().currentUser.uid)
              .get()
              .then((doc) => {
                if (doc.exists) {
                  let PaidOrNot = doc.data().paid;
                  let offer = doc.data().offer;
                  if (PaidOrNot === true) {
                    this.userActive = true;
                  } else {
                    if (offer === "busi") {
                      this.userActive = false;
                    } else {
                      this.userActive = false;
                    }
                  }
                }
              });
          }
        });
      } else {
        // User is signed out
        // ...
      }
    });
  },
  computed: {
    ...mapState(["user"]),
    isSignIn() {
      return this.$route.path === "/inscription";
    },
    isSignInBis() {
      return this.$route.path === "/login";
    },
  },
  methods: {
    async portail() {
      const functionRef = fb.firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
      const { data } = await functionRef({
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    },
    adminRoute() {
      if (this.$route.name == "Administration") {
      } else {
        this.$router.push({ name: "Administration" });
      }
    },
    captionsRoute() {
      if (this.$route.name == "Templates") {
      } else {
        this.$router.push({ name: "Templates" });
      }
    },
    homeRoute() {
      if (this.$route.name == "Home") {
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    newPost() {
      this.$refs.view.newPost();
      this.$emit("newPost");
      // this.$router.go("/");
    },
    photosRoute() {
      if (this.$route.name == "Photos") {
      } else {
        this.$router.push({ name: "Photos" });
      }
    },
    async signOut() {
      try {
        const data = await fb.auth.signOut();
        this.$store.commit("setSnackbar", {
          color: "success",
          timeout: 3000,
          text: "Déconnexion réussie",
        });
      } catch (err) {
        console.log(err);
      }
      this.$router.go("/");
    },
    update() {
      this.$router.push("/update");
      setTimeout(function () {
        location.reload();
      }, 40);
    },
  },
};
</script>
<style>
.Titillium {
  font-family: "Titillium Web", sans-serif;
}
.row-pointer:hover {
  cursor: pointer;
}
.color-main {
  background-color: #fafafa;
}
.otherColor {
  background-color: #ffffff;
}
.shadow-app {
  -webkit-box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06);
}
</style>
