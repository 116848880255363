<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col class="hidden-md-and-up" sm="1" xs="1"></v-col>
        <v-col xl="3" lg="3" md="3" sm="9" cols="10">
          <v-row
            justify-xl="start"
            justify-lg="start"
            justify-md="start"
            justify-sm="center"
            justify="center"
            ><h2>Mon profil</h2></v-row
          >
          <v-row><strong>Nom</strong></v-row>
          <v-row
            ><v-text-field
              ref="name"
              v-model="name"
              :rules="nameRules"
              placeholder="Doe"
              type="text"
              outlined
              dense
              required
            ></v-text-field
          ></v-row>
          <v-row class="mt-n2"><strong>Prénom</strong></v-row>
          <v-row
            ><v-text-field
              ref="firstName"
              v-model="firstName"
              :rules="firstnameRules"
              placeholder="John"
              type="text"
              outlined
              dense
              required
            ></v-text-field
          ></v-row>
          <v-row class="mt-n2"><strong>Mail</strong></v-row>
          <v-row
            ><v-text-field
              ref="email"
              v-model="email"
              :rules="emailRules"
              placeholder="john@doe.com"
              type="mail"
              outlined
              dense
              required
            ></v-text-field
          ></v-row>
          <v-row class="mt-n2"><strong>Image de profil</strong></v-row>
          <v-row
            ><v-file-input
              v-model="photo"
              accept="image/*"
              label="Cliquez pour télécharger une image"
              prepend-icon="mdi-file-image-plus-outline"
              class="mt-3 mb-n2"
              outlined
              dense
            ></v-file-input
          ></v-row>
          <v-row><strong>Nom de l'entreprise</strong></v-row>
          <v-row
            ><v-text-field
              v-model="nameOfEnterprise"
              placeholder="Nom de l'entreprise (optionnel)"
              class="mb-n3"
              outlined
              dense
            ></v-text-field>
          </v-row>
          <v-row><strong>Numéro de TVA</strong></v-row>
          <v-row
            ><v-text-field
              v-model="numberOfTva"
              placeholder="Numéro de TVA (optionnel)"
              class="mb-n3"
              outlined
              dense
            ></v-text-field>
          </v-row>
          <v-row><strong>Adresse de facturation</strong></v-row>
          <v-row
            ><v-text-field
              ref="address"
              v-model="address"
              :rules="addressRules"
              placeholder="Numéro et nom de la rue"
              class="mb-n3"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="pt-1"
            ><v-text-field
              ref="postalCode"
              v-model="postalCode"
              :rules="postalRules"
              placeholder="Code postal"
              class="mb-n3"
              type="text"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row class="pt-1"
            ><v-text-field
              ref="city"
              v-model="city"
              :rules="cityRules"
              placeholder="Ville"
              type="text"
              outlined
              dense
              required
            ></v-text-field>
          </v-row>
          <v-row v-if="(client && updateProfil() && valid) || photo != null">
            <v-card-actions>
              <v-row class="justify-center pt-2">
                <v-btn
                  class="text-none px-9 mt-n3 ml-1 mb-5"
                  color="primary"
                  elevation="0"
                  :disabled="loading"
                  :loading="loading"
                  @click="modifyClient()"
                  >Valider les changements</v-btn
                >
              </v-row>
            </v-card-actions></v-row
          >
          <v-form ref="form" v-model="validPassword" lazy-validation>
            <v-row class="mt-5"
              ><strong>Modifier mon mot de passe</strong></v-row
            >
            <v-row
              ><v-text-field
                ref="currentPassword"
                v-model="currentPassword"
                class="mt-n2 pt-2"
                placeholder="Entrer votre mot de passe actuel"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showCurrentPassword = !showCurrentPassword"
                :type="showCurrentPassword ? 'text' : 'password'"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row v-if="currentPassword.length >= 6"
              ><v-text-field
                ref="password"
                v-model="password"
                class="mt-n2"
                placeholder="Entrer mon nouveau mot de passe"
                :rules="passwordRules"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row v-if="password.length >= 6"
              ><v-text-field
                ref="passwordV"
                v-model="passwordV"
                class="mt-n2"
                placeholder="Confirmez votre nouveau mot de passe"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :type="show1 ? 'text' : 'password'"
                :rules="passwordConfirmRules"
                outlined
                dense
              ></v-text-field>
            </v-row>
            <v-row v-if="passwordV && validPassword">
              <v-card-actions>
                <v-row class="justify-center pt-2">
                  <v-btn
                    class="text-none px-3 mt-n3 ml-1 mb-3"
                    color="primary"
                    elevation="0"
                    :disabled="loading"
                    :loading="loading"
                    @click="modifyPassword()"
                    >Valider le nouveau mot de passe</v-btn
                  >
                </v-row>
              </v-card-actions></v-row
            >
          </v-form>

          <v-row
            class="pt-6"
            justify-xl="start"
            justify-lg="start"
            justify-md="start"
            justify-sm="center"
            justify="center"
            ><h2>Mes comptes liés</h2></v-row
          >
          <v-row v-if="nbAccountInsta > 1" class="pt-5 pb-4"
            ><strong>Comptes Instagram</strong></v-row
          >
          <v-row v-else class="pt-5 pb-4"
            ><strong>Compte Instagram</strong></v-row
          >
          <v-form v-model="isFormValid"
            ><v-row>
              <v-text-field
                ref="emailInstagram"
                v-model="emailInstagram"
                :rules="accountRules"
                placeholder="@johnDoe15"
                type="email"
                :disabled="nbAccountInsta === 4"
                oncopy="return false"
                onpaste="return false"
                outlined
                dense
                required
              ></v-text-field> </v-row
          ></v-form>
          <v-form v-model="isFormValidBis"
            ><v-row
              v-if="nbAccountInsta >= 2 && nbAccountInsta < 4"
              class="ml-n6 mt-n5"
              ><v-col cols="10" class="mr-8 pr-1"
                ><v-text-field
                  ref="emailInstagramBis"
                  v-model="emailInstagramBis"
                  :rules="accountRules"
                  placeholder="@johnDoe15"
                  type="email"
                  :disabled="nbAccountInsta === 4"
                  oncopy="return false"
                  onpaste="return false"
                  outlined
                  dense
                  required
                ></v-text-field
              ></v-col>
              <v-col cols="2" class="ml-n8"
                ><v-btn
                  small
                  icon
                  @click="nbAccountInsta--, (emailInstagramBis = 'delete')"
                  class="mt-1"
                >
                </v-btn
              ></v-col> </v-row
          ></v-form>
          <v-form v-model="isFormValidTer"
            ><v-row
              v-show="nbAccountInsta >= 3 && nbAccountInsta < 4"
              class="ml-n6 mt-n8"
              ><v-col cols="10" class="mr-8 pr-1"
                ><v-text-field
                  ref="emailInstagramTer"
                  v-model="emailInstagramTer"
                  :rules="accountRules"
                  placeholder="@johnDoe15"
                  type="email"
                  :disabled="nbAccountInsta === 4"
                  oncopy="return false"
                  onpaste="return false"
                  outlined
                  dense
                  required
                ></v-text-field
              ></v-col>
              <v-col cols="2" class="ml-n8"
                ><v-btn
                  icon
                  @click="nbAccountInsta--, (emailInstagramTer = '')"
                  class="mt-1"
                >
                  <v-icon size="30" dark left color="primary"
                    >mdi-minus-circle</v-icon
                  ></v-btn
                ></v-col
              >
            </v-row></v-form
          >
          <v-row class="">
            <v-btn
              v-if="nbAccountInsta <= 1"
              text
              class="text-none ml-n1 mt-2"
              :disabled="isFormValid"
              @click="nbAccountInsta++, (emailInstagramBis = '')"
            >
              <v-icon size="28" dark left color="primary" class="mr-3"
                >mdi-plus-circle</v-icon
              >Ajouter un compte</v-btn
            >
            <v-btn
              v-else-if="nbAccountInsta > 1 && nbAccountInsta < 3"
              text
              class="text-none ml-n1"
              :disabled="!isFormValidBis"
              @click="nbAccountInsta++, (emailInstagramTer = '')"
            >
              <v-icon size="28" dark left color="primary" class="mr-3"
                >mdi-plus-circle</v-icon
              >Ajouter un compte</v-btn
            >
            <v-btn
              v-else-if="nbAccountInsta > 2 && nbAccountInsta < 4"
              text
              class="text-none ml-n1"
              :disabled="!isFormValidTer"
              @click="(dialog = true), (offerAskChange = 'busi')"
            >
              <v-icon size="30" dark left color="primary" class="mr-3"
                >mdi-plus-circle</v-icon
              >Passer à l'offre business</v-btn
            >
          </v-row>

          <v-row
            v-if="emailInstagram && updateInstagramEmail()"
            class="justify-center pt-5"
          >
            <v-card-actions>
              <v-btn
                class="text-none mr-6 px-6"
                color="primary"
                elevation="0"
                :disabled="loading"
                :loading="loading"
                @click="modifyInstagramEmail()"
                >Modifier le compte Instagram</v-btn
              >
            </v-card-actions></v-row
          >
          <v-row
            v-if="emailInstagram && updateInstagramEmail()"
            class="justify-center hidden-sm-and-down pt-2"
          >
            <v-card-actions>
              <v-btn
                class="text-none mx-auto px-8"
                :disabled="loading"
                :loading="loading"
                text
                @click="$router.push('/')"
                >Annuler</v-btn
              >
            </v-card-actions></v-row
          >
          <v-row v-if="offer && client.offer != offer && offer === 'mult'">
            <font
              size="1"
              style="text-align: start; font-weight: bold"
              class="hidden-sm-and-down mt-3"
              ><b>Vous êtes en train d’entrer un deuxième compte :</b><br />
              En ajoutant 1 à 2 comptes supplémentaires, vous faites évoluer
              votre abonnement vers l’Offre “Multipreneur” (voir ci-contre).
              Après validation, vous serez redirigé vers la page de paiement.
              Prêt-à-Poster facturera automatiquement un pro-rata sur la base de
              ce que vous versez déjà dans votre abonnement actuel.</font
            >
            <font
              size="1"
              style="text-align: start; font-weight: bold"
              class="hidden-md-and-up mt-3"
            >
              <b>Vous êtes en train d’entrer un deuxième compte :</b><br />
              En ajoutant 1 à 2 comptes supplémentaires, vous faites évoluer
              votre abonnement vers l’Offre “Multipreneur” (voir ci-contre).
              Après validation, vous serez redirigé vers la page de paiement.
              Prêt-à-Poster facturera automatiquement un pro-rata sur la base de
              ce que vous versez déjà dans votre abonnement actuel.</font
            ></v-row
          ><v-row v-else-if="offer && client.offer != offer && offer === 'busi'"
            ><font
              size="1"
              style="text-align: start; font-weight: bold"
              class="hidden-md-and-up mt-3"
              ><b>Vous êtes en train d’entrer un quatrième compte :</b><br />
              A partir de 4 comptes, et au-delà, vous faites évoluer votre
              abonnement vers l’Offre “Business” (voir ci-contre). Après
              validation, vous serez redirigé vers une page de mise en relation.
              Prêt-à-Poster vous contactera pour effectuer avec vous un devis
              personnalisé qui correspond à vos besoins.
            </font>
            <font
              size="1"
              style="text-align: start; font-weight: bold"
              class="hidden-sm-and-down mt-3"
              ><b>Vous êtes en train d’entrer un quatrième compte :</b><br />
              A partir de 4 comptes, et au-delà, vous faites évoluer votre
              abonnement vers l’Offre “Business” (voir ci-contre). Après
              validation, vous serez redirigé vers une page de mise en relation.
              Prêt-à-Poster vous contactera pour effectuer avec vous un devis
              personnalisé qui correspond à vos besoins.
            </font></v-row
          >
        </v-col>
        <v-col xl="1" lg="1" md="1" sm="1" xs="1"></v-col>
        <v-col xl="8" lg="8" md="8" sm="11" xs="auto"
          ><v-row class="mt-6 hidden-md-and-up"></v-row>
          <v-row
            class="mb-6"
            justify-xl="start"
            justify-lg="start"
            justify-md="start"
            justify-sm="center"
            justify="center"
            ><h2>Mon offre actuelle</h2></v-row
          >
          <v-row
            ><p class="hidden-md-and-up ml-8" ALIGN="center">
              Vous pouvez upgrader votre abonnement à tout moment. La plateforme
              facturera un pro-rata sur la base de ce que vous versez déjà dans
              votre abonnement actuel.
            </p>
            <p class="hidden-sm-and-down" ALIGN="start">
              Vous pouvez upgrader votre abonnement à tout moment. La plateforme
              facturera un pro-rata sur la base de ce que vous versez déjà dans
              votre abonnement actuel.
            </p>
          </v-row>
          <v-row dense justify="center" class="ml-n16">
            <v-avatar
              v-if="billing === 'annual'"
              color="primary"
              size="49"
              class="hidden-xs-only white--text mt-6 mb-2"
              >-20%</v-avatar
            >
            <v-icon
              v-if="billing === 'annual'"
              color="primary"
              class="hidden-xs-only white--text mr-10 mt-n10 ml-n1"
              >mdi-undo</v-icon
            >

            <v-avatar
              v-if="billing === 'annual'"
              color="primary"
              size="39"
              class="hidden-sm-and-up white--text mt-4 mb-2"
              >-20%</v-avatar
            >
            <v-icon
              v-if="billing === 'annual'"
              color="primary"
              class="hidden-sm-and-up white--text mt-n12 mr-8 ml-n3"
              >mdi-undo</v-icon
            >

            <v-btn-toggle
              v-model="billing"
              mandatory
              style="background-color: #fafafa"
              class="ml-n8"
              ><v-btn
                v-if="billing === 'annual'"
                class="rounded text-none white--text mt-4 px-10 mr-3"
                value="annual"
                height="35"
                color="primary"
                @click="billing = 'annual'"
                >Annuel</v-btn
              >
              <v-btn
                v-else
                class="rounded text-none mt-5 px-10 mb-6 ml-16"
                value="annual"
                height="35"
                color="white"
                @click="billing = 'annual'"
                >Annuel</v-btn
              >
              <v-btn
                v-if="billing === 'monthly'"
                class="rounded text-none white--text mt-5 px-10 ml-6 mb-4 mr-n4"
                value="monthly"
                height="35"
                color="primary"
                @click="billing = 'monthly'"
                >Mensuel</v-btn
              ><v-btn
                v-else
                class="rounded text-none mt-4 px-10 ml-3"
                style="border: 1px solid #dfe0eb"
                value="monthly"
                height="35"
                color="white"
                @click="billing = 'monthly'"
                >Mensuel</v-btn
              >
            </v-btn-toggle></v-row
          >
          <v-row dense class="mt-8 mx-n4">
            <v-dialog
              v-model="dialog"
              transition="dialog-bottom-transition"
              max-width="600"
            >
              <v-card>
                <v-toolbar color="primary" dark>
                  Envoyer une demande de changement d'offre ?</v-toolbar
                >
                <v-card-text>
                  <div class="text-h6 pa-12">
                    Un membre de l'équipe Prêt-à-Poster vous contactera pour
                    valider votre changement d'offre.
                  </div>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn plain @click="dialog = false">Annuler</v-btn>
                  <v-btn color="primary" @click="sendEmail(), (dialog = false)"
                    >Envoyer ma demande</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col xl="4" lg="3" md="3" sm="12" cols="12">
              <v-row justify="center" class="mb-4 ml-md-1 ml-lg-6 ml-xl-1">
                <v-card
                  :color="colorSolo"
                  min-width="220"
                  max-width="280"
                  :dark="darkSolo"
                  min-height="328"
                >
                  <v-card-title class="justify-center pt-10 text-h6">
                    Offre Solopreneur
                  </v-card-title>
                  <v-row>
                    <v-col
                      class="hidden-sm-and-down"
                      xl="2"
                      lg="2"
                      md="2"
                    ></v-col>
                    <v-col xl="8" lg="8" md="8" sm="12" cols="12">
                      <v-row class="justify-center pt-4"
                        ><h4>Je souhaite poster sur</h4></v-row
                      >
                      <v-row class="justify-center"><h4>1 compte</h4></v-row>
                      <v-row
                        v-if="billing === 'annual'"
                        class="justify-center pt-4 police_fine"
                        >45€/mois</v-row
                      ><v-row v-else class="justify-center pt-4 police_fine"
                        >59€/mois</v-row
                      ><v-row
                        v-if="billing === 'annual'"
                        class="justify-center police_fine police_down mt-3"
                        >facturé annuellement</v-row
                      ><v-row
                        v-else
                        class="justify-center police_fine police_down mt-3"
                        >facturé mensuellement</v-row
                      >
                      <v-row
                        v-if="billing === 'monthly'"
                        class="justify-center police_fine police_down"
                        >(sans engagement)</v-row
                      ><v-row
                        v-else
                        class="justify-center police_fine police_down"
                        >&nbsp;</v-row
                      >
                    </v-col>
                    <v-col
                      class="hidden-sm-and-down"
                      xl="2"
                      lg="2"
                      md="2"
                    ></v-col>
                  </v-row>
                  <v-row class="justify-center pt-7">
                    <v-card-actions>
                      <v-btn
                        v-if="colorSolo === 'white'"
                        class="text-none mx-auto px-5"
                        :disabled="loading"
                        :loading="loading"
                        elevation="0"
                        color="primary"
                        height="30"
                        @click="changeOffer(), (offerAskChange = 'solo')"
                        >Modifier l'offre</v-btn
                      ><v-btn
                        v-else-if="billingTemp != billing"
                        class="text-none mx-auto px-5"
                        text
                        elevation="0"
                        :disabled="loading"
                        :loading="loading"
                        color="white"
                        height="30"
                        @click="changeOffer(), (offerAskChange = 'solo')"
                        >Modifier l'offre</v-btn
                      >
                    </v-card-actions></v-row
                  >
                </v-card>
              </v-row>
            </v-col>
            <v-col xl="4" lg="6" md="6" sm="12" cols="12">
              <v-row justify="center" class="mb-4 mx-md-2">
                <v-card
                  :color="colorMult"
                  min-width="220"
                  max-width="280"
                  min-height="328"
                  :dark="darkMult"
                >
                  <v-card-title class="pt-10 text-h6 justify-center">
                    Offre Multipreneur
                  </v-card-title>
                  <v-row>
                    <v-col
                      class="hidden-sm-and-down"
                      xl="2"
                      lg="2"
                      md="2"
                    ></v-col>
                    <v-col xl="8" lg="8" md="8" sm="12" cols="12">
                      <v-row class="justify-center pt-4"
                        ><h4>Je souhaite poster sur</h4></v-row
                      >
                      <v-row class="justify-center"
                        ><h4>2 à 3 comptes</h4></v-row
                      >
                      <v-row
                        v-if="billing === 'annual'"
                        class="justify-center pt-4 police_fine"
                        >59€/mois</v-row
                      ><v-row v-else class="justify-center pt-4 police_fine"
                        >75€/mois</v-row
                      ><v-row
                        v-if="billing === 'annual'"
                        class="justify-center police_fine police_down mt-3"
                        >facturé annuellement</v-row
                      ><v-row
                        v-else
                        class="justify-center police_fine police_down mt-3"
                        >facturé mensuellement</v-row
                      >
                      <v-row
                        v-if="billing === 'monthly'"
                        class="justify-center police_fine police_down"
                        >(sans engagement)</v-row
                      ><v-row
                        v-else
                        class="justify-center police_fine police_down"
                        >&nbsp;</v-row
                      >
                    </v-col>
                    <v-col
                      class="hidden-sm-and-down"
                      xl="2"
                      lg="2"
                      md="2"
                    ></v-col>
                  </v-row>
                  <v-row class="justify-center pt-7">
                    <v-card-actions>
                      <v-btn
                        v-if="colorMult === 'white'"
                        class="text-none mx-auto px-5"
                        elevation="0"
                        :disabled="loading"
                        :loading="loading"
                        color="primary"
                        height="30"
                        @click="changeOffer(), (offerAskChange = 'multi')"
                        >Modifier l'offre</v-btn
                      ><v-btn
                        v-else-if="billingTemp != billing"
                        class="text-none mx-auto px-5"
                        text
                        elevation="0"
                        :disabled="loading"
                        :loading="loading"
                        color="white"
                        height="30"
                        @click="changeOffer(), (offerAskChange = 'multi')"
                        >Modifier l'offre</v-btn
                      >
                    </v-card-actions></v-row
                  >
                </v-card>
              </v-row>
            </v-col>
            <v-col xl="4" lg="3" md="3" sm="12" cols="12">
              <v-row justify="center" class="mr-md-1 mr-lg-6 mr-xl-1">
                <v-card
                  :color="colorBusi"
                  min-width="220"
                  max-width="220"
                  min-height="328"
                  :dark="darkBusi"
                >
                  <v-card-title class="pt-10 text-h6 justify-center">
                    Offre Business
                  </v-card-title>
                  <v-row>
                    <v-col xl="2" lg="2" md="2" sm="1" cols="1"></v-col>
                    <v-col xl="8" lg="8" md="8" sm="10" cols="10">
                      <v-row class="pt-4 police_down_align_center"
                        ><h4>
                          Je suis une agence ou un community manager et je
                          souhaite utiliser la plateforme pour mes clients
                        </h4></v-row
                      ></v-col
                    >
                    <v-col xl="2" lg="2" md="2" sm="1" cols="1"></v-col>
                  </v-row>
                  <v-row class="justify-center mt-12">
                    <v-card-actions>
                      <v-btn
                        v-if="colorBusi === 'white'"
                        class="text-none mx-auto px-5"
                        elevation="0"
                        :disabled="loading"
                        :loading="loading"
                        color="primary"
                        height="30"
                        @click="(dialog = true), (offerAskChange = 'busi')"
                        >Être recontacté(e)</v-btn
                      ><v-btn
                        v-else-if="billingTemp != billing"
                        class="text-none mx-auto px-5"
                        text
                        elevation="0"
                        :disabled="loading"
                        :loading="loading"
                        color="white"
                        height="30"
                        @click="(dialog = true), (offerAskChange = 'busi')"
                        >Être recontacté(e)</v-btn
                      >
                    </v-card-actions></v-row
                  >
                </v-card>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="hidden-md-and-up mt-8"></v-row>
          <v-dialog
            v-model="dialogUnscribe"
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <v-card>
              <v-toolbar color="primary" dark>
                Envoyer une demande de résiliation ?</v-toolbar
              >
              <v-card-text>
                <div class="text-h6 pa-12">
                  Un membre de l'équipe Prêt-à-Poster vous contactera pour
                  valider la résiliation de votre abonnement.
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn plain @click="dialogUnscribe = false">Annuler</v-btn>
                <v-btn
                  color="primary"
                  @click="sendEmail('delete'), (dialogUnscribe = false)"
                  >Envoyer ma demande</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-row class="pt-14 hidden-sm-and-down" justify="end">
            <v-btn small plain class="text-none" @click="clientAskUnscribe()"
              >Je souhaite résilier mon abonnement</v-btn
            >
          </v-row>
          <v-row class="mb-n6 hidden-md-and-up" justify="center">
            <v-btn small plain class="text-none" @click="clientAskUnscribe()"
              >Je souhaite résilier mon abonnement</v-btn
            >
          </v-row>
          <v-row class="pt-14 ml-1"><h2>Paiement et facturation</h2></v-row>
          <v-row class="mt-2 ml-n2 text-node" justify="start">
            <v-btn
              small
              text
              color="primary"
              class="text-none"
              @click="clientAskUpdateBilling()"
              >Mettre à jour mes informations de paiement</v-btn
            >
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import emailjs from "@emailjs/browser";
const fb = require("@/firebase/init.js");
export default {
  name: "Administration",
  data() {
    return {
      address: null,
      billing: null,
      billingTemp: null,
      city: null,
      colorSolo: "primary",
      colorMult: "white",
      colorBusi: "white",
      client: null,
      currentPassword: "",
      darkSolo: false,
      darkMult: false,
      darkBusi: false,
      dialog: false,
      dialogUnscribe: false,
      email: null,
      emailInstagram: null,
      emailInstagramBis: null,
      emailInstagramTer: null,
      errorMsg: null,
      firstName: null,
      id: null,
      isFormValid: false,
      isFormValidBis: false,
      isFormValidTer: false,
      loading: false,
      name: null,
      nameOfEnterprise: "",
      nbAccountInsta: 0,
      numberOfTva: "",
      offer: null,
      offerAskChange: null,
      oldClient: false,
      postalCode: null,
      password: "",
      passwordV: null,
      photo: null,
      show: false,
      show1: false,
      showCurrentPassword: false,
      valid: true,
      validPassword: true,
      accountRules: [
        (v) =>
          !!v || "Le compte Instagram est nécessaire, exemple : @johnDoe15",
        (v) =>
          (v && v.length <= 30) ||
          "Le compte Instagram ne peut pas contenir plus de 30 caractères",
      ],
      addressRules: [
        (v) => !!v || "L'adresse est nécessaire",
        (v) =>
          (v && v.length <= 60) ||
          "L'adresse ne doit pas dépasser 60 caractères",
      ],
      cityRules: [
        (v) => !!v || "Le nom de la ville est nécessaire",
        (v) =>
          (v && v.length <= 40) ||
          "Le nom de la ville ne peut pas dépasser 40 caractères",
      ],
      emailRules: [
        (v) => !!v || "L'email est nécessaire, exemple : john@doe.fr",
        (v) =>
          (v && v.length <= 40) ||
          "L'email ne peut pas contenir plus de 40 caractères",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return (
            pattern.test(value) ||
            "L'email doit être valide, exemple : john@doe.fr"
          );
        },
      ],
      firstnameRules: [
        (v) => !!v || "Le prénom est nécessaire",
        (v) => (v && v.length <= 30) || "Ne peut pas dépasser 20 caractères",
      ],
      nameRules: [
        (v) => !!v || "Le nom est nécessaire",
        (v) => (v && v.length <= 30) || "Ne peut pas dépasser 20 caractères",
      ],
      passwordConfirmRules: [
        (v) => !!v || "Veuillez confimer le mot de passe",
        (v) => v === this.password || "Les mots de passe ne correspondent pas",
      ],
      passwordRules: [
        (v) => !!v || "Le mot de passe est nécessaire",
        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit contenir au moins 6 caractères",
        (v) =>
          (v && v.length <= 40) ||
          "Le mot de passe ne peut pas contenir plus de 40 caractères",
      ],
      postalRules: [(v) => !!v || "Le code postal est nécessaire"],
    };
  },
  created() {
    let offer = null;
    let billing = null;
    const usersRef = fb.db
      .collection("customers")
      .doc(fb.firebase.auth().currentUser.uid);

    usersRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        this.oldClient = false;
        usersRef.onSnapshot((doc) => {
          fb.firebase
            .firestore()
            .collection("customers")
            .doc(fb.firebase.auth().currentUser.uid)
            .collection("subscriptions")
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                if (doc.data().price.id == "price_1Lrd2BCxGLgtuUQ0AZaImdO3") {
                  offer = "solo";
                  billing = "monthly";
                } else if (
                  doc.data().price.id == "price_1Lrd2BCxGLgtuUQ0zvDRtGLO"
                ) {
                  offer = "solo";
                  billing = "annual";
                } else if (
                  doc.data().price.id == "price_1Lrd2ICxGLgtuUQ0WmcyeiG3"
                ) {
                  offer = "mult";
                  billing = "monthly";
                } else if (
                  doc.data().price.id == "price_1Lrd2ICxGLgtuUQ0c7Gbdlvb"
                ) {
                  offer = "mult";
                  billing = "annual";
                } else {
                }
              });
              fb.db
                .collection("clients")
                .doc(fb.firebase.auth().currentUser.uid)
                .onSnapshot((doc) => {
                  this.client = doc.data();
                  this.name = this.client.name;
                  this.firstName = this.client.firstName;
                  this.email = this.client.email;
                  this.nameOfEnterprise = this.client.nameOfEnterprise;
                  this.numberOfTva = this.client.numberOfTva;
                  this.address = this.client.address;
                  this.postalCode = this.client.postalCode;
                  this.city = this.client.city;
                  this.id = this.client.id;
                  this.offer = offer;
                  this.chooseColorOffer(this.offer);
                  this.billing = billing;
                  this.billingTemp = billing;
                  if (this.offer === "solo") {
                    this.emailInstagram = this.client.emailInstagram[0];
                  } else if (this.offer === "mult") {
                    if (this.client.emailInstagram.length > 2) {
                      this.emailInstagram = this.client.emailInstagram[0];
                      this.emailInstagramBis = this.client.emailInstagram[1];
                      this.emailInstagramTer = this.client.emailInstagram[2];
                      this.nbAccountInsta = 3;
                    } else {
                      this.emailInstagram = this.client.emailInstagram[0];
                      this.emailInstagramBis = this.client.emailInstagram[1];
                      this.nbAccountInsta = 2;
                    }
                  } else {
                  }
                });
            });
        });
      } else {
        this.oldClient = true;
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              offer = doc.data().offer;
              billing = doc.data().billing;
              fb.db
                .collection("clients")
                .doc(fb.firebase.auth().currentUser.uid)
                .onSnapshot((doc) => {
                  this.client = doc.data();
                  this.name = this.client.name;
                  this.firstName = this.client.firstName;
                  this.email = this.client.email;
                  this.nameOfEnterprise = this.client.nameOfEnterprise;
                  this.numberOfTva = this.client.numberOfTva;
                  this.address = this.client.address;
                  this.postalCode = this.client.postalCode;
                  this.city = this.client.city;
                  this.id = this.client.id;
                  this.offer = offer;
                  this.chooseColorOffer(this.offer);
                  this.billing = billing;
                  this.billingTemp = billing;
                  if (this.offer === "solo") {
                    this.emailInstagram = this.client.emailInstagram[0];
                  } else if (this.offer === "mult") {
                    if (this.client.emailInstagram.length > 2) {
                      this.emailInstagram = this.client.emailInstagram[0];
                      this.emailInstagramBis = this.client.emailInstagram[1];
                      this.emailInstagramTer = this.client.emailInstagram[2];
                      this.nbAccountInsta = 3;
                    } else {
                      this.emailInstagram = this.client.emailInstagram[0];
                      this.emailInstagramBis = this.client.emailInstagram[1];
                      this.nbAccountInsta = 2;
                    }
                  } else {
                  }
                });
            }
          });
      }
    });
  },
  watch: {},
  methods: {
    clientAskUnscribe() {
      if (this.oldClient) {
        this.dialogUnscribe = true;
      } else {
        this.changeOffer();
      }
    },
    clientAskUpdateBilling() {
      if (this.oldClient) {
        this.$router.push("/");
      } else {
        this.changeOffer();
      }
    },
    async changeOffer() {
      if (this.oldClient) {
        this.dialog = true;
      } else {
        this.loading = true;
        const functionRef = fb.firebase
          .app()
          .functions("europe-west1")
          .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
        const { data } = await functionRef({
          returnUrl: window.location.origin + "/administration",
        });
        window.location.assign(data.url);
      }
    },
    cancelBusi() {
      if (this.emailInstagramBis != null) {
        if (this.emailInstagramTer != null) {
          this.nbAccountInsta = 3;
        } else {
          this.nbAccountInsta = 2;
        }
      } else {
        this.nbAccountInsta = 1;
      }
    },
    chooseColorOffer(offer) {
      if (offer === "solo") {
        this.offer === "solo";
        this.colorSolo = "primary";
        this.colorMult = "white";
        this.colorBusi = "white";
      } else if (offer === "mult") {
        this.offer === "mult";
        this.colorMult = "primary";
        this.colorSolo = "white";
        this.colorBusi = "white";
      } else if (offer === "busi") {
        this.offer === "busi";
        this.colorBusi = "primary";
        this.colorSolo = "white";
        this.colorMult = "white";
      } else {
      }
    },
    deleteInstagramEmail(nameInstagramEmail) {
      let instagramEmailRef = fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid);

      let removeInstagramEmail = instagramEmailRef.update({
        emailInstagram:
          fb.firebase.firestore.FieldValue.arrayRemove(nameInstagramEmail),
      });

      let docRef = fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .where("emailInstagram", "==", nameInstagramEmail)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.delete();
          });
        })
        .catch((error) => {
          console.log("Error in created(): ", error);
        });
    },
    modifyClient() {
      var self = this;
      this.loading = true;
      if (this.photo) {
        var self = this;
        var storageRef = fb.storageRef;
        this.loading = true;
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg",
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTask = storageRef
          .child("imagesProfils/" + this.client.id)
          .put(this.photo, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          fb.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case fb.firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case fb.firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
            this.loading = false;
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text: "Un problème est survenu, veuillez recommencer",
            });
            this.loading = false;
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              fb.db
                .collection("clients")
                .doc(fb.firebase.auth().currentUser.uid)
                .update({
                  profilPhoto: downloadURL,
                })
                .then(() => {})
                .catch((error) => {
                  self.$store.commit("setSnackbar", {
                    color: "error",
                    timeout: 4000,
                    text: error,
                  });
                });
              console.log("File available at", downloadURL);
              this.loading = false;
              self.$store.commit("setSnackbar", {
                color: "success",
                timeout: 4000,
                text: "La photo de profil a bien été téléchargée",
              });
            });
          }
        );
        this.photo = null;
      }
      if (this.email != this.client.email) {
        fb.auth.currentUser
          .updateEmail(this.email)
          .then(() => {
            self.$store.commit("setSnackbar", {
              color: "success",
              timeout: 4000,
              text: "L'email a bien été modifié par " + this.email,
            });
          })
          .catch((error) => {
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text: error,
            });
          });
      }
      if (this.$refs.form.validate()) {
        let emailInstagram = [];
        if (this.emailInstagram && this.offer === "solo" && this.isFormValid) {
          emailInstagram = [this.emailInstagram];
        }

        if (
          this.emailInstagramBis &&
          this.offer === "mult" &&
          this.isFormValidBis
        ) {
          emailInstagram = [this.emailInstagram, this.emailInstagramBis];
        }
        if (this.emailInstagramTer && this.offer === "mult") {
          emailInstagram = [
            this.emailInstagram,
            this.emailInstagramBis,
            this.emailInstagramTer,
          ];
        }
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .update({
            name: self.name,
            firstName: self.firstName,
            email: self.email,
            emailInstagram: emailInstagram,
            address: self.address,
            nameOfEnterprise: self.nameOfEnterprise,
            numberOfTva: self.numberOfTva,
            postalCode: self.postalCode,
            city: self.city,
            offer: self.offer,
            billing: self.billing,
          })
          .then(() => {
            this.loading = false;
            self.$store.commit("setSnackbar", {
              color: "success",
              timeout: 4000,
              text: "Les modifications ont bien été prises en compte",
            });
          })
          .catch((error) => {
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text: error,
            });
            this.loading = false;
          });
      } else {
        this.$store.commit("setSnackbar", {
          color: "error",
          timeout: 4000,
          text: "Certaines informations sont manquantes ou invalides",
        });
        this.loading = false;
      }
    },
    modifyInstagramEmail() {
      var self = this;
      let emailInstagram = [];
      if (this.emailInstagram && this.isFormValid) {
        emailInstagram = [this.emailInstagram];
        if (this.client.emailInstagram[0] != this.emailInstagram) {
          this.deleteInstagramEmail(this.client.emailInstagram[0]);
        }
      }
      if (this.emailInstagramBis && this.isFormValid && this.isFormValidBis) {
        emailInstagram = [this.emailInstagram, this.emailInstagramBis];
        if (this.client.emailInstagram[1] != this.emailInstagramBis) {
          if (this.client.emailInstagram[1]) {
            this.deleteInstagramEmail(this.client.emailInstagram[1]);
          }
        }
      }
      if (
        this.emailInstagramTer &&
        this.isFormValid &&
        this.isFormValidBis &&
        this.isFormValidTer
      ) {
        if (this.nbAccountInsta < 3) {
          this.deleteInstagramEmail(this.client.emailInstagram[2]);
        } else {
          emailInstagram = [
            this.emailInstagram,
            this.emailInstagramBis,
            this.emailInstagramTer,
          ];
          if (this.client.emailInstagram[2]) {
            this.deleteInstagramEmail(this.client.emailInstagram[2]);
          }
        }
      }
      if (this.client.emailInstagram.length === 3) {
        if (this.emailInstagramTer) {
        } else {
          this.deleteInstagramEmail(this.client.emailInstagram[2]);
        }
      }
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .update({
          emailInstagram: emailInstagram,
        })
        .then(() => {
          self.$store.commit("setSnackbar", {
            color: "success",
            timeout: 4000,
            text: "Les modifications ont bien été prises en compte",
          });
          this.loading = false;
        })
        .catch((error) => {
          self.$store.commit("setSnackbar", {
            color: "error",
            timeout: 4000,
            text: error,
          });
          this.loading = false;
        });
    },
    modifyOffer() {
      let self = this;
      var templateParams = {
        firstName: "Prénom : " + self.firstName,
        name: "Nom : " + self.name,
        email: "email : " + self.email,
        offer: "Choix de l'offre : " + self.offerAskChange,
        billing: "Choix de la facturation : " + self.offer,
        id: "id : " + self.id,
        subject:
          "Demande de changement d'offre par " +
          self.firstName +
          " " +
          self.name,
        message: "Un de vos clients souhaite changer d'offre !",
      };
      emailjs
        .send(
          "service_a05rae5",
          "template_3f62fjc",
          templateParams,
          "1FewyxFa-JoEAEU3M"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            console.log("FAILED...", error);
          }
        );
    },
    /*modifyOfferOldBeforeChangeForStripe() {
      let emailInstagram = [];
      let self = this;
      if (this.emailInstagram && this.isFormValid) {
        emailInstagram = [this.emailInstagram];
      }
      if (
        this.emailInstagramBis &&
        this.isFormValid &&
        this.isFormValidBis &&
        this.emailInstagramBis != "delete"
      ) {
        emailInstagram = [this.emailInstagram, this.emailInstagramBis];
      } else if (this.emailInstagramBis === "delete") {
        this.deleteInstagramEmail(this.client.emailInstagram[1]);
      }
      if (
        this.emailInstagramTer &&
        this.isFormValid &&
        this.isFormValidBis &&
        this.emailInstagramTer != "delete"
      ) {
        emailInstagram = [
          this.emailInstagram,
          this.emailInstagramBis,
          this.emailInstagramTer,
        ];
      } else if (this.emailInstagramTer === "delete") {
        this.deleteInstagramEmail(this.client.emailInstagram[2]);
      }
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .update({
          emailInstagram: emailInstagram,
          offer: self.offer,
          billing: self.billing,
        })
        .then(() => {
          self.$store.commit("setSnackbar", {
            color: "success",
            timeout: 4000,
            text: "Les modifications ont bien été prises en compte",
          });
          this.loading = false;
        })
        .catch((error) => {
          self.$store.commit("setSnackbar", {
            color: "error",
            timeout: 4000,
            text: error,
          });
          this.loading = false;
        });
    },*/
    modifyPassword() {
      this.loading = true;
      let self = this;
      if (this.currentPassword) {
        const user = fb.firebase.auth().currentUser;
        const credential = fb.firebase.auth.EmailAuthProvider.credential(
          user.email,
          self.currentPassword
        );
        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            if (self.password) {
              if (self.validPassword) {
                fb.auth.currentUser
                  .updatePassword(self.password)
                  .then(() => {
                    self.$store.commit("setSnackbar", {
                      color: "success",
                      timeout: 4000,
                      text: "Le mot de passe a bien été modifié",
                    });
                    self.currentPassword = "";
                    self.password = "";
                    self.passwordV = null;
                    this.loading = false;
                  })
                  .catch((error) => {
                    self.$store.commit("setSnackbar", {
                      color: "error",
                      timeout: 4000,
                      text: "Le mot de passe n'a pas pu être modifié, essayez de vous déconnecter et recommencez",
                    });
                    this.loading = false;
                    console.log(error);
                  });
              } else {
                this.$store.commit("setSnackbar", {
                  color: "error",
                  timeout: 4000,
                  text: "Les mots de passes ne correspondent pas",
                });
                this.loading = false;
              }
            }
          })
          .catch((error) => {
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text:
                "Le mot de passe actuel ne correspond pas à celui du compte " +
                this.client.email,
            });
            this.loading = false;
            console.log(error);
          });
      }
    },
    sendEmail(userAskDeleteAccount) {
      let self = this;
      if (userAskDeleteAccount) {
        if (this.oldClient) {
          var templateParams = {
            firstName: "Prénom : " + self.firstName,
            name: "Nom : " + self.name,
            email: "email : " + self.email,
            offer: "Choix de l'offre : " + self.offer,
            billing: "Choix de la facturation : " + self.billing,
            id: "id : " + self.id,
            subject:
              "Demande de résiliation d'offre par " +
              self.firstName +
              " " +
              self.name,
            message: "Un de vos clients souhaite résilier son offre !",
          };
          emailjs
            .send(
              "service_a05rae5",
              "template_3f62fjc",
              templateParams,
              "1FewyxFa-JoEAEU3M"
            )
            .then(
              function (response) {
                console.log("SUCCESS!", response.status, response.text);
                self.$store.commit("setSnackbar", {
                  color: "success",
                  timeout: 4000,
                  text: "Votre demande de résiliation a bien été prise en compte",
                });
              },
              function (error) {
                console.log("FAILED...", error);
              }
            );
        } else {
          console.log(
            "Problème dans les cas d'anciens clients et des nouveaux"
          );
        }
      } else {
        var templateParams = {
          firstName: "Prénom : " + self.firstName,
          name: "Nom : " + self.name,
          email: "email : " + self.email,
          offer: "Choix de l'offre : " + self.offerAskChange,
          billing: "Choix de la facturation : " + self.billing,
          id: "id : " + self.id,
          subject:
            "Demande de changement d'offre par " +
            self.firstName +
            " " +
            self.name,
          message: "Un de vos clients souhaite changer d'offre !",
        };
        emailjs
          .send(
            "service_a05rae5",
            "template_3f62fjc",
            templateParams,
            "1FewyxFa-JoEAEU3M"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
              self.$store.commit("setSnackbar", {
                color: "success",
                timeout: 4000,
                text: "Votre demande de changement d'offre a bien été prise en compte",
              });
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
      }
    },
    updateInstagramEmail() {
      if (
        this.client.emailInstagram[0] &&
        this.client.emailInstagram[0] != this.emailInstagram
      ) {
        return true;
      } else if (
        (this.client.emailInstagram[1] &&
          this.client.emailInstagram[1] != this.emailInstagramBis) ||
        (this.nbAccountInsta == 2 &&
          this.emailInstagramBis &&
          this.emailInstagramBis != this.client.emailInstagram[1])
      ) {
        return true;
      } else if (
        (this.client.emailInstagram[2] &&
          this.client.emailInstagram[2] != this.emailInstagramTer) ||
        (this.isFormValidTer &&
          this.client.emailInstagram[2] != this.emailInstagramTer)
      ) {
        return true;
      } else {
        return false;
      }
    },
    updateProfil() {
      if (
        this.name != this.client.name ||
        this.firstName != this.client.firstName ||
        this.email != this.client.email ||
        this.address != this.client.address ||
        this.postalCode != this.client.postalCode ||
        this.nameOfEnterprise != this.client.nameOfEnterprise ||
        this.numberOfTva != this.client.numberOfTva ||
        this.city != this.client.city
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border: 2px solid;
  border-color: #dfe0eb;
}
</style>
<style>
.v-file-input {
  flex-direction: row-reverse;
}
</style>
