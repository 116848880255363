<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="user"
        color="primary"
        elevation="0"
        v-bind="attrs"
        v-on="on"
        :class="classForGenerate"
      >
        <v-icon left>mdi-plus</v-icon>
        Générer ma stratégie
      </v-btn></template
    ><v-card min-height="700" :loading="loading">
      <v-spacer></v-spacer>
      <v-card-title class="pt-12 text-h6 font-weight-regular justify-center">
        <span class="mt-16">{{ currentTitle }}</span>
      </v-card-title>
      <v-btn v-if="step === 1" :disabled="loading" icon class="mt-8"
        ><v-avatar
          color="primary lighten-1"
          class="white--text text-h6"
          size="40"
          v-text="1"
        ></v-avatar></v-btn
      ><v-btn v-else icon class="mt-8" :disabled="loading" @click="step = 1"
        ><v-avatar
          color="grey lighten-1"
          class="white--text"
          size="28"
          v-text="1"
        ></v-avatar
      ></v-btn>
      <v-btn
        v-if="step === 2"
        icon
        :disabled="loading"
        class="mt-8 ml-6 text-h6"
      >
        <v-avatar
          color="primary lighten-1"
          class="white--text"
          size="40"
          v-text="2"
        ></v-avatar
      ></v-btn>
      <v-btn
        v-else
        icon
        class="mt-8 ml-6"
        :disabled="loading"
        @click="step = 2"
      >
        <v-avatar
          color="grey lighten-1"
          class="white--text"
          size="28"
          v-text="2"
        ></v-avatar
      ></v-btn>
      <v-window v-model="step" class="mt-10 pt-3">
        <v-window-item :value="1">
          <v-row class="mb-16 mt-2">
            <v-col class="hidden-xs-only"></v-col>
            <v-col class="hidden-sm-and-up" cols="3"></v-col>
            <v-col cols="6" sm="5" md="5" lg="5" xl="5">
              <v-form ref="form" v-model="valid">
                <v-row>
                  <v-select
                    v-if="user"
                    v-model="emailInstagramForGenerateStrat"
                    :items="user.emailInstagram"
                    filled
                    append-icon="mdi-chevron-down"
                    label="Choix du compte"
                    :rules="accountRules"
                    required
                  ></v-select>
                </v-row>
                <v-row class="mt-n4">
                  <v-btn
                    block
                    height="55"
                    elevation="0"
                    class="mt-3 mb-2 text-none"
                    :disabled="loading"
                    @click="goalsDayShowOrNot = !goalsDayShowOrNot"
                    ><v-spacer></v-spacer><strong> Objectifs</strong
                    ><v-spacer></v-spacer
                    ><v-icon right>mdi-chevron-down</v-icon></v-btn
                  >
                  <v-row v-show="goalsDayShowOrNot" class="mx-n13">
                    <v-card-text class="caption mb-4 mt-1"
                      >Choisissez vos objectifs à déterminer en déplaçant les
                      étiquettes par ordre de préférence. Prêt-à-Poster génèrera
                      un plan stratégique avec les formats les plus pertinents
                      pour répondre aux objectifs. L’ordre de préférence des
                      objectifs sera pris en compte dans la
                      suggestion.</v-card-text
                    >
                  </v-row>
                  <v-row
                    v-show="goalsDayShowOrNot"
                    class="hidden-xs-only mt-n2"
                  >
                    <v-col cols="10"
                      ><draggable
                        v-model="goalsItems"
                        group="goals"
                        class="hidden-xs-only"
                        @start="drag = true"
                        @end="drag = false"
                      >
                        <v-list-item
                          v-for="item in goalsItems"
                          :key="item.id"
                          class="ml-n4"
                          ><v-btn
                            width="230"
                            class="hidden-xs-only drag-el text-none"
                            :disabled="loading"
                            draggable
                            outlined
                          >
                            <v-icon left color="primary" class="ml-n2 mr-5">
                              mdi-dots-grid
                            </v-icon>
                            <strong>{{ item.title }}</strong>
                            <v-spacer></v-spacer>
                          </v-btn>
                          <v-spacer></v-spacer>
                        </v-list-item> </draggable
                    ></v-col>
                    <v-col cols="2">
                      <v-list-item
                        v-for="(item, i) in goalsItems"
                        :key="item.id"
                        class="ml-n4"
                      >
                        <v-avatar
                          color="primary"
                          size="23"
                          class="ml-2 mt-n3 lighten-1"
                        >
                          <span class="white--text"> {{ i + 1 }}</span>
                        </v-avatar>
                      </v-list-item></v-col
                    >
                  </v-row>
                </v-row>
                <v-row
                  v-show="goalsDayShowOrNot"
                  class="hidden-sm-and-up mt-n2 mb-n16"
                >
                  <v-col cols="10"
                    ><draggable
                      v-show="goalsDayShowOrNot"
                      v-model="goalsItems"
                      group="goals"
                      class="mb-n14"
                      @start="drag = true"
                      @end="drag = false"
                    >
                      <v-btn
                        v-for="item in goalsItems"
                        :key="item.id"
                        block
                        class="hidden-sm-and-up drag-el text-none"
                        :disabled="loading"
                        draggable
                        outlined
                      >
                        <v-icon left color="primary" class="">
                          mdi-dots-grid
                        </v-icon>
                        {{ item.title }}
                        <v-spacer></v-spacer>
                      </v-btn> </draggable
                  ></v-col>
                  <v-col cols="2">
                    <v-list-item
                      v-for="(item, i) in goalsItems"
                      :key="item.id"
                      class="ml-n4"
                    >
                      <v-avatar
                        color="primary"
                        size="23"
                        class="mt-n4 lighten-1"
                      >
                        <span class="white--text"> {{ i + 1 }}</span>
                      </v-avatar>
                    </v-list-item></v-col
                  >
                </v-row>
              </v-form></v-col
            >
            <v-col></v-col>
          </v-row>
        </v-window-item>

        <v-window-item :value="2">
          <v-row class="mb-16">
            <v-col>
              <v-row justify="center">
                <v-btn
                  filled
                  class="mt-3 text-none"
                  elevation="0"
                  @click="daysOfWeekShowOrNot = !daysOfWeekShowOrNot"
                  ><strong>Quand veux-tu poster ?</strong
                  ><v-icon right>mdi-chevron-down</v-icon></v-btn
                >
              </v-row>
              <v-row
                v-show="daysOfWeekShowOrNot"
                class="mt-n2 ml-n6"
                justify="center"
              >
                <v-col></v-col>
                <v-col cols="8">
                  <v-card-text class="text-caption">
                    Il n’y a pas de bons ou de mauvais jours sur les réseaux
                    sociaux. Choisissez les jours que vous souhaitez en fonction
                    de vos disponibilités.</v-card-text
                  ></v-col
                >
                <v-col></v-col>

                <v-btn-toggle
                  v-model="daysOfWeekSelected"
                  multiple
                  dense
                  class="hidden-xs-only mt-n3 mb-1"
                  ><v-btn
                    v-for="dayOfWeek in daysOfWeek"
                    :key="dayOfWeek"
                    :disabled="loading"
                    active-class="primary lighten-1 white--text text-none"
                    class="px-3 text-none"
                    ><strong>{{ dayOfWeek }}</strong></v-btn
                  >
                </v-btn-toggle>
                <v-btn-toggle
                  v-model="daysOfWeekSelected"
                  multiple
                  dense
                  class="hidden-sm-and-up"
                >
                  <v-btn
                    x-small
                    v-for="dayOfWeek in daysOfWeek"
                    :key="dayOfWeek"
                    :disabled="loading"
                    active-class="primary lighten-1 white--text text-none"
                    class="px-1 text-none"
                    ><strong>{{ dayOfWeek }}</strong></v-btn
                  ></v-btn-toggle
                >
              </v-row>
              <v-row justify="center">
                <v-btn
                  filled
                  class="mt-8 mb-12 text-none"
                  elevation="0"
                  :disabled="daysOfWeekSelected.length === 0"
                  @click="formatDayShowOrNot = !formatDayShowOrNot"
                  ><strong>Quel format pour chaque jour ?</strong
                  ><v-icon right>mdi-chevron-down</v-icon></v-btn
                ></v-row
              ><v-row
                v-show="formatDayShowOrNot"
                class="mt-n10"
                justify="center"
              >
                <v-list
                  v-for="dayOfPost in daysOfWeekSelectedForFormat"
                  :key="dayOfPost"
                  :value="dayOfPost"
                  class="mx-n5"
                >
                  <v-list-item class="ml-2">
                    <v-btn outlined dense :disabled="loading" class="text-none"
                      ><strong>{{ dayOfPost }}</strong></v-btn
                    >
                  </v-list-item>
                  <draggable
                    v-if="dayOfPost === 'Lun'"
                    v-model="formatItems.Lun"
                    group="Lun"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Lun"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-n1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                  <draggable
                    v-else-if="dayOfPost === 'Mar'"
                    v-model="formatItems.Mar"
                    group="Mar"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Mar"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                  <draggable
                    v-else-if="dayOfPost === 'Mer'"
                    v-model="formatItems.Mer"
                    group="Mer"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Mer"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                  <draggable
                    v-else-if="dayOfPost === 'Jeu'"
                    v-model="formatItems.Jeu"
                    group="Jeu"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Jeu"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                  <draggable
                    v-else-if="dayOfPost === 'Ven'"
                    v-model="formatItems.Ven"
                    group="Ven"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Ven"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                  <draggable
                    v-else-if="dayOfPost === 'Sam'"
                    v-model="formatItems.Sam"
                    group="Sam"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Sam"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                  <draggable
                    v-else-if="dayOfPost === 'Dim'"
                    v-model="formatItems.Dim"
                    group="Dim"
                    @start="drag = true"
                    @end="drag = false"
                  >
                    <v-list-item
                      v-for="item in formatItems.Dim"
                      :key="item.id"
                      class="mb-n7"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          ><v-btn
                            class="drag-el mx-2 px-1 text-none"
                            width="70"
                            draggable
                            x-small
                            outlined
                            :disabled="loading"
                          >
                            {{ item.title }}
                          </v-btn></v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </draggable>
                </v-list>
              </v-row>

              <v-row v-show="formatDayShowOrNot" class="mb-n16">
                <v-col></v-col>
                <v-col cols="8">
                  <v-card-text class="text-caption"
                    >Choisissez un ordre de préférence de formats prêts à
                    poster. Prêt-à-Poster génèrera un plan stratégique avec les
                    formats les plus pertinents pour répondre aux objectifs.
                    L’ordre de préférence des formats sera pris en compte dans
                    la suggestion.</v-card-text
                  ></v-col
                >
                <v-col></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="step === 1"
          class="text-none px-8 px-sm-16 px-md-16 px-lg-16 px-xl-16"
          color="primary"
          elevation="0"
          :disabled="!valid || loading"
          @click="step++"
          >Continuer ma stratégie</v-btn
        ><v-btn
          v-else
          class="text-none px-10"
          color="primary"
          elevation="0"
          :disabled="daysOfWeekSelected.length === 0 || loading"
          @click="
            GenerateStrategy(
              emailInstagramForGenerateStrat,
              goalsItems,
              daysOfWeekSelected,
              daysOfWeekSelectedForFormat,
              formatItems
            )
          "
          >Générer mon plan stratégique</v-btn
        ><v-spacer></v-spacer>
      </v-card-actions>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          plain
          class="text-none mb-8"
          :disabled="loading"
          @click="
            dialog = !dialog;
            step = 1;
            formatDayShowOrNot = null;
            goalsDayShowOrNot = null;
          "
          >Annuler</v-btn
        ><v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import draggable from "vuedraggable";
import dayjs from "dayjs";
const fb = require("@/firebase/init.js");
export default {
  name: "GenerateStrategy",
  components: {
    draggable,
  },
  props: {
    classForGenerate: {
      type: String,
      default: "text-none white--text mt-8",
    },
  },
  data: () => ({
    emailInstagramForGenerateStrat: null,
    daysOfWeek: ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"],
    daysOfWeekSelected: [],
    daysOfWeekShowOrNot: false,
    dialog: false,
    drag: false,
    formatDayShowOrNot: false,
    formatItems: {
      Lun: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
      Mar: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
      Mer: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
      Jeu: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
      Ven: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
      Sam: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
      Dim: [
        {
          id: 0,
          title: "Single post",
          list: 1,
        },
        {
          id: 1,
          title: "Carrousel",
          list: 1,
        },
        {
          id: 2,
          title: "Reels",
          list: 1,
        },
      ],
    },
    goalsItems: [
      {
        id: 0,
        title: "Expertise",
      },
      {
        id: 1,
        title: "Visibilité",
      },
      {
        id: 2,
        title: "Vente",
      },
      {
        id: 3,
        title: "Audience",
      },
    ],
    goalGeneratePostItems: ["Expertise", "Visibilité", "Vente", "Audience"],
    goalsDayShowOrNot: null,
    loading: false,
    step: 1,
    valid: false,
    accountRules: [
      (v) =>
        !!v ||
        "Vous devez choisir un compte Instagram pour générer la stratégie",
    ],
    goalRules: [
      (v) => !!v || "Vous devez choisir vos objectifs par ordre de préférence",
    ],
  }),
  computed: {
    ...mapState(["user"]),
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Générer ma stratégie";
        case 2:
          return "Générer ma stratégie";
        default:
          return "Générer ma stratégie";
      }
    },
    daysOfWeekSelectedForFormat() {
      let daysOfWeekSelectedTemp = this.daysOfWeekSelected.sort();
      let daysOfWeekSelectedTempBis = null;
      let daysOfWeekSelectedFormat = [];
      for (let index = 0; index < this.daysOfWeekSelected.length; index++) {
        daysOfWeekSelectedTempBis = daysOfWeekSelectedTemp[index];
        daysOfWeekSelectedTempBis = this.daysOfWeek[daysOfWeekSelectedTempBis];
        daysOfWeekSelectedFormat.push(daysOfWeekSelectedTempBis);
      }
      return daysOfWeekSelectedFormat;
    },
  },
  watch: {
    user: function (val) {
      this.emailInstagramForGenerateStrat = this.user.emailInstagram[0];
    },
  },
  methods: {
    deleteAllPost() {
      //this.loading = true;
      let docRef = fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards");
      docRef.get().then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          doc.ref.delete();
        });
      });
      // setTimeout(() => (this.dialog = false), 6000);
      //setTimeout(() => ((this.loading = false), this.$emit("newPost")), 6001);
    },
    GenerateStrategy(email, goals, days, daysBis, format) {
      this.loading = true;
      let countNbPost = 0;
      for (let index = 0; index < daysBis.length; index++) {
        countNbPost += 13;
      }
      var expertise = 0;
      var engagement = 0;
      var socialProof = 0;
      var promotion = 0;
      var personalBranding = 0;
      for (let index = 0; index < goals.length; index++) {
        if (index === 0) {
          if (countNbPost === 65 || countNbPost === 91) {
            expertise += 1;
          }
          let nbPost = Math.round(countNbPost * 0.375);
          if (
            goals[index].title === "Expertise" ||
            goals[index].title === "Visibilité"
          ) {
            if (nbPost % 2 === 0) {
              expertise += nbPost / 2;
              engagement += nbPost / 2;
            } else {
              let nbPostTemp = nbPost % 2;
              expertise += (nbPost - nbPostTemp) / 2;
              engagement += (nbPost - nbPostTemp) / 2;
              if (nbPostTemp > 1) {
                expertise += nbPostTemp / 2;
                engagement += nbPostTemp / 2;
              } else {
                expertise += nbPostTemp;
              }
            }
          } else if (goals[index].title === "Vente") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              socialProof += Math.round(nbPost / 3);
              promotion += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              socialProof += Math.round(nbPostTemp / 3);
              promotion += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                promotion += (nbPost % 3) / 2;
                socialProof += (nbPost % 3) / 2;
              } else {
                socialProof += 1;
              }
            }
          } else if (goals[index].title === "Audience") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              engagement += Math.round(nbPost / 3);
              personalBranding += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              engagement += Math.round(nbPostTemp / 3);
              personalBranding += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                personalBranding += (nbPost % 3) / 2;
                engagement += (nbPost % 3) / 2;
              } else {
                personalBranding += 1;
              }
            }
          }
        } else if (index === 1) {
          let nbPost = Math.round(countNbPost * 0.3125);
          if (
            goals[index].title === "Expertise" ||
            goals[index].title === "Visibilité"
          ) {
            if (nbPost % 2 === 0) {
              expertise += nbPost / 2;
              engagement += nbPost / 2;
            } else {
              let nbPostTemp = nbPost % 2;
              expertise += (nbPost - nbPostTemp) / 2;
              engagement += (nbPost - nbPostTemp) / 2;
              if (nbPostTemp > 1) {
                expertise += nbPostTemp / 2;
                engagement += nbPostTemp / 2;
              } else {
                expertise += nbPostTemp;
              }
            }
          } else if (goals[index].title === "Vente") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              socialProof += Math.round(nbPost / 3);
              promotion += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              socialProof += Math.round(nbPostTemp / 3);
              promotion += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                promotion += (nbPost % 3) / 2;
                socialProof += (nbPost % 3) / 2;
              } else {
                socialProof += 1;
              }
            }
          } else if (goals[index].title === "Audience") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              engagement += Math.round(nbPost / 3);
              personalBranding += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              engagement += Math.round(nbPostTemp / 3);
              personalBranding += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                personalBranding += (nbPost % 3) / 2;
                engagement += (nbPost % 3) / 2;
              } else {
                personalBranding += 1;
              }
            }
          }
        } else if (index === 2) {
          let nbPost = Math.round(countNbPost * 0.1875);
          if (
            goals[index].title === "Expertise" ||
            goals[index].title === "Visibilité"
          ) {
            if (nbPost % 2 === 0) {
              expertise += nbPost / 2;
              engagement += nbPost / 2;
            } else {
              let nbPostTemp = nbPost % 2;
              expertise += (nbPost - nbPostTemp) / 2;
              engagement += (nbPost - nbPostTemp) / 2;
              if (nbPostTemp > 1) {
                expertise += nbPostTemp / 2;
                engagement += nbPostTemp / 2;
              } else {
                expertise += nbPostTemp;
              }
            }
          } else if (goals[index].title === "Vente") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              socialProof += Math.round(nbPost / 3);
              promotion += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              socialProof += Math.round(nbPostTemp / 3);
              promotion += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                promotion += (nbPost % 3) / 2;
                socialProof += (nbPost % 3) / 2;
              } else {
                socialProof += 1;
              }
            }
          } else if (goals[index].title === "Audience") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              engagement += Math.round(nbPost / 3);
              personalBranding += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              engagement += Math.round(nbPostTemp / 3);
              personalBranding += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                personalBranding += (nbPost % 3) / 2;
                engagement += (nbPost % 3) / 2;
              } else {
                personalBranding += 1;
              }
            }
          }
        } else if (index === 3) {
          let nbPost = Math.round(countNbPost * 0.125);
          let nbPostForCondition = countNbPost * 0.125;
          if (nbPost - nbPostForCondition === 0.5) {
            nbPost = nbPost - 1;
          }
          if (
            goals[index].title === "Expertise" ||
            goals[index].title === "Visibilité"
          ) {
            if (nbPost % 2 === 0) {
              expertise += nbPost / 2;
              engagement += nbPost / 2;
            } else {
              let nbPostTemp = nbPost % 2;
              expertise += (nbPost - nbPostTemp) / 2;
              engagement += (nbPost - nbPostTemp) / 2;
              if (nbPostTemp > 1) {
                expertise += nbPostTemp / 2;
                engagement += nbPostTemp / 2;
              } else {
                expertise += nbPostTemp;
              }
            }
          } else if (goals[index].title === "Vente") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              socialProof += Math.round(nbPost / 3);
              promotion += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              socialProof += Math.round(nbPostTemp / 3);
              promotion += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                promotion += (nbPost % 3) / 2;
                socialProof += (nbPost % 3) / 2;
              } else {
                socialProof += 1;
              }
            }
          } else if (goals[index].title === "Audience") {
            if (nbPost % 3 === 0) {
              expertise += Math.round(nbPost / 3);
              engagement += Math.round(nbPost / 3);
              personalBranding += Math.round(nbPost / 3);
            } else {
              let nbPostTemp = nbPost - (nbPost % 3);
              expertise += Math.round(nbPostTemp / 3);
              engagement += Math.round(nbPostTemp / 3);
              personalBranding += Math.round(nbPostTemp / 3);
              if (nbPost % 3 > 1) {
                personalBranding += (nbPost % 3) / 2;
                engagement += (nbPost % 3) / 2;
              } else {
                personalBranding += 1;
              }
            }
          }
        } else {
        }
      }

      let expertiseReel = 0;
      let expertiseCarroussel = 0;
      let expertiseSinglePost = 0;

      let engagementReel = 0;
      let engagementCarroussel = 0;
      let engagementSinglePost = 0;

      let socialProofReel = 0;
      let socialProofCarroussel = 0;
      let socialProofSinglePost = 0;

      let promotionReel = 0;
      let promotionCarroussel = 0;
      let promotionSinglePost = 0;

      let personalBrandingReel = 0;
      let personalBrandingCarroussel = 0;
      let personalBrandingSinglePost = 0;
      if (expertise < 3) {
        if (expertise > 1) {
          expertiseReel = 1;
          expertiseCarroussel = 1;
        } else {
          expertiseReel = 1;
        }
      } else {
        let expertiseTemp = expertise;
        if (
          Math.round(expertiseTemp * 0.44) +
            Math.round(expertiseTemp * 0.33) +
            Math.round(expertiseTemp * 0.22) <
          expertiseTemp
        ) {
          expertiseReel += 1;
        }
        expertiseReel += Math.round(expertiseTemp * 0.44);
        expertiseCarroussel += Math.round(expertiseTemp * 0.33);
        expertiseSinglePost += Math.round(expertiseTemp * 0.22);
      }
      if (engagement < 3) {
        if (engagement <= 1) {
          if (engagement === 0) {
            // pas de Engagement on ne fait rien.
          } else {
            engagementSinglePost = 1;
          }
        } else {
          engagementSinglePost = 1;
          engagementReel = 1;
        }
      } else {
        let engagementTemp = engagement;

        engagementReel += Math.round(engagementTemp * 0.33);
        engagementCarroussel += Math.round(engagementTemp * 0.22);
        engagementSinglePost += Math.round(engagementTemp * 0.44);
        if (
          Math.round(engagementTemp * 0.44) +
            Math.round(engagementTemp * 0.33) +
            Math.round(engagementTemp * 0.22) >
          engagementTemp
        ) {
          engagementSinglePost -= 1;
        } else if (
          Math.round(engagementTemp * 0.44) +
            Math.round(engagementTemp * 0.33) +
            Math.round(engagementTemp * 0.22) <
          engagementTemp
        ) {
          engagementSinglePost += 1;
        }
      }
      if (socialProof < 3) {
        if (socialProof <= 1) {
          if (socialProof === 0) {
            // pas de Social Proof on ne fait rien.
          } else {
            socialProofSinglePost = 1;
          }
        } else {
          socialProofSinglePost = 1;
          socialProofCarroussel = 1;
        }
      } else {
        let socialProofTemp = socialProof;

        socialProofReel += Math.round(socialProofTemp * 0.22);
        socialProofCarroussel += Math.round(socialProofTemp * 0.33);
        socialProofSinglePost += Math.round(socialProofTemp * 0.44);
        if (
          Math.round(socialProofTemp * 0.44) +
            Math.round(socialProofTemp * 0.33) +
            Math.round(socialProofTemp * 0.22) >
          socialProofTemp
        ) {
          socialProofSinglePost -= 1;
        }
      }
      if (promotion < 3) {
        if (promotion <= 1) {
          if (promotion === 0) {
            // pas de promotion on ne fait rien.
          } else {
            promotionCarroussel = 1;
          }
        } else {
          promotionCarroussel = 1;
          promotionSinglePost = 1;
        }
      } else {
        let promotionTemp = promotion;

        promotionReel += Math.round(promotionTemp * 0.22);
        promotionCarroussel += Math.round(promotionTemp * 0.44);
        promotionSinglePost += Math.round(promotionTemp * 0.33);
        if (
          Math.round(promotionTemp * 0.44) +
            Math.round(promotionTemp * 0.33) +
            Math.round(promotionTemp * 0.22) >
          promotionTemp
        ) {
          promotionCarroussel -= 1;
        }
      }
      if (personalBranding < 3) {
        if (personalBranding <= 1) {
          if (personalBranding === 0) {
            // pas de personalBranding on ne fait rien.
          } else {
            personalBrandingSinglePost = 1;
          }
        } else {
          personalBrandingSinglePost = 1;
          personalBrandingReel = 1;
        }
      } else {
        let personalBrandingTemp = personalBranding;

        personalBrandingReel += Math.round(personalBrandingTemp * 0.33);
        personalBrandingCarroussel += Math.round(personalBrandingTemp * 0.22);
        personalBrandingSinglePost += Math.round(personalBrandingTemp * 0.44);
        if (
          Math.round(personalBrandingTemp * 0.44) +
            Math.round(personalBrandingTemp * 0.33) +
            Math.round(personalBrandingTemp * 0.22) >
          personalBrandingTemp
        ) {
          personalBrandingSinglePost -= 1;
        }
      }
      console.log(
        "Posts expertise : " + expertise,
        "Posts engagement : " + engagement,
        "Posts socialProof : " + socialProof,
        "Posts promotion : " + promotion,
        "Posts personalBranding : " + personalBranding
      );
      console.log("expertiseReel : " + expertiseReel);
      console.log("expertiseCarroussel : " + expertiseCarroussel);
      console.log("expertiseSinglePost : " + expertiseSinglePost);
      console.log("engagementReel : " + engagementReel);
      console.log("engagementCarroussel : " + engagementCarroussel);
      console.log("engagementSinglePost : " + engagementSinglePost);
      console.log("socialProofReel : " + socialProofReel);
      console.log("socialProofCarroussel : " + socialProofCarroussel);
      console.log("socialProofSinglePost : " + socialProofSinglePost);
      console.log("promotionReel : " + promotionReel);
      console.log("promotionCarroussel : " + promotionCarroussel);
      console.log("promotionSinglePost : " + promotionSinglePost);
      console.log("personalBrandingReel : " + personalBrandingReel);
      console.log("personalBrandingCarroussel : " + personalBrandingCarroussel);
      console.log("personalBrandingSinglePost : " + personalBrandingSinglePost);
      let priorityUserPerDay = [];
      let postPerFormatAndCategorie = [];
      for (let index = 0; index < daysBis.length; index++) {
        let day = daysBis[index];
        let priorityOne = format[day][0]["title"];
        let priorityTwo = format[day][1]["title"];
        let priorityThree = format[day][2]["title"];
        priorityUserPerDay.push({
          day: day,
          priorityOne,
          priorityTwo,
          priorityThree,
        });
        // console.log(priorityUserPerDay);
      }
      //////////////// Expertise - Reel /////////////////////////////
      for (let index = 0; index < expertiseReel; index++) {
        let card = [
          {
            categorie: "Expertise",
            format: "Reel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Expertise - Carroussel /////////////////////////////
      for (let index = 0; index < expertiseCarroussel; index++) {
        let card = [
          {
            categorie: "Expertise",
            format: "Carrousel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Expertise - Single Post /////////////////////////////
      for (let index = 0; index < expertiseSinglePost; index++) {
        let card = [
          {
            categorie: "Expertise",
            format: "Single Post",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      /////////////////////////////////////////////////////////////////
      //////////////// Engagement - Reel /////////////////////////////
      for (let index = 0; index < engagementReel; index++) {
        let card = [
          {
            categorie: "Engagement",
            format: "Reel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Engagement - Carroussel /////////////////////////////
      for (let index = 0; index < engagementCarroussel; index++) {
        let card = [
          {
            categorie: "Engagement",
            format: "Carrousel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Engagement - Single Post /////////////////////////////
      for (let index = 0; index < engagementSinglePost; index++) {
        let card = [
          {
            categorie: "Engagement",
            format: "Single Post",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      ////////////////////////////////////////////////////////////////////////
      //////////////// Social Proof - Reel /////////////////////////////
      for (let index = 0; index < socialProofReel; index++) {
        let card = [
          {
            categorie: "Social Proof",
            format: "Reel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Social Proof - Carroussel /////////////////////////////
      for (let index = 0; index < socialProofCarroussel; index++) {
        let card = [
          {
            categorie: "Social Proof",
            format: "Carrousel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Social Proof - Single Post /////////////////////////////
      for (let index = 0; index < socialProofSinglePost; index++) {
        let card = [
          {
            categorie: "Social Proof",
            format: "Single Post",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////////////////////////////////////////////////////////////
      //////////////// Promotion - Reel /////////////////////////////
      for (let index = 0; index < promotionReel; index++) {
        let card = [
          {
            categorie: "Promotion",
            format: "Reel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Promotion - Carroussel /////////////////////////////
      for (let index = 0; index < promotionCarroussel; index++) {
        let card = [
          {
            categorie: "Promotion",
            format: "Carrousel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Promotion - Single Post /////////////////////////////
      for (let index = 0; index < promotionSinglePost; index++) {
        let card = [
          {
            categorie: "Promotion",
            format: "Single Post",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////////////////////////////////////////////////////////////
      //////////////// Personal Branding - Reel /////////////////////////////
      for (let index = 0; index < personalBrandingReel; index++) {
        let card = [
          {
            categorie: "Personal branding",
            format: "Reel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Personal Branding - Carroussel /////////////////////////////
      for (let index = 0; index < personalBrandingCarroussel; index++) {
        let card = [
          {
            categorie: "Personal branding",
            format: "Carrousel",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      //////////////// Personal Branding - Single Post /////////////////////////////
      for (let index = 0; index < personalBrandingSinglePost; index++) {
        let card = [
          {
            categorie: "Personal branding",
            format: "Single Post",
          },
        ];
        postPerFormatAndCategorie.push(card);
      }
      const middleIndex = Math.ceil(postPerFormatAndCategorie.length / 2);
      let firstArray = postPerFormatAndCategorie.splice(0, middleIndex);
      let secondArray = postPerFormatAndCategorie.splice(-middleIndex);
      let secondArrayTemp = secondArray.map((x) => x);
      let thirdArray = [];
      if (firstArray.length + secondArray.length === 13) {
        thirdArray.push(
          firstArray[0],
          secondArray[5],
          firstArray[1],
          secondArray[4],
          firstArray[2],
          secondArray[3],
          firstArray[3],
          secondArray[2],
          firstArray[4],
          secondArray[1],
          firstArray[5],
          secondArray[0],
          firstArray[6]
        );
      } else {
        for (let index = 0; index < firstArray.length; index++) {
          if (secondArray.length > index) {
            if (firstArray[index][0].format === secondArray[index][0].format) {
              let indexTemp = index;
              for (let index = 0; index < secondArrayTemp.length; index++) {
                if (
                  firstArray[indexTemp][0].format !=
                    secondArrayTemp[index][0].format &&
                  firstArray[indexTemp][0].categorie !=
                    secondArrayTemp[index][0].categorie
                ) {
                  thirdArray.push(firstArray[indexTemp]);
                  thirdArray.push(secondArrayTemp[index]);
                  secondArrayTemp.splice(index, 1);

                  index = secondArrayTemp.length;
                } else {
                }
              }
            } else {
              if (
                firstArray[index][0].categorie ===
                secondArray[index][0].categorie
              ) {
              } else {
                thirdArray.push(firstArray[index]);
                thirdArray.push(secondArray[index]);
                secondArrayTemp.splice(index, 1);
              }
            }
          } else {
            thirdArray.push(firstArray[index]);
          }
        }
      }

      let arrayWithAllDay = [];
      let today = new Date();
      for (let index = 0; index < 13; index++) {
        let indexForDayForFirst = index + 1;
        for (let index = 0; index < daysBis.length; index++) {
          let dayForFirst = null;
          if (daysBis[index] === "Lun") {
            dayForFirst = 1;
          } else if (daysBis[index] === "Mar") {
            dayForFirst = 2;
          } else if (daysBis[index] === "Mer") {
            dayForFirst = 3;
          } else if (daysBis[index] === "Jeu") {
            dayForFirst = 4;
          } else if (daysBis[index] === "Ven") {
            dayForFirst = 5;
          } else if (daysBis[index] === "Sam") {
            dayForFirst = 6;
          } else if (daysBis[index] === "Dim") {
            dayForFirst = 0;
          }
          let first = today.getDate() - today.getDay() + dayForFirst;
          let dayTemp = new Date(today.setDate(first));
          let firstDayNextWeek = indexForDayForFirst * 7;
          dayTemp.setDate(dayTemp.getDate() + firstDayNextWeek);

          if (daysBis[index] === "Dim") {
            dayTemp.setDate(dayTemp.getDate() + 7);
          }
          let listDay = dayjs(dayTemp).format("YYYY-MM-DD");
          arrayWithAllDay.push(listDay);
        }
      }
      let arrayWithAllDayMix = [];
      let daysCount = arrayWithAllDay.length / 13;
      let daysCountTemp = arrayWithAllDay.length / 13;
      let pairImpair = Math.round(arrayWithAllDay.length / 2) + 1;
      if (arrayWithAllDay.length % 2 === 0) {
        pairImpair = pairImpair + 1;
      }
      for (let indexP = 0; indexP < pairImpair; indexP++) {
        if (daysCount === indexP) {
          daysCount = daysCountTemp + daysCount;
          for (let index = 0; index < daysCountTemp; index++) {
            let indexToCount = indexP - daysCountTemp + index;
            arrayWithAllDayMix.push(arrayWithAllDay[indexToCount]);
          }
          for (let index = 0; index < daysCountTemp; index++) {
            let indexToCountReverse = arrayWithAllDay.length - indexP + index;
            arrayWithAllDayMix.push(arrayWithAllDay[indexToCountReverse]);
          }
        }
        if (
          indexP == Math.round(arrayWithAllDay.length / 2) &&
          Math.round(arrayWithAllDay.length / 2) > 7
        ) {
          for (let index = 0; index < daysCountTemp; index++) {
            let indexToCount = daysCountTemp * 6 + index;
            arrayWithAllDayMix.push(arrayWithAllDay[indexToCount]);
          }
        }
      }
      if (arrayWithAllDay.length === 13) {
        arrayWithAllDayMix = [];
        arrayWithAllDayMix = arrayWithAllDay;
      }
      let finalArray = [];
      for (let index = 0; index < thirdArray.length; index++) {
        let card = [
          {
            caption: "Template automatique",
            categorie: thirdArray[index][0].categorie,
            createdOn: new Date(),
            day: arrayWithAllDayMix[index],
            emailInstagram: email,
            format: thirdArray[index][0].format,
            imgPost:
              "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9",
            imgPostRaw:
              "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9",
            list: arrayWithAllDayMix[index],
          },
        ];
        finalArray.push(card);
      }
      for (let index = 0; index < finalArray.length; index++) {
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .collection("cards")
          .add({
            caption: finalArray[index][0].caption,
            emailInstagram: finalArray[index][0].emailInstagram,
            format: finalArray[index][0].format,
            categorie: finalArray[index][0].categorie,
            day: finalArray[index][0].day,
            imgPost: finalArray[index][0].imgPost,
            imgPostRaw: finalArray[index][0].imgPostRaw,
            list: finalArray[index][0].list,
            createdOn: finalArray[index][0].createdOn,
          });
      }
      setTimeout(
        () => (
          (this.dialog = false), (this.loading = false), this.$emit("newPost")
        ),
        2500
      );
    },
  },
};
</script>

<style>
.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
.drag-el:hover {
  cursor: grab;
}
.drag-el:active {
  cursor: grabbing;
}
</style>
