<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="upHere && button && cardItemsFilter.length < 1"
        icon
        justify="center"
        class="mt-16"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        v-show="cardItemsFilter.length < 1"
        icon
        x-small
        justify="center"
        class="hidden-lg-and-up mt-n8"
        v-bind="attrs"
        v-on="on"
      >
      </v-btn>
      <v-btn
        v-show="!button && cardItemsFilter.length >= 1"
        small
        text
        color="primary"
        class="text-none mb-2"
        v-bind="attrs"
        v-on="on"
        @click="dialogAddPost = true"
        >Ajouter un post</v-btn
      >
      <v-btn
        v-show="!button && cardItemsFilter.length === 0"
        small
        text
        color="primary"
        class="text-none mb-2"
        v-bind="attrs"
        v-on="on"
        @click="dialogAddPost = true"
        >Créer mon premier post</v-btn
      >
    </template>
    <v-form ref="form" v-model="valid">
      <v-card min-height="740" max-width="600">
        <v-img
          v-if="imgValidate.length < 2"
          v-model="imgValidate"
          class="white--text align-end"
          height="300px"
          :src="imgValidate[0]"
        >
          <div
            class="d-flex darken-2 v-card--reveal text-h2 white--text"
            style="height: 170px"
          >
            <v-spacer></v-spacer
            ><v-dialog v-model="dialogAddPicture" max-width="500" persistent>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-2"
                  outlined
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="(imgSelected = []), getImgBank()"
                  >Choisir une autre image
                </v-btn>
              </template>

              <v-card class="mx-auto">
                <v-container class="pa-1">
                  <v-item-group v-model="imgSelected" multiple>
                    <v-row dense>
                      <v-col
                        v-for="(item, i) in imgBank"
                        :key="i"
                        cols="12"
                        md="6"
                      >
                        <v-item v-slot="{ active, toggle }" :value="item.url">
                          <v-img
                            :src="item.url"
                            height="150"
                            class="text-right pa-2"
                            @click="toggle"
                          >
                            <v-btn icon>
                              <v-icon color="green">
                                {{
                                  active
                                    ? "mdi-checkbox-marked-circle"
                                    : "mdi-checkbox-blank-circle-outline"
                                }}
                              </v-icon>
                            </v-btn>
                            <v-btn
                              v-if="item.imgImportByUser"
                              icon
                              @click="deleteUserImg(item.url)"
                            >
                              <v-icon color="red">mdi-delete</v-icon>
                            </v-btn>
                          </v-img>
                        </v-item>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-card
                          v-if="totalStorageSizeUsed < 950000000"
                          height="150"
                          elevation="0"
                          style="border: 1px solid #dfe0eb"
                        >
                          <v-card-text
                            >Importer une de vos images<v-file-input
                              v-model="photo"
                              accept="image/*"
                              label="Sélectionner une image"
                              prepend-icon="mdi-file-image-plus-outline"
                              class="mt-2 ml-4"
                              hide-details
                              outlined
                              dense
                            ></v-file-input
                            ><v-btn
                              outlined
                              small
                              @click="saveImgUser()"
                              color="primary"
                              dense
                              :disabled="photo === null"
                              class="mt-4 text-none"
                              >Enregistrer</v-btn
                            ></v-card-text
                          >
                          <v-card-actions> </v-card-actions>
                        </v-card>
                        <v-card v-else color="red" height="150">
                          <v-card-title class="justify-center text-h5"
                            >Espace Saturé</v-card-title
                          >
                          <v-card-text
                            >Pour ajouter de nouvelles images, veuillez en
                            supprimer</v-card-text
                          >
                          <v-card-actions> </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-item-group>
                  <v-card-actions
                    ><v-spacer></v-spacer
                    ><v-btn
                      plain
                      small
                      class="mt-2 text-none px-6"
                      @click="dialogAddPicture = false"
                      >Annuler</v-btn
                    ><v-btn
                      small
                      color="primary"
                      class="mt-2 text-none px-6"
                      elevation="0"
                      :disabled="photo !== null"
                      @click="validatePictures(), (dialogAddPicture = false)"
                      >Valider</v-btn
                    ></v-card-actions
                  >
                </v-container>
              </v-card> </v-dialog
            ><v-spacer></v-spacer>
          </div>
        </v-img>
        <v-carousel v-else height="350px">
          <v-carousel-item
            v-for="(item, i) in imgValidate"
            :key="i"
            :src="item"
            reverse-transition="fade-transition"
            transition="fade-transition"
            class="white--text align-end"
          >
            <v-img class="mt-10"></v-img>
            <div class="mt-13"></div>
            <v-dialog
              v-model="dialogAddPictureCarrousel"
              max-width="500"
              persistent
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-16"
                  outlined
                  color="white"
                  v-bind="attrs"
                  v-on="on"
                  @click="(imgSelected = []), getImgBank()"
                  >Choisir une autre image
                </v-btn>
              </template>

              <v-card class="mx-auto">
                <v-container class="pa-1">
                  <v-item-group v-model="imgSelected" multiple>
                    <v-row dense>
                      <v-col
                        v-for="(item, i) in imgBank"
                        :key="i"
                        cols="12"
                        md="6"
                      >
                        <v-item v-slot="{ active, toggle }" :value="item.url">
                          <v-img
                            :src="item.url"
                            height="150"
                            class="text-right pa-2"
                            @click="toggle"
                          >
                            <v-btn icon>
                              <v-icon color="green">
                                {{
                                  active
                                    ? "mdi-checkbox-marked-circle"
                                    : "mdi-checkbox-blank-circle-outline"
                                }}
                              </v-icon>
                            </v-btn>
                          </v-img>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-item-group>
                  <v-card-actions
                    ><v-spacer></v-spacer
                    ><v-btn
                      plain
                      small
                      class="mt-2 text-none px-6"
                      @click="dialogAddPictureCarrousel = false"
                      >Annuler</v-btn
                    ><v-btn
                      small
                      color="primary"
                      class="mt-2 text-none px-6"
                      elevation="0"
                      @click="
                        validatePictures(), (dialogAddPictureCarrousel = false)
                      "
                      >Valider</v-btn
                    ></v-card-actions
                  >
                </v-container>
              </v-card>
            </v-dialog></v-carousel-item
          >
        </v-carousel>
        <v-col class="mt-3 mb-n2 ml-3" align="start" cols="8"
          ><v-chip x-small color="cyan accent-1" label>{{
            formatSelected
          }}</v-chip
          ><v-chip x-small class="mx-1" color="deep-purple lighten-4" label>
            <span class="text-truncate">
              {{ categorieSelected }}
            </span></v-chip
          ><v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                color="black"
                class="text-none"
                text
                v-bind="attrs"
                v-on="on"
                ><strong> Modifier mes paramètres</strong></v-btn
              >
            </template>

            <v-card>
              <v-divider></v-divider>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-chip-group
                      v-model="formatSelected"
                      mandatory
                      active-class="primary--text"
                    >
                      <v-chip
                        v-for="format in formats"
                        :key="format"
                        :value="format"
                      >
                        {{ format }}
                      </v-chip>
                    </v-chip-group></v-list-item-title
                  >
                </v-list-item>

                <v-list-item>
                  <v-list-item-title
                    ><v-chip-group
                      v-model="categorieSelected"
                      mandatory
                      active-class="primary--text"
                    >
                      <v-chip
                        v-for="categorie in categories"
                        :key="categorie"
                        :value="categorie"
                      >
                        {{ categorie }}
                      </v-chip>
                    </v-chip-group></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-card> </v-menu
          ><v-select
            v-model="emailInstagramForPost"
            :items="user.emailInstagram"
            label="Compte"
            :rules="accountRules"
            required
          ></v-select>
        </v-col>
        <v-card-text>
          <v-textarea
            v-model="caption"
            outlined
            name="input-7-1"
            label="Commencez par écrire vos premières lignes ici."
            height="300px"
            auto-grow
            value=""
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="mt-n10">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="grey"
            class="text-none mr-3"
            text
            @click="dialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            small
            color="primary"
            class="text-none mr-3 px-6"
            elevation="0"
            :disabled="!valid"
            @click="savePost()"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
const fb = require("@/firebase/init.js");

export default {
  name: "AddPost",

  props: {
    button: {
      type: Boolean,
      default: false,
    },
    cardItemsFilter: {
      type: Array,
      default: false,
    },
    day: {
      type: Object,
      required: true,
    },
    upHere: {
      type: Boolean,
      default: false,
    },
    dialogBis: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    caption: "",
    categories: [
      "Expertise",
      "Engagement",
      "Personal branding",
      "Social Proof",
      "Promotion",
    ],
    categorieSelected: "Expertise",
    dialog: false,
    dialogAddPicture: false,
    dialogAddPictureCarrousel: false,
    emailInstagramForPost: null,
    formats: ["Single Post", "Carrousel", "Reel"],
    formatSelected: "Single Post",
    imgBank: [],
    imgSelected: [],
    imgValidate: [
      "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9",
    ],
    menu: false,
    photo: null,
    tagsImportUser: [],
    totalStorageSizeUsed: 0,
    valid: false,
    accountRules: [
      (v) => !!v || "Le compte Instagram est nécessaire, exemple : @johnDoe15",
    ],
  }),
  created() {
    this.emailInstagramForPost = this.user.emailInstagram[0];
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    dialogBis: function (val) {
      this.dialog = true;
    },
    imgValidate: function (val) {
      if (this.imgValidate.length > 1) {
        this.formatSelected = "Carrousel";
      }
    },
  },
  methods: {
    addPost(day, cards) {
      this.day = day;
      this.cardItemsFilter = cards;
      this.dialog = true;
    },
    deleteUserImg(url) {
      let self = this;
      let imgSize = 0;
      let searshNameOfImagebyUrl = fb.db
        .collection("imagesUsers")
        .where("url", "==", url);
      searshNameOfImagebyUrl
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            imgSize = doc.data().imgSize;
            // Create a reference to the file to delete
            var ImagesRef = fb.storageRef.child(
              "imagesUsers/" + doc.data().name
            );

            // Delete the file
            ImagesRef.delete()
              .then(() => {
                fb.db.collection("imagesUsers").doc(doc.id).delete();
                let urlTemp = [];
                fb.db
                  .collection("clients")
                  .doc(fb.firebase.auth().currentUser.uid)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      let cardUnit = doc.data().tagsImportUser;
                      for (let index = 0; index < cardUnit.length; index++) {
                        if (cardUnit[index] === url) {
                        } else {
                          urlTemp.push(cardUnit[index]);
                        }
                      }
                      self.tagsImportUser = [];
                      self.tagsImportUser = urlTemp;
                      self.totalStorageSizeUsed -= imgSize;
                      fb.db
                        .collection("clients")
                        .doc(fb.firebase.auth().currentUser.uid)
                        .update({
                          tagsImportUser: self.tagsImportUser,
                          totalStorageSizeUsed: self.totalStorageSizeUsed,
                        });
                      let imgBankTemp = [];
                      for (
                        let index = 0;
                        index < self.imgBank.length;
                        index++
                      ) {
                        if (self.imgBank[index].url === url) {
                        } else {
                          imgBankTemp.push(self.imgBank[index]);
                        }
                      }
                      self.imgBank = [];
                      self.imgBank = imgBankTemp;
                    }
                  });

                // File deleted successfully
              })
              .catch((error) => {
                // Uh-oh, an error occurred!
              });
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    getImgBank() {
      const self = this;
      fb.db
        .collection("images")
        .get()
        .then((snapshot) => {
          self.cardItems = [];
          snapshot.forEach((doc) => {
            let cardUnit = doc.data();
            cardUnit.id = doc.id;
            self.imgBank.push(cardUnit);
          });
        })
        .catch((error) => {
          console.log("Error in created(): ", error);
        })
        .then(() => {
          fb.db
            .collection("clients")
            .doc(fb.firebase.auth().currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let cardUnit = doc.data().tagsImportUser;
                self.tagsImportUser = cardUnit;
                self.totalStorageSizeUsed = doc.data().totalStorageSizeUsed;

                for (let index = 0; index < cardUnit.length; index++) {
                  let cardUnitObject = {
                    url: cardUnit[index],
                    urlRaw: cardUnit[index],
                    imgImportByUser: true,
                  };
                  self.imgBank.push(cardUnitObject);
                }
              }
            });
        });
    },

    saveImgUser() {
      var self = this;
      var imgSize = null;
      var storageRef = fb.storageRef;
      // Create the file metadata
      var metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("imagesUsers/" + this.photo.name)
        .put(this.photo, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        fb.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          imgSize = snapshot.totalBytes;
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case fb.firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case fb.firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          self.$store.commit("setSnackbar", {
            color: "error",
            timeout: 4000,
            text: "Un problème est survenu, veuillez recommencer",
          });
          this.loading = false;
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            self.tagsImportUser.push(downloadURL);
            self.totalStorageSizeUsed += imgSize;
            fb.db
              .collection("clients")
              .doc(fb.firebase.auth().currentUser.uid)
              .update({
                tagsImportUser: self.tagsImportUser,
                totalStorageSizeUsed: self.totalStorageSizeUsed,
              })
              .then(() => {
                let cardUnitObject = {
                  url: downloadURL,
                  urlRaw: downloadURL,
                  imgImportByUser: true,
                  imgSize: imgSize,
                };
                self.imgBank.push(cardUnitObject);
                fb.db
                  .collection("imagesUsers")
                  .add({
                    name: self.photo.name,
                    url: downloadURL,
                    imgSize: imgSize,
                  })
                  .then(() => {})
                  .catch((error) => {
                    console.error("Error updated document: ", error);
                  });
                self.photo = null;
              })
              .catch((error) => {
                self.$store.commit("setSnackbar", {
                  color: "error",
                  timeout: 4000,
                  text: error,
                });
              });
          });
        }
      );
    },
    savePost() {
      let img = null;
      if (this.imgValidate.length < 2) {
        img = this.imgValidate[0];
      } else {
        img = this.imgValidate;
        this.formatSelected = "Carrousel";
      }
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .add({
          caption: this.caption,
          emailInstagram: this.emailInstagramForPost,
          format: this.formatSelected,
          categorie: this.categorieSelected,
          day: this.day.date,
          imgPost: img,
          list: this.day.date,
          createdOn: new Date(),
        })
        .then(() => {})
        .catch((error) => {
          console.error("Error updated document: ", error);
        });
      this.$emit("newPost");
      this.dialog = false;
    },
    validatePictures() {
      if (this.imgSelected.length < 1) {
        this.imgSelected.push(
          "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9"
        );
      }
      this.imgValidate = this.imgSelected;
    },
  },
};
</script>
<style scoped>
.transparent {
  background-color: white !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparentImg {
  background-color: transparent !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparent-card {
  background-color: #fafafa;
  opacity: 0.9;
  border-color: transparent !important;
}
</style>
