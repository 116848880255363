<template>
  <v-container>
    <div class="home">
      <CalendarMonth ref="view" @newPost="newPost" />
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src
import CalendarMonth from "../components/CalendarMonth";

export default {
  name: "Home",
  components: {
    CalendarMonth,
  },
  methods: {
    newPost() {
      this.$refs.view.newPost();
      // this.$router.go("/");
    },
  },
};
</script>
<style>
#app {
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  font-family: sans-serif;
  --grey-100: #e4e9f0;
  --grey-200: #cfd7e3;
  --grey-300: #b5c0cd;
  --grey-800: #3e4e63;
  --grid-gap: 1px;
  --day-label-size: 20px;
}
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
