import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Inscription from "../views/Inscription.vue";
import Administration from "../views/Administration.vue";
import Templates from "../views/Templates.vue";
import Photos from "../views/Photos.vue";
import DashBoardAdmin from "../views/DashBoardAdmin.vue";
import BusiOffer from "../views/BusiOffer.vue";
import PastDueCustomer from "../views/PastDueCustomer.vue";
import WaitCreationStripeAccount from "../views/WaitCreationStripeAccount.vue";
import UpgradeAccount from "../views/UpgradeAccount.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/busiOffer",
    name: "BusiOffer",
    component: BusiOffer,
  },
  {
    path: "/pastduecustomer",
    name: "PastDueCustomer",
    component: PastDueCustomer,
  },
  {
    path: "/waitcreationstripeaccount",
    name: "WaitCreationStripeAccount",
    component: WaitCreationStripeAccount,
  },
  {
    path: "/upgradeaccount",
    name: "UpgradeAccount",
    component: UpgradeAccount,
    meta: { requiresAuth: true },
  },
  {
    path: "/inscription",
    name: "Inscription",
    component: Inscription,
  },
  {
    path: "/administration",
    name: "Administration",
    component: Administration,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/templates",
    name: "Templates",
    component: Templates,
    meta: { requiresAuth: true },
  },
  {
    path: "/photos",
    name: "Photos",
    component: Photos,
    meta: { requiresAuth: true },
  },

  {
    path: "/dashboard-admin",
    name: "DashBoardAdmin",
    component: DashBoardAdmin,
    meta: { requiresAuth: true },
  },
  {
    path: "/update",
    name: "Update",
    component: () =>
      import(/* webpackChunkName: "update" */ "../views/Update.vue"),
  },
  {
    path: "/paymentSuccess",
    name: "PaymentSuccess",
    component: () =>
      import(
        /* webpackChunkName: "PaymentSuccess" */ "../views/PaymentSuccess.vue"
      ),
  },
  {
    path: "/paymentError",
    name: "PaymentError",
    component: () =>
      import(
        /* webpackChunkName: "PaymentError" */ "../views/PaymentError.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
const fb = require("@/firebase/init.js");
router.beforeEach(async (to, from, next) => {
  if (await to.matched.some((record) => record.meta.requiresAuth)) {
    if ((await fb.firebase.getCurrentUser()) == false) {
      next("/login");
    } else {
      let self = this;
      const usersRef = fb.db
        .collection("customers")
        .doc(fb.firebase.auth().currentUser.uid);

      usersRef.get().then((docSnapshot) => {
        if (docSnapshot.exists) {
          usersRef.onSnapshot((doc) => {
            fb.firebase
              .firestore()
              .collection("customers")
              .doc(fb.firebase.auth().currentUser.uid)
              .collection("subscriptions")
              .get()
              .then((snapshot) => {
                if (snapshot.empty) {
                  next("/waitcreationstripeaccount");
                } else {
                  snapshot.forEach((doc) => {
                    if (doc.exists) {
                      if (
                        doc.data().status == "active" ||
                        doc.data().status == "trialing"
                      ) {
                        next();
                      } else if (doc.data().status == "past_due") {
                        next("/pastduecustomer");
                      } else {
                        next("/pastduecustomer");
                        fb.firebase
                          .auth()
                          .currentUser.delete()
                          .then(() => {
                            // User deleted.
                          })
                          .catch((error) => {
                            // An error ocurred
                            // ...
                          });

                        setTimeout(function () {
                          next("/inscription");
                        }, 2500);
                      }
                    } else {
                      next("/waitcreationstripeaccount");
                    }
                  });
                }
              });
          });
        } else {
          fb.db
            .collection("clients")
            .doc(fb.firebase.auth().currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let PaidOrNot = doc.data().paid;
                let offer = doc.data().offer;
                if (PaidOrNot === true) {
                  next();
                } else {
                  if (offer === "busi") {
                    next("/busiOffer");
                  } else {
                    let self = this;
                    fb.firebase
                      .auth()
                      .currentUser.delete()
                      .then(() => {
                        // User deleted.
                      })
                      .catch((error) => {
                        // An error ocurred
                        // ...
                      });

                    setTimeout(function () {
                      self.$router.push("/inscription");
                    }, 2500);
                  }
                }
              }
            });
        }
      });
    }
  } else {
    next();
  }
});
export default router;
