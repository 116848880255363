import Vue from "vue";
import Vuex from "vuex";
const fb = require("@/firebase/init.js");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {},
    snackbar: { active: false, color: "", mode: "", timeout: -1, text: "" },
  },
  mutations: {
    setUser(state, val) {
      state.user = val;
    },
    setSnackbar(state, val) {
      state.snackbar = Object.assign({}, val, { active: true });
    },
  },
  actions: {
    getAdminData({ commit, state }) {
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .onSnapshot((doc) => {
          commit(
            "setUser",
            Object.assign(
              {
                email: fb.firebase.auth().currentUser.email,
                id: fb.firebase.auth().currentUser.uid,
              },
              doc.data()
            )
          );
        });
    },
  },
  modules: {},
});
