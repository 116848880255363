<template>
  <v-row>
    <v-col lg="1" xl="0" cols="1">
      <v-menu
        v-model="selectedOpen"
        :value="selectedOpen"
        :close-on-content-click="false"
        :close-on-click="false"
        bottom
      >
        <template v-slot:activator="{ on, attrs }"
          ><v-btn text v-bind="attrs" v-on="on" class="text-none caption">
            <v-icon left class="mt-n1" size="23">mdi-filter-outline</v-icon>
            <div class="ml-n1 mt-n1"><strong>Filtrer</strong></div></v-btn
          >
        </template>
        <v-card height="450" width="250">
          <v-btn
            fab
            absolute
            plain
            right
            small
            elevation="0"
            color="white"
            class="mr-n3 mt-1"
            @click="selectedOpen = false"
            ><v-icon color="primary">mdi-close</v-icon></v-btn
          >
          <v-list class="pt-10">
            <v-list-item>
              <v-btn-toggle
                v-model="selectFormat"
                active-class="btn-selected"
                class="ml-3"
              >
                <v-list-item-content>
                  <v-list-item-title class="mb-2"
                    ><strong>Formats :</strong></v-list-item-title
                  >
                  <v-list-item-subtitle v-for="format in formats" :key="format"
                    ><v-btn
                      plain
                      class="mr-2 my-n3 text-none rounded-circle"
                      :value="format"
                      ><strong>{{ format }}</strong></v-btn
                    ></v-list-item-subtitle
                  ></v-list-item-content
                ></v-btn-toggle
              >
            </v-list-item>
          </v-list>
          <v-list class="mt-n2">
            <v-list-item>
              <v-btn-toggle
                v-model="selectCategorie"
                active-class="btn-selected"
                class="ml-3"
              >
                <v-list-item-content>
                  <v-list-item-title class="mb-2"
                    ><strong>Categories :</strong></v-list-item-title
                  >
                  <v-list-item-subtitle
                    v-for="categorie in categories"
                    :key="categorie"
                    ><v-btn
                      plain
                      class="mr-2 my-n3 text-none rounded-circle"
                      :value="categorie"
                      ><strong>{{ categorie }}</strong></v-btn
                    ></v-list-item-subtitle
                  ></v-list-item-content
                ></v-btn-toggle
              >
            </v-list-item>
          </v-list>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex justify-center mt-n3"
                  ><v-btn
                    color="primary"
                    class="text-none white--text"
                    @click="
                      filterPerFormatAndCategorie(
                        selectCategorie,
                        selectFormat
                      ),
                        (selectedOpen = false)
                    "
                  >
                    Filtrer
                  </v-btn></v-list-item-title
                >
                <v-list-item-title
                  v-if="selectFormat || selectCategorie"
                  class="d-flex justify-center"
                  ><v-btn
                    plain
                    class="text-none mt-1"
                    color="red"
                    @click="reset()"
                  >
                    Réinitialiser
                  </v-btn></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu></v-col
    >
    <v-col lg="11" xl="11" cols="11" align="right">
      <v-btn
        text
        x-small
        class="text-none caption hidden-md-and-up"
        @click="filterByNew()"
        ><strong>Récemment ajoutés</strong></v-btn
      ><v-btn
        text
        x-small
        class="text-none caption hidden-md-and-up ml-n1 mr-n1"
        @click="filterByUse()"
        ><strong>Mes templates utilisés</strong></v-btn
      >
      <v-btn
        plain
        x-small
        class="text-none caption mt-2 hidden-sm-and-down"
        @click="filterByNew()"
        ><strong>Récemment ajoutés</strong></v-btn
      ><v-btn
        plain
        x-small
        class="text-none caption mt-2 hidden-sm-and-down"
        @click="filterByUse()"
        ><strong>Mes templates utilisés</strong></v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CaptionViewBar",

  data: () => ({
    categories: [
      "Expertise",
      "Engagement",
      "Personal branding",
      "Social Proof",
      "Promotion",
    ],
    formats: ["Single Post", "Carrousel", "Reel"],
    selectCategorie: null,
    selectFormat: null,
    selectedOpen: false,
  }),
  methods: {
    filterPerFormatAndCategorie(categorie, format) {
      let ToFilter = [];
      if (categorie) {
        ToFilter.push(categorie);
      } else {
        ToFilter.push(false);
      }
      if (format) {
        ToFilter.push(format);
      } else {
        ToFilter.push(false);
      }
      this.$emit("filter", ToFilter);
    },
    filterByNew() {
      this.$emit("filterByNewest", "filterByNewest");
    },
    filterByUse() {
      this.$emit("filterByUse", "filterByUse");
    },
    reset() {
      this.selectCategorie = null;
      this.selectFormat = null;
      let ToFilter = [];
      ToFilter.push(false);
      ToFilter.push(false);
      this.$emit("filter", ToFilter);
    },
  },
};
</script>

<style>
.btn-toggle-selected-color > * {
  color: #6868a8 !important;
}
.btn-selected {
  color: #ffffff !important;
  background-color: #6868a8;
}
</style>
