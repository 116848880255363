import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const fb = require("@/firebase/init.js");

// handle page reloads
let app;

fb.auth.onAuthStateChanged((user) => {
  store.commit("setUser", user);
  if (!user) {
  } else if (user.isAnonymous == true) {
  } else if (user.isAnonymous == false) {
    store.dispatch("getAdminData");
  }

  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      vuetify,
      store,
      render: (h) => h(App),
    });
  }
});
