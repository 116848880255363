<template>
  <v-dialog v-model="dialogModifPost" :retain-focus="false" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-show="button"
        icon
        x-small
        v-bind="attrs"
        v-on="on"
        @click="
          dataForModif(
            cardItem.caption,
            cardItem.emailInstagram,
            cardItem.categorie,
            cardItem.format,
            cardItem.id,
            cardItem.imgPost
          )
        "
      >
        <v-icon x-small>mdi-note-edit-outline</v-icon>
      </v-btn></template
    >
    <v-card min-height="100%" max-width="100%">
      <v-img
        v-if="imgValidate.length < 2"
        v-model="imgValidate"
        class="white--text align-end"
        height="350px"
        :src="imgValidate[0]"
      >
        <div
          class="d-flex darken-2 v-card--reveal text-h2 white--text"
          style="height: 170px"
        >
          <v-spacer></v-spacer>
          <v-tooltip v-if="visualUrl[0] || cardItem.visual" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                has-bg
                color="white"
                class="my-2 deep-purple--text text--primary-base"
                v-bind="attrs"
                v-on="on"
                @click="useVisual(cardItem.visual)"
                >Utiliser</v-btn
              >
            </template>
            <span>Télécharger l'image</span>
          </v-tooltip>
          <v-tooltip v-else-if="cardItem.imgPost === imgValidate[0]" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                has-bg
                color="white"
                class="my-2 deep-purple--text text--primary-base"
                v-bind="attrs"
                v-on="on"
                @click="downloadImg(cardItem.imgPost, cardItem.id)"
                ><v-icon>mdi-download-circle-outline</v-icon></v-btn
              >
            </template>
            <span>Télécharger l'image</span>
          </v-tooltip>
          <v-dialog v-model="dialogAddPicture" max-width="500" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                has-bg
                color="white"
                class="ma-2 deep-purple--text text--primary-base"
                v-bind="attrs"
                v-on="on"
                @click="(imgSelected = []), getImgBank()"
                >Choisir une autre image
              </v-btn>
            </template>

            <v-card class="mx-auto">
              <v-container class="pa-1">
                <v-item-group v-model="imgSelected" multiple>
                  <v-row dense>
                    <v-col
                      v-for="(item, i) in imgBank"
                      :key="i"
                      cols="12"
                      md="6"
                    >
                      <v-item v-slot="{ active, toggle }" :value="item.url">
                        <v-img
                          :src="item.url"
                          height="150"
                          class="text-right pa-2"
                          @click="toggle"
                        >
                          <v-btn icon>
                            <v-icon color="green">
                              {{
                                active
                                  ? "mdi-checkbox-marked-circle"
                                  : "mdi-checkbox-blank-circle-outline"
                              }}
                            </v-icon>
                          </v-btn>
                          <v-btn
                            v-if="item.imgImportByUser"
                            icon
                            @click="deleteUserImg(item.url), (active = false)"
                          >
                            <v-icon color="red">mdi-delete</v-icon>
                          </v-btn>
                        </v-img>
                      </v-item>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card
                        v-if="totalStorageSizeUsed < 950000000"
                        height="150"
                        elevation="0"
                        style="border: 1px solid #dfe0eb"
                      >
                        <v-card-text
                          >Importer une de vos images<v-file-input
                            v-model="photo"
                            accept="image/*"
                            label="Sélectionner une image"
                            prepend-icon="mdi-file-image-plus-outline"
                            class="mt-2 ml-4"
                            hide-details
                            outlined
                            dense
                          ></v-file-input
                          ><v-btn
                            outlined
                            small
                            @click="saveImgUser()"
                            color="primary"
                            dense
                            :disabled="photo === null"
                            class="mt-4 text-none"
                            >Enregistrer</v-btn
                          ></v-card-text
                        >
                        <v-card-actions> </v-card-actions>
                      </v-card>
                      <v-card v-else color="red" height="150">
                        <v-card-title class="justify-center text-h5"
                          >Espace Saturé</v-card-title
                        >
                        <v-card-text
                          >Pour ajouter de nouvelles images, veuillez en
                          supprimer</v-card-text
                        >
                        <v-card-actions> </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-item-group>
                <v-card-actions
                  ><v-spacer></v-spacer>
                  <v-btn
                    plain
                    small
                    color="grey"
                    class="text-none mr-3 mt-2"
                    @click="dialogAddPicture = false"
                  >
                    Annuler </v-btn
                  ><v-btn
                    small
                    color="primary"
                    class="mt-2 text-none px-6"
                    elevation="0"
                    :disabled="photo !== null"
                    @click="
                      validatePictures(),
                        (dialogAddPicture = false),
                        (visualUrl = [])
                    "
                    >Valider</v-btn
                  ></v-card-actions
                >
              </v-container>
            </v-card> </v-dialog
          ><v-spacer></v-spacer>
        </div>
      </v-img>
      <v-carousel v-else>
        <v-carousel-item
          v-for="(item, i) in imgValidate"
          :key="i"
          :src="item"
          reverse-transition="fade-transition"
          transition="fade-transition"
          class="white--text align-end"
        >
          <v-img class="mt-16 pb-9"></v-img>
          <div class="mt-16 mb-8"></div>
          <v-tooltip v-if="visualUrl[0] || cardItem.visual" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="white"
                class="my-2"
                v-bind="attrs"
                v-on="on"
                @click="useVisual(cardItem.visual)"
                >Utiliser</v-btn
              >
            </template>
            <span>Télécharger l'image</span>
          </v-tooltip>
          <v-tooltip v-else-if="cardItem.imgPost[i] === item" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="white"
                class="mx-2 mt-16"
                elevation="0"
                v-bind="attrs"
                v-on="on"
                @click="downloadImg(cardItem.imgPost[i], cardItem.id)"
                ><v-icon>mdi-download-circle-outline</v-icon></v-btn
              >
            </template>
            <span>Télécharger l'image</span>
          </v-tooltip>
          <v-dialog
            v-model="dialogAddPictureCarrousel"
            max-width="500"
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-16"
                outlined
                color="white"
                v-bind="attrs"
                v-on="on"
                @click="(imgSelected = []), getImgBank()"
                >Choisir une autre image
              </v-btn>
            </template>

            <v-card class="mx-auto">
              <v-container class="pa-1">
                <v-item-group v-model="imgSelected" multiple>
                  <v-row dense>
                    <v-col
                      v-for="(item, i) in imgBank"
                      :key="i"
                      cols="12"
                      md="6"
                    >
                      <v-item v-slot="{ active, toggle }" :value="item.url">
                        <v-img
                          :src="item.url"
                          height="150"
                          class="text-right pa-2"
                          @click="toggle"
                        >
                          <v-btn icon>
                            <v-icon color="green">
                              {{
                                active
                                  ? "mdi-checkbox-marked-circle"
                                  : "mdi-checkbox-blank-circle-outline"
                              }}
                            </v-icon>
                          </v-btn>
                        </v-img>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-item-group>
                <v-card-actions
                  ><v-spacer></v-spacer
                  ><v-btn
                    plain
                    small
                    class="mt-2 text-none px-6"
                    @click="dialogAddPictureCarrousel = false"
                    >Annuler</v-btn
                  ><v-btn
                    small
                    color="primary"
                    class="mt-2 text-none px-6"
                    elevation="0"
                    @click="
                      validatePictures(),
                        (dialogAddPictureCarrousel = false),
                        (visualUrl = [])
                    "
                    >Valider</v-btn
                  ></v-card-actions
                >
              </v-container>
            </v-card>
          </v-dialog></v-carousel-item
        >
      </v-carousel>
      <v-btn
        absolute
        small
        right
        fab
        elevation="0"
        color="primary"
        class="text-none mt-n6 mr-5 hidden-sm-and-up"
        @click="deletePost(cardItem.id)"
        ><v-icon color="red" class="">mdi-delete</v-icon></v-btn
      >
      <v-col class="mt-3 mb-n2 ml-3" align="start" cols="8"
        ><v-chip x-small color="cyan accent-1" label>{{
          formatSelectedForModif
        }}</v-chip
        ><v-chip x-small class="mx-1" color="deep-purple lighten-4" label>
          <span class="text-truncate">
            {{ categorieSelectedForModif }}
          </span></v-chip
        ><v-menu
          v-model="menuModifPost"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              color="black"
              class="text-none"
              text
              v-bind="attrs"
              v-on="on"
              ><strong> Modifier mes paramètres</strong></v-btn
            >
          </template>

          <v-card>
            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-title
                  ><v-chip-group
                    v-model="formatSelectedForModif"
                    mandatory
                    active-class="primary--text"
                  >
                    <v-chip
                      v-for="format in formats"
                      :key="format"
                      :value="format"
                    >
                      {{ format }}
                    </v-chip>
                  </v-chip-group></v-list-item-title
                >
              </v-list-item>

              <v-list-item>
                <v-list-item-title
                  ><v-chip-group
                    v-model="categorieSelectedForModif"
                    mandatory
                    active-class="primary--text"
                  >
                    <v-chip
                      v-for="categorie in categories"
                      :key="categorie"
                      :value="categorie"
                    >
                      {{ categorie }}
                    </v-chip>
                  </v-chip-group></v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-card> </v-menu
        ><v-select
          v-model="emailSelectedForModif"
          :items="user.emailInstagram"
          label="Compte"
        ></v-select
      ></v-col>
      <v-card-text v-if="captionSelectedForModif != 'Template automatique'">
        <v-textarea
          v-model="captionSelectedForModif"
          outlined
          name="input-7-1"
          label="Commencez par écrire vos premières lignes ici."
          auto-grow
        ></v-textarea>
      </v-card-text>
      <v-card-text v-else>
        <v-sheet outlined class="border-sheet mt-n5 pb-10 px-2">
          <v-textarea
            :value="captionBank[0]"
            label=""
            rows="3"
            row-height="25"
            class="hide-scrollbar caption"
            :auto-grow="autoGrowTrueOrFalse"
            no-resize
            hide-details
            readonly
          ></v-textarea>

          <v-btn
            absolute
            small
            outlined
            class="hidden-xs-only mt-1 ml-8 text-none"
            @click="autoGrowTrueOrFalse = !autoGrowTrueOrFalse"
            >Lire +</v-btn
          >
          <v-btn
            absolute
            small
            right
            color="primary"
            class="hidden-xs-only mt-1 mr-6 text-none"
            elevation="0"
            @click="
              (captionSelectedForModif = captionBank[0]),
                (imgSelected = captionBankImg[0]),
                (visualUrl = captionBankVisual[0]),
                validatePictures()
            "
            >Choisir ce template</v-btn
          >
          <v-row dense>
            <v-col></v-col>
            <v-col></v-col>
            <v-col cols="auto">
              <v-btn
                small
                outlined
                class="hidden-sm-and-up mb-n10 text-none"
                @click="autoGrowTrueOrFalse = !autoGrowTrueOrFalse"
                >Lire +</v-btn
              >
              <v-btn
                small
                color="primary"
                class="hidden-sm-and-up mb-n10 ml-2 text-none"
                elevation="0"
                @click="
                  (captionSelectedForModif = captionBank[0]),
                    (imgSelected = captionBankImg[0]),
                    (visualUrl = captionBankVisual[0]),
                    validatePictures()
                "
                >Choisir ce template</v-btn
              ></v-col
            ></v-row
          >
        </v-sheet>
        <v-sheet outlined class="border-sheet mt-4 pb-10 px-2">
          <v-textarea
            :value="captionBank[1]"
            label=""
            rows="3"
            row-height="25"
            class="hide-scrollbar caption"
            :auto-grow="autoGrowTrueOrFalseBis"
            no-resize
            hide-details
            readonly
          ></v-textarea>
          <v-btn
            absolute
            small
            outlined
            class="hidden-xs-only mt-1 ml-8 text-none"
            @click="autoGrowTrueOrFalseBis = !autoGrowTrueOrFalseBis"
            >Lire +</v-btn
          ><v-btn
            absolute
            small
            right
            color="primary"
            class="hidden-xs-only mt-1 mr-6 text-none"
            elevation="0"
            @click="
              (captionSelectedForModif = captionBank[1]),
                (imgSelected = captionBankImg[1]),
                (visualUrl = captionBankVisual[1]),
                validatePictures()
            "
            >Choisir ce template</v-btn
          >
          <v-row dense>
            <v-col></v-col>
            <v-col></v-col>
            <v-col cols="auto">
              <v-btn
                small
                outlined
                class="hidden-sm-and-up mb-n10 text-none"
                @click="autoGrowTrueOrFalseBis = !autoGrowTrueOrFalseBis"
                >Lire +</v-btn
              >
              <v-btn
                small
                color="primary"
                class="hidden-sm-and-up mb-n10 ml-2 text-none"
                elevation="0"
                @click="
                  (captionSelectedForModif = captionBank[1]),
                    (imgSelected = captionBankImg[1]),
                    (visualUrl = captionBankVisual[1]),
                    validatePictures()
                "
                >Choisir ce template</v-btn
              ></v-col
            ></v-row
          >
        </v-sheet>
        <v-sheet outlined class="border-sheet mt-4 pb-10 px-2">
          <v-textarea
            :value="captionBank[2]"
            label=""
            rows="3"
            row-height="25"
            class="hide-scrollbar caption"
            :auto-grow="autoGrowTrueOrFalseTer"
            no-resize
            hide-details
            readonly
          ></v-textarea>
          <v-btn
            absolute
            small
            outlined
            class="hidden-xs-only mt-1 ml-8 text-none"
            @click="autoGrowTrueOrFalseTer = !autoGrowTrueOrFalseTer"
            >Lire +</v-btn
          ><v-btn
            absolute
            small
            right
            color="primary"
            class="hidden-xs-only mt-1 mr-6 text-none"
            elevation="0"
            @click="
              (captionSelectedForModif = captionBank[2]),
                (imgSelected = captionBankImg[2]),
                (visualUrl = captionBankVisual[2]),
                validatePictures()
            "
            >Choisir ce template</v-btn
          >
          <v-row dense>
            <v-col></v-col>
            <v-col></v-col>
            <v-col cols="auto">
              <v-btn
                small
                outlined
                class="hidden-sm-and-up mb-n10 text-none"
                @click="autoGrowTrueOrFalseTer = !autoGrowTrueOrFalseTer"
                >Lire +</v-btn
              >
              <v-btn
                small
                color="primary"
                class="hidden-sm-and-up mb-n10 ml-2 text-none"
                elevation="0"
                @click="
                  (captionSelectedForModif = captionBank[2]),
                    (imgSelected = captionBankImg[2]),
                    (visualUrl = captionBankVisual[2]),
                    validatePictures()
                "
                >Choisir ce template</v-btn
              ></v-col
            ></v-row
          >
        </v-sheet>
      </v-card-text>

      <v-card-actions class="mt-2">
        <v-btn
          small
          icon
          class="text-none ml-4 hidden-xs-only"
          @click="deletePost(cardItem.id)"
          ><v-icon small color="red" class="">mdi-delete</v-icon>
          Supprimer</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          small
          color="grey"
          class="text-none mr-3"
          text
          @click="dialogModifPost = false"
        >
          Annuler
        </v-btn>
        <v-btn
          v-if="captionSelectedForModif != 'Template automatique'"
          small
          color="primary"
          class="text-none mr-3 px-6"
          elevation="0"
          :disabled="updateProfil()"
          @click="
            modifPost(
              idSelectedForModif,
              captionSelectedForModif,
              cardItem.day,
              emailSelectedForModif,
              cardItem.list,
              categorieSelectedForModif,
              formatSelectedForModif,
              visualUrl,
              cardItem.visual
            )
          "
        >
          Sauvegarder
        </v-btn>
        <v-btn
          v-else
          small
          color="primary"
          class="text-none mr-3 px-6"
          elevation="0"
          @click="captionSelectedForModif = ''"
        >
          Rédiger mon template
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebase/init.js");
export default {
  name: "ModifPost",

  props: {
    button: {
      type: Boolean,
      default: true,
    },
    day: {
      type: Object,
      required: true,
    },
    cardItem: {
      type: Object,
      required: false,
    },
  },
  data: () => ({
    autoGrowTrueOrFalse: false,
    autoGrowTrueOrFalseBis: false,
    autoGrowTrueOrFalseTer: false,
    captionBank: [],
    captionBankImg: [],
    captionBankVisual: [],
    captionSelectedForModif: null,
    captionSelectedForModifTemp: null,
    categories: [
      "Expertise",
      "Engagement",
      "Personal branding",
      "Social Proof",
      "Promotion",
    ],
    categorieSelectedForModif: null,
    categorieSelectedForModifTemp: null,
    dialogConfirmationDeleteImg: false,
    dialogModifPost: false,
    dialogAddPicture: false,
    dialogAddPictureCarrousel: false,
    emailSelectedForModif: null,
    emailSelectedForModifTemp: null,
    formats: ["Single Post", "Carrousel", "Reel"],
    formatSelectedForModif: null,
    formatSelectedForModifTemp: null,
    imgBank: [],
    imgSelected: [],

    imgValidate: [
      "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9",
    ],
    imgValidateTemp: [],
    menuModifPost: false,
    photo: null,
    tagsImportUser: [],
    totalStorageSizeUsed: 0,
    visualUrl: [],
    accountRules: [
      (v) => !!v || "Le compte Instagram est nécessaire, exemple : @johnDoe15",
    ],
  }),
  created() {
    if (typeof this.cardItem.imgPost === "string") {
      this.imgValidate[0] = this.cardItem.imgPost;
      this.imgValidateTemp[0] = this.cardItem.imgPost;
    } else {
      this.imgValidate = this.cardItem.imgPost;
      this.imgValidateTemp = this.cardItem.imgPost;
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {
    imgValidate: function (val) {
      if (this.imgValidate.length > 1) {
        this.formatSelectedForModif = "Carrousel";
      }
    },
  },
  methods: {
    dataForModif: function (caption, email, categorie, format, id, img) {
      this.captionSelectedForModif = caption;
      this.emailSelectedForModif = email;
      this.categorieSelectedForModif = categorie;
      this.formatSelectedForModif = format;
      this.idSelectedForModif = id;
      if (typeof img === "string") {
        this.imgValidate[0] = img;
        this.imgValidateTemp[0] = img;
      } else {
        this.imgValidate = img;
        this.imgValidateTemp = img;
      }

      this.captionSelectedForModifTemp = caption;
      this.emailSelectedForModifTemp = email;
      this.categorieSelectedForModifTemp = categorie;
      this.formatSelectedForModifTemp = format;
      this.dialogModifPost = true;
      const self = this;
      let captionBankTemp = [];

      fb.db
        .collection("captions")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let cardUnit = doc.data();
            cardUnit.id = doc.id;
            captionBankTemp.push(cardUnit);
          });
        })
        .then(() => {
          for (let index = 0; index < captionBankTemp.length; index++) {
            if (
              captionBankTemp[index].categorie === this.cardItem.categorie &&
              captionBankTemp[index].format === this.cardItem.format
            ) {
              if (captionBankTemp[index].visual) {
                self.captionBankVisual.push(captionBankTemp[index].visual);
              } else {
                self.captionBankVisual.push(false);
              }
              self.captionBank.push(captionBankTemp[index].caption);
              self.captionBankImg.push(captionBankTemp[index].url);
            }
          }
          for (var i = self.captionBank.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var tempCaption = self.captionBank[i];
            var tempCaptionImg = self.captionBankImg[i];
            var tempCaptionBankVisual = self.captionBankVisual[i];
            self.captionBank[i] = self.captionBank[j];
            self.captionBankImg[i] = self.captionBankImg[j];
            self.captionBankVisual[i] = self.captionBankVisual[j];
            self.captionBank[j] = tempCaption;
            self.captionBankImg[j] = tempCaptionImg;
            self.captionBankVisual[j] = tempCaptionBankVisual;
          }
        })
        .catch((error) => {
          console.log("Error in created(): ", error);
        });
    },
    useVisual(url) {
      if (url) {
        window.open(url);
      } else {
        window.open(this.visualUrl);
      }
    },
    deletePost(cardId) {
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .doc(cardId)
        .delete();
      this.$emit("newPost");
      this.dialogModifPost = false;
    },
    deleteUserImg(url) {
      let imgSize = 0;
      let self = this;
      let searshNameOfImage = fb.db
        .collection("imagesUsers")
        .where("url", "==", url);
      searshNameOfImage
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            imgSize = doc.data().imgSize;
            // Create a reference to the file to delete
            var ImagesRef = fb.storageRef.child(
              "imagesUsers/" + doc.data().name
            );

            // Delete the file
            ImagesRef.delete()
              .then(() => {
                fb.db.collection("imagesUsers").doc(doc.id).delete();
                let urlTemp = [];
                fb.db
                  .collection("clients")
                  .doc(fb.firebase.auth().currentUser.uid)
                  .get()
                  .then((doc) => {
                    if (doc.exists) {
                      let cardUnit = doc.data().tagsImportUser;
                      for (let index = 0; index < cardUnit.length; index++) {
                        if (cardUnit[index] === url) {
                        } else {
                          urlTemp.push(cardUnit[index]);
                        }
                      }
                      self.tagsImportUser = [];
                      self.tagsImportUser = urlTemp;
                      self.totalStorageSizeUsed -= imgSize;
                      fb.db
                        .collection("clients")
                        .doc(fb.firebase.auth().currentUser.uid)
                        .update({
                          tagsImportUser: self.tagsImportUser,
                          totalStorageSizeUsed: self.totalStorageSizeUsed,
                        });
                      let imgBankTemp = [];
                      for (
                        let index = 0;
                        index < self.imgBank.length;
                        index++
                      ) {
                        if (self.imgBank[index].url === url) {
                        } else {
                          imgBankTemp.push(self.imgBank[index]);
                        }
                      }
                      self.imgBank = [];
                      self.imgBank = imgBankTemp;
                    }
                  });

                // File deleted successfully
              })
              .catch((error) => {
                // Uh-oh, an error occurred!
              });
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    downloadImg(url, id) {
      fb.db
        .collection("images")
        .where("url", "==", url)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            url = doc.data().urlRaw;
          });
        })
        .then(() => {
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              var urlTemp = window.URL.createObjectURL(blob);
              var a = document.createElement("a");
              a.href = urlTemp;
              a.download = id + ".jpg";
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();
              a.remove(); //afterwards we remove the element again
              this.$store.commit("setSnackbar", {
                color: "success",
                timeout: 4000,
                text: "Le téléchargement de l'image a bien commencé",
              });
            })
            .catch((error) =>
              console.log("Authorization failed: " + error.message)
            );
        });
    },
    getImgBank() {
      this.imgBank = [];
      let self = this;
      fb.db
        .collection("images")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let cardUnit = doc.data();
            cardUnit.id = doc.id;
            self.imgBank.push(cardUnit);
          });
        })
        .catch((error) => {
          console.log("Error in created(): ", error);
        })
        .then(() => {
          fb.db
            .collection("clients")
            .doc(fb.firebase.auth().currentUser.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let cardUnit = doc.data().tagsImportUser;
                self.tagsImportUser = cardUnit;
                self.totalStorageSizeUsed = doc.data().totalStorageSizeUsed;

                for (let index = 0; index < cardUnit.length; index++) {
                  let cardUnitObject = {
                    url: cardUnit[index],
                    urlRaw: cardUnit[index],
                    imgImportByUser: true,
                  };
                  self.imgBank.push(cardUnitObject);
                }
              }
            });
        })

        .catch((error) => {
          console.log("Error in created(): ", error);
        });
    },
    modifPost(
      id,
      caption,
      day,
      emailInstagram,
      list,
      categorie,
      format,
      visualUrl,
      visualUrlPost
    ) {
      let img = null;
      if (this.imgValidate.length < 2) {
        img = this.imgValidate[0];
      } else {
        img = this.imgValidate;
        this.formatSelected = "Carrousel";
      }
      if (visualUrl) {
        console.log("good");
      }
      console.log(visualUrl[0]);
      console.log(visualUrl);
      console.log(visualUrlPost);
      if (visualUrl[0]) {
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .collection("cards")
          .doc(id)
          .update({
            caption: caption,
            categorie: categorie,
            day: day,
            emailInstagram: emailInstagram,
            format: format,
            imgPost: img,
            list: list,
            visual: visualUrl,
          })
          .then(() => {})
          .catch((error) => {
            console.error("Error updated document: ", error);
          });
      } else {
        fb.db
          .collection("clients")
          .doc(fb.firebase.auth().currentUser.uid)
          .collection("cards")
          .doc(id)
          .update({
            caption: caption,
            categorie: categorie,
            day: day,
            emailInstagram: emailInstagram,
            format: format,
            imgPost: img,
            list: list,
            visual: "",
          })
          .then(() => {})
          .catch((error) => {
            console.error("Error updated document: ", error);
          });
      }

      this.$emit("newPost");
      this.$emit("deleteFlag");
      this.dialogModifPost = false;
    },

    saveImgUser() {
      var self = this;
      var imgSize = null;
      var storageRef = fb.storageRef;
      // Create the file metadata
      var metadata = {
        contentType: "image/jpeg",
      };

      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("imagesUsers/" + this.photo.name)
        .put(this.photo, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        fb.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          imgSize = snapshot.totalBytes;
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case fb.firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case fb.firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          self.$store.commit("setSnackbar", {
            color: "error",
            timeout: 4000,
            text: "Un problème est survenu, veuillez recommencer",
          });
          this.loading = false;
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            self.tagsImportUser.push(downloadURL);
            self.totalStorageSizeUsed += imgSize;
            fb.db
              .collection("clients")
              .doc(fb.firebase.auth().currentUser.uid)
              .update({
                tagsImportUser: self.tagsImportUser,
                totalStorageSizeUsed: self.totalStorageSizeUsed,
              })
              .then(() => {
                let cardUnitObject = {
                  url: downloadURL,
                  urlRaw: downloadURL,
                  imgImportByUser: true,
                  imgSize: imgSize,
                };
                self.imgBank.push(cardUnitObject);
                fb.db
                  .collection("imagesUsers")
                  .add({
                    name: self.photo.name,
                    url: downloadURL,
                    imgSize: imgSize,
                  })
                  .then(() => {})
                  .catch((error) => {
                    console.error("Error updated document: ", error);
                  });
                self.photo = null;
              })
              .catch((error) => {
                self.$store.commit("setSnackbar", {
                  color: "error",
                  timeout: 4000,
                  text: error,
                });
              });
          });
        }
      );
    },
    updateProfil() {
      let flag = false;
      if (this.imgValidate.length === this.imgValidateTemp.length) {
        for (let index = 0; index < this.imgValidate.length; index++) {
          if (this.imgValidate[index] != this.imgValidateTemp[index]) {
            flag = true;
          }
        }
      }

      if (
        flag ||
        this.imgValidate.length != this.imgValidateTemp.length ||
        this.captionSelectedForModif != this.captionSelectedForModifTemp ||
        this.emailSelectedForModif != this.emailSelectedForModifTemp ||
        this.categorieSelectedForModif != this.categorieSelectedForModifTemp ||
        this.formatSelectedForModif != this.formatSelectedForModifTemp
      ) {
        return false;
      } else {
        return true;
      }
    },
    validatePictures() {
      if (typeof this.imgSelected === "string") {
        let imgTemp = this.imgSelected;
        this.imgSelected = [];
        this.imgSelected.push(imgTemp);
      }
      if (this.imgSelected.length < 1) {
        if (this.imgSelected) {
        }
        this.imgSelected.push(
          "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9"
        );
      }
      this.imgValidate = this.imgSelected;
    },
  },
};
</script>
<style>
.transparent {
  background-color: white !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparentImg {
  background-color: transparent !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparent-card {
  background-color: #fafafa;
  opacity: 0.9;
  border-color: transparent !important;
}

.hide-scrollbar textarea {
  overflow-y: hidden;
  line-height: 15px;
}
.border-sheet {
  border: 1px solid rgb(156, 156, 156) !important;
  border-radius: 5px;
}
.v-input.v-textarea > .v-input__control > .v-input__slot:before {
  border: none;
  line-height: 200%;
}
</style>
