<template>
  <v-container>
    <PhotoView />
  </v-container>
</template>

<script>
// @ is an alias to /src
import PhotoView from "../components/PhotoView";

export default {
  name: "Photos",
  components: {
    PhotoView,
  },
  methods: {},
};
</script>
<style></style>
