<template>
  <v-container>
    <CaptionView />
  </v-container>
</template>

<script>
// @ is an alias to /src
import CaptionView from "../components/CaptionView";

export default {
  name: "Templates",
  components: {
    CaptionView,
  },
  methods: {},
};
</script>
<style>
ol,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}
</style>
