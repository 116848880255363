<template>
  <v-container>
    <v-row
      ><v-col></v-col
      ><v-col
        ><v-card width="600">
          <v-form ref="form" v-model="valid">
            <v-card-subtitle class="mt-4 mb-6 font-weight-bold"
              >Ajouter du contenu :
            </v-card-subtitle>
            <v-card-text>
              <v-select
                v-model="typeSelected"
                :items="types"
                label="Type de contenu"
                class="mx-6"
                outlined
                dense
              ></v-select>
            </v-card-text>
            <v-card-text v-if="typeSelected === 'Template'">
              <v-select
                v-model="imgOrCanvaSelected"
                :items="imgOrCanva"
                label="Choisir une photo ou importer un Canva ?"
                class="mx-6"
                outlined
                dense
              ></v-select>
            </v-card-text>
            <v-divider class="mb-4"></v-divider>
            <v-card-text class="ml-n2">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    color="primary"
                    class="text-none mr-4"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    ><strong> Modifier les objectifs</strong></v-btn
                  >
                </template>

                <v-card>
                  <v-divider></v-divider>

                  <v-list>
                    <v-list-item v-if="typeSelected === 'Template'">
                      <v-list-item-title
                        ><v-chip-group
                          v-model="formatSelected"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            v-for="format in formats"
                            :key="format"
                            :value="format"
                          >
                            {{ format }}
                          </v-chip>
                        </v-chip-group></v-list-item-title
                      >
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-title
                        ><v-chip-group
                          v-model="categorieSelected"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            v-for="categorie in categories"
                            :key="categorie"
                            :value="categorie"
                          >
                            {{ categorie }}
                          </v-chip>
                        </v-chip-group></v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
              <v-chip class="ml-10" color="cyan accent-1" label>{{
                formatSelected
              }}</v-chip
              ><v-chip class="ml-10" color="deep-purple lighten-4" label>
                <span class="text-truncate">
                  {{ categorieSelected }}
                </span></v-chip
              >
            </v-card-text>

            <v-divider class="mt-4"></v-divider>
            <v-card-text
              v-if="
                typeSelected === 'Template' && imgOrCanvaSelected === 'Photo'
              "
            >
              <v-dialog v-model="dialogAddPicture" max-width="500" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="ma-2" outlined v-bind="attrs" v-on="on"
                    >Choisir une photo
                  </v-btn>
                </template>

                <v-card class="mx-auto">
                  <v-container class="pa-1">
                    <v-item-group v-model="imgSelected" multiple>
                      <v-row dense>
                        <v-col
                          v-for="(item, i) in imgBank"
                          :key="i"
                          cols="12"
                          md="6"
                        >
                          <v-item v-slot="{ active, toggle }" :value="item">
                            <v-img
                              :src="item.url"
                              height="150"
                              class="text-right pa-2"
                              @click="toggle"
                            >
                              <v-btn icon>
                                <v-icon color="green">
                                  {{
                                    active
                                      ? "mdi-checkbox-marked-circle"
                                      : "mdi-checkbox-blank-circle-outline"
                                  }}
                                </v-icon>
                              </v-btn>
                            </v-img>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-item-group>
                    <v-card-actions
                      ><v-spacer></v-spacer>
                      <v-btn
                        small
                        plain
                        color="primary"
                        class="mt-2 text-none px-6"
                        @click="dialogAddPicture = false"
                        >Fermer</v-btn
                      >
                      <v-btn
                        small
                        color="primary"
                        class="mt-2 text-none px-6"
                        @click="validatePictures(), (dialogAddPicture = false)"
                        >Valider</v-btn
                      ></v-card-actions
                    >
                  </v-container>
                </v-card>
              </v-dialog>
            </v-card-text>
            <v-card-text
              v-else-if="
                typeSelected === 'Template' && imgOrCanvaSelected === 'Canva'
              "
              ><v-card-subtitle class="mb-n2 mt-n3 font-weight-bold">
                Image de présentation du Canva
              </v-card-subtitle>
              <v-file-input
                ref="photoCompressed"
                v-model="photoCompressed"
                accept="image/*"
                label="Cliquez pour télécharger une image Canva"
                prepend-icon="mdi-file-image-plus-outline"
                class="ml-12 mr-6"
                :rules="rulesPhoto"
                show-size
                counter
                outlined
                dense
                required
              ></v-file-input>
              <v-card-subtitle class="mt-n6 mb-n3 font-weight-bold"
                >URL Canva modifiable</v-card-subtitle
              >
              <v-text-field
                ref="urlCanva"
                v-model="urlCanva"
                label="Copié l'URL du visuel modifiable sur Canva"
                class="mx-12"
                :rules="rulesUrl"
                outlined
                dense
                required
              ></v-text-field
            ></v-card-text>
            <v-card-text v-else>
              <v-card-subtitle class="mb-n2 mt-n3 font-weight-bold">
                Image compressée - moins de 2Mo
              </v-card-subtitle>

              <v-file-input
                ref="photoCompressed"
                v-model="photoCompressed"
                accept="image/*"
                label="Cliquez pour télécharger une image compressée"
                prepend-icon="mdi-file-image-plus-outline"
                class="ml-12 mr-6"
                :rules="rulesPhotoCompressed"
                show-size
                counter
                outlined
                dense
                required
              ></v-file-input>
              <v-card-subtitle class="mt-n6 mb-n3 font-weight-bold"
                >Image originale</v-card-subtitle
              >

              <v-file-input
                ref="photo"
                v-model="photo"
                accept="image/*"
                label="Cliquez pour télécharger l'image originale"
                prepend-icon="mdi-file-image-plus-outline"
                class="ml-12 mr-6"
                :rules="rulesPhoto"
                outlined
                dense
                required
              ></v-file-input>
            </v-card-text>
            <v-divider class="mb-8 mt-n1"></v-divider>
            <v-card-text class="mt-n2"
              ><v-textarea
                ref="template"
                v-model="template"
                outlined
                label="Template"
                class="mx-12"
                :rules="rulesCaption"
                required
              ></v-textarea
            ></v-card-text>
            <v-card-text class="mt-n8 mb-6"> </v-card-text>
            <v-card-actions
              ><v-spacer></v-spacer
              ><v-btn
                v-if="
                  imgOrCanvaSelected === 'Photo' && typeSelected === 'Template'
                "
                outlined
                @click="saveContent()"
                color="green"
                :disabled="!valid || !imgValidate.length > 0"
                >Enregistrer</v-btn
              >
              <v-btn
                v-else
                outlined
                @click="saveContent()"
                color="green"
                :disabled="!valid"
                >Enregistrer</v-btn
              ></v-card-actions
            >
          </v-form>
        </v-card>
      </v-col>
      <v-col></v-col
    ></v-row>
  </v-container>
</template>

<script>
const fb = require("@/firebase/init.js");
export default {
  name: "DashBoardAdmin",
  components: {},
  data: () => ({
    template: "",
    categories: [
      "Expertise",
      "Engagement",
      "Personal branding",
      "Social Proof",
      "Promotion",
    ],
    categorieSelected: "Expertise",
    dialogAddPicture: false,
    formats: ["Single Post", "Carrousel", "Reel"],
    formatSelected: "Single Post",
    imgBank: [],
    imgOrCanva: ["Canva", "Photo"],
    imgOrCanvaSelected: "Canva",
    imgSelected: [],
    imgValidate: [],
    menu: false,
    photo: null,
    photoCompressed: null,
    typeSelected: "Template",
    types: ["Template", "Photo"],
    urlForRaw: null,
    urlCanva: null,
    valid: false,
    rulesPhotoCompressed: [
      (value) =>
        !value || value.size < 2100000 || "L'image ne doit pas dépasser 2Mo",
      (v) => !!v || "Sélectionner une image compressée",
    ],
    rulesPhoto: [(v) => !!v || "Sélectionner une image"],
    rulesUrl: [(v) => !!v || "Copier l'URL du modèle"],
    rulesCaption: [(v) => !!v || "Rédiger votre template"],
  }),
  created() {
    const self = this;
    fb.db
      .collection("clients")
      .doc(fb.firebase.auth().currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.exists) {
          if (doc.data().admin) {
          } else {
            this.$router.push("/");
          }
        }
      });
    fb.db
      .collection("images")
      .get()
      .then((snapshot) => {
        self.cardItems = [];
        snapshot.forEach((doc) => {
          let cardUnit = doc.data();
          cardUnit.id = doc.id;
          self.imgBank.push(cardUnit);
        });
      })
      .catch((error) => {
        console.log("Error in created(): ", error);
      });
  },
  watch: {
    imgOrCanvaSelected: function (val) {
      if (this.imgOrCanvaSelected === "Canva") {
        this.imgSelected = [];
        this.imgValidate = [];
      } else if (this.imgOrCanvaSelected === "Photo") {
        this.photoCompressed = null;
        this.urlCanva = null;
      } else {
      }
    },
    typeSelected: function (val) {
      if (this.typeSelected === "Template") {
        this.$refs.template.reset();
        this.photo = null;
        this.$refs.photoCompressed.reset();
        this.urlCanva = null;
      } else if (this.typeSelected === "Photo") {
        this.$refs.photoCompressed.reset();
        this.imgSelected = [];
        this.imgValidate = [];
        this.photoCompressed = null;
        this.urlCanva = null;
        this.template = "Photographe: Nom du photographe";
      } else {
      }
    },
    imgValidate: function (val) {
      if (this.imgValidate.length > 1) {
        this.formatSelected = "Carrousel";
      } else {
        this.formatSelected = "Single Post";
      }
    },
  },
  methods: {
    saveContent() {
      if (this.typeSelected === "Template") {
        this.saveTemplate();
      } else if (this.typeSelected === "Photo") {
        var self = this;
        var storageRef = fb.storageRef;
        this.loading = true;
        // Create the file metadata
        var metadata = {
          contentType: "image/jpeg",
        };

        // Upload file and metadata to the object 'images/mountains.jpg'
        var uploadTaskBis = storageRef
          .child("images/" + this.photo.name)
          .put(this.photo, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTaskBis.on(
          fb.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case fb.firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case fb.firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text: "Un problème est survenu, veuillez recommencer",
            });
            this.loading = false;
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTaskBis.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.urlForRaw = downloadURL;
              this.saveContentPhoto();
            });
          }
        );
      }
    },
    saveContentPhoto() {
      var self = this;
      var storageRef = fb.storageRef;
      var metadata = {
        contentType: "image/jpeg",
      };
      // Upload file and metadata to the object 'images/mountains.jpg'
      var uploadTask = storageRef
        .child("images/" + this.photoCompressed.name)
        .put(this.photoCompressed, metadata);

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(
        fb.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case fb.firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log("Upload is paused");
              break;
            case fb.firebase.storage.TaskState.RUNNING: // or 'running'
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
          self.$store.commit("setSnackbar", {
            color: "error",
            timeout: 4000,
            text: "Un problème est survenu, veuillez recommencer",
          });
          this.loading = false;
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            fb.db
              .collection("images")
              .add({
                url: downloadURL,
                urlRaw: self.urlForRaw,
                caption: self.template,
                format: self.formatSelected,
                categorie: self.categorieSelected,
                date: fb.firebase.firestore.FieldValue.serverTimestamp(),
              })
              .then(() => {})
              .catch((error) => {
                self.$store.commit("setSnackbar", {
                  color: "error",
                  timeout: 4000,
                  text: error,
                });
              });
            console.log("File available at", downloadURL);
            this.loading = false;
            this.template = "Photographe: Nom du photographe";
            this.$refs.photo.reset();
            this.$refs.photoCompressed.reset();
            this.urlForRaw = null;
            self.$store.commit("setSnackbar", {
              color: "success",
              timeout: 5000,
              text: "La photo  a bien été téléchargée",
            });
          });
        }
      );
    },
    saveTemplate() {
      var self = this;
      if (this.imgValidate.length > 0) {
        console.log("Template avec photo");
        let img = [];
        let imgRaw = [];
        if (this.imgValidate.length < 2) {
          img = this.imgValidate[0].url;
          imgRaw = this.imgValidate[0].urlRaw;
        } else {
          this.formatSelected = "Carrousel";
          for (let index = 0; index < this.imgValidate.length; index++) {
            img.push(this.imgValidate[index].url);
            imgRaw.push(this.imgValidate[index].urlRaw);
          }
        }
        fb.db
          .collection("captions")
          .add({
            caption: self.template,
            categorie: self.categorieSelected,
            date: fb.firebase.firestore.FieldValue.serverTimestamp(),
            format: self.formatSelected,
            url: img,
            urlRaw: imgRaw,
          })
          .then(() => {
            this.$refs.template.reset();
            this.imgSelected = [];
            this.imgValidate = [];
            self.$store.commit("setSnackbar", {
              color: "success",
              timeout: 5000,
              text: "La template a bien été créée",
            });
          })
          .catch((error) => {
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text: error,
            });
          });
      } else {
        console.log("Template avec Visuel Canva");

        var storageRef = fb.storageRef;
        var metadata = {
          contentType: "image/jpeg",
        };
        // Upload file and metadata to the object 'images/********.jpg'
        var uploadTask = storageRef
          .child("images/" + this.photoCompressed.name)
          .put(this.photoCompressed, metadata);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          fb.firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case fb.firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case fb.firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;
              // ...
              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 4000,
              text: "Un problème est survenu, veuillez recommencer",
            });
            this.loading = false;
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              fb.db
                .collection("captions")
                .add({
                  caption: self.template,
                  categorie: self.categorieSelected,
                  date: fb.firebase.firestore.FieldValue.serverTimestamp(),
                  format: self.formatSelected,
                  url: downloadURL,
                  urlRaw: downloadURL,
                  visual: self.urlCanva,
                })
                .then(() => {})
                .catch((error) => {
                  self.$store.commit("setSnackbar", {
                    color: "error",
                    timeout: 4000,
                    text: error,
                  });
                });
              this.loading = false;
              this.$refs.template.reset();
              this.$refs.photoCompressed.reset();
              this.$refs.urlCanva.reset();
              self.$store.commit("setSnackbar", {
                color: "success",
                timeout: 5000,
                text: "La Template a bien été créée",
              });
            });
          }
        );
      }
    },

    validatePictures() {
      this.imgValidate = this.imgSelected;
    },
  },
};
</script>
