<template>
  <v-card
    class="drop-zone rounded"
    outlined
    elevation="0"
    @drop="onDrop($event, 1)"
    @dragover.prevent
    @dragenter.prevent
    height="1210"
    ><v-card-title class="justify-center"
      ><v-btn icon dark color="primary" @click="OpenAndCloseUnplannedPost">
        <v-icon large>mdi-close-circle</v-icon></v-btn
      ></v-card-title
    >
    <v-card-subtitle class="mt-3 caption"
      ><font size="3"
        ><strong> Mes posts non planifiés</strong></font
      ></v-card-subtitle
    >
    <v-list-item
      v-if="cardItemsFilter.length == 0"
      three-line
      class="mt-n2 ml-5"
    >
      <v-list-item-content>
        <v-list-item-subtitle class="font-text-card"
          >Aucun post à afficher ici pour le moment</v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>

    <AddPost
      :upHere="upHere"
      :day="day"
      :cardItemsFilter="cardItemsFilter"
      :button="button"
      @newPost="newPost"
    />
    <v-virtual-scroll
      :items="cardItemsFilter"
      :item-height="190"
      min-width="190"
      height="1000"
      max-width="300"
      class="ml-lg-n1 ml-xl-3"
      ><template v-slot:default="{ index, item }">
        <v-col cols="12" align-self="center">
          <v-card
            class="mb-2"
            max-width="260"
            max-height="190"
            draggable
            @dragstart="startDrag($event, item)"
            @click="
              dataForModif(
                item.caption,
                item.emailInstagram,
                item.categorie,
                item.format,
                item.id,
                index,
                item.imgPost,
                flagErrorLoadImg
              )
            "
            ><v-row dense>
              <v-col align="start" class="ml-1"
                ><font size="1">{{ item.emailInstagram }}</font></v-col
              >
              <v-col align="end" class=""
                ><ModifPost
                  ref="ModifPost"
                  :day="day"
                  :cardItem="item"
                  @newPost="newPost"
                  @deleteFlag="deleteFlag" /></v-col
            ></v-row>

            <v-hover
              v-if="
                typeof item.imgPost === 'string' && flagErrorLoadImg === false
              "
              v-slot="{ hover }"
            >
              <v-img
                class="white--text align-end"
                min-height="150px"
                max-height="150px"
                :src="item.imgPost"
                @error="imageLoadError"
                ><v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transparentImg darken-2 text-h2 white--text"
                    style="height: 60px"
                  >
                    <v-spacer></v-spacer
                    ><v-btn 
                      has-bg
                      x-small
                      color="white"
                      class="mt-1 deep-purple--text text--primary-base"
                      >Modifier mon post</v-btn
                    ><v-spacer></v-spacer>
                  </div> </v-expand-transition
                ><v-sheet min-height="45" max-height="50px" class="transparent"
                  ><v-row dense>
                    <v-col cols="5" align="start" class="mt-n1"
                      ><v-chip
                        x-small
                        class="ml-1 pl-1 pr-14"
                        color="cyan accent-1"
                        label
                      >
                        {{ item.format }}
                      </v-chip></v-col
                    ><v-col cols="6" align="start" class="mt-n1"
                      ><v-chip
                        x-small
                        class="pl-1"
                        color="deep-purple lighten-4"
                        label
                      >
                        {{ item.categorie }}
                      </v-chip></v-col
                    >
                  </v-row>
                  <v-list-item three-line class="mt-n9 ml-n3">
                    <v-list-item-subtitle class="font-text-card ml-1">{{
                      item.caption
                    }}</v-list-item-subtitle></v-list-item
                  >
                </v-sheet></v-img
              ></v-hover
            >
            <v-hover v-else-if="flagErrorLoadImg === true" v-slot="{ hover }">
              <v-img
                class="white--text align-end"
                min-width="135"
                height="140px"
                max-height="170"
                src="https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9"
                ><v-expand-transition>
                  <div
                    v-if="hover"
                    class="d-flex transparentImg darken-2 text-h2 white--text"
                    style="height: 60px"
                  >
                    <v-spacer></v-spacer
                    ><v-btn 
                      has-bg
                      x-small
                      color="white"
                      class="mt-1 deep-purple--text text--primary-base"
                      >Modifier mon post</v-btn
                    ><v-spacer></v-spacer>
                  </div> </v-expand-transition
                ><v-sheet min-height="50" max-height="55px" class="transparent"
                  ><v-row dense>
                    <v-col cols="5" align="start" class="mt-n1"
                      ><v-chip
                        x-small
                        class="ml-1 pl-1 pr-14"
                        color="cyan accent-1"
                        label
                      >
                        {{ item.format }}
                      </v-chip></v-col
                    ><v-col cols="6" align="start" class="mt-n1 ml-1"
                      ><v-chip
                        x-small
                        class="pl-1"
                        color="deep-purple lighten-4"
                        label
                      >
                        {{ item.categorie }}
                      </v-chip></v-col
                    >
                  </v-row>
                  <v-list-item three-line class="mt-n8 ml-n3">
                    <v-list-item-subtitle class="font-text-card ml-1">{{
                      item.caption
                    }}</v-list-item-subtitle></v-list-item
                  >
                </v-sheet></v-img
              >
            </v-hover>
            <v-carousel
              v-else-if="typeof item.imgPost === 'object'"
              hide-delimiters
              show-arrows-on-hover
              height="130px"
              max-height="180px"
              class="mb-16"
            >
              <template v-slot:next="{ on, attrs }">
                <v-btn class="" icon small v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-arrow-right</v-icon></v-btn
                >
              </template>
              <template v-slot:prev="{ on, attrs }">
                <v-btn class="" icon small v-bind="attrs" v-on="on"
                  ><v-icon small>mdi-arrow-left</v-icon></v-btn
                >
              </template>

              <v-carousel-item
                v-for="(itemCarrousel, i) in item.imgPost"
                :key="i"
                :src="itemCarrousel"
                reverse-transition="fade-transition"
                transition="fade-transition"
                height="130px"
                max-height="180px"
                class="white--text align-end mb-12"
                ><div class="mt-14"></div>
                <v-icon
                  v-for="(itemCircle, i) in item.imgPost"
                  :key="i"
                  color="white"
                  class="mx-n2"
                  >mdi-circle-small
                </v-icon>
                <v-sheet min-height="50" max-height="55px" class="transparent">
                  <v-row v-if="typeof item.imgPost === 'object'" dense class="">
                    <v-col cols="5" align="start" class=""
                      ><v-chip x-small color="cyan accent-1" label>
                        {{ item.format }}
                      </v-chip></v-col
                    ><v-col cols="6" align="start" class="ml-n2"
                      ><v-chip x-small color="deep-purple lighten-4" label>
                        {{ item.categorie }}
                      </v-chip></v-col
                    >
                  </v-row>
                  <v-list-item
                    v-if="typeof item.imgPost === 'object'"
                    three-line
                    class="mt-n9 ml-n3"
                  >
                    <v-list-item-subtitle class="font-text-card ml-1">{{
                      item.caption
                    }}</v-list-item-subtitle></v-list-item
                  >
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-col>
      </template></v-virtual-scroll
    >
  </v-card>
</template>
<script>
import AddPost from "./AddPost";
import ModifPost from "./ModifPost";
const fb = require("@/firebase/init.js");
export default {
  name: "UnplannedPost",
  components: {
    AddPost,
    ModifPost,
  },
  props: {
    cardItems: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    button: false,
    day: { date: 1 },
    flagErrorLoadImg: false,
    upHere: true,
  }),
  created() {},
  computed: {
    cardItemsFilter() {
      return this.cardItems.filter((item) => item.list === 1);
    },
  },
  watch: {},
  methods: {
    dataForModif(caption, email, categorie, format, id, index, img, flag) {
      if (flag) {
        img =
          "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9";
      }
      this.$refs.ModifPost.dataForModif(
        caption,
        email,
        categorie,
        format,
        id,
        img
      );
    },
    deleteFlag() {
      this.flagErrorLoadImg = false;
    },
    imageLoadError() {
      this.flagErrorLoadImg = true;
    },
    newPost() {
      this.$emit("newPost");
    },
    OpenAndCloseUnplannedPost() {
      this.$emit("OpenAndCloseUnplannedPost");
    },
    onDrop(evt, list) {
      const self = this;
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.cardItems.find((item) => item.id == itemID);
      this.postMove = false;
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .doc(item.id)
        .update({
          day: list,
          list: list,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updated document: ", error);
        });
      this.$emit("newPost");
    },

    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
  },
};
</script>
<style scoped>
.drop-zone {
  padding: 10px;
}

.font-text-card {
  line-height: inherit;
  line-height: 13px;
  font-size: inherit;
  font-size: 10px;
  text-align: start;
}
.transparent {
  background-color: white !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparentImg {
  background-color: transparent !important;
  opacity: 0.9;
  border-color: transparent !important;
}
</style>
