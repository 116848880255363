<template>
  <v-container>
    <v-row class="mt-16"> </v-row>
    <v-sheet
      elevation="0"
      style="border: 1px solid #dfe0eb; border-radius: 3px"
      class="mx-auto"
      width="95%"
      max-width="550"
      max-height="650"
    >
      <v-row class="hidden-sm-and-down pt-16"> </v-row>
      <v-row class="pt-10">
        <v-col xl="3" lg="3" md="3" sm="1" xs="1"></v-col>
        <v-col xl="6" lg="6" md="6" sm="10" xs="10">
          <v-row justify="center" class="Titillium"
            ><h2>Se connecter</h2></v-row
          >
          <v-row class="pt-8 mb-n3 Titillium"><strong>Mail</strong></v-row>
          <v-row
            ><v-text-field
              ref="email"
              v-model="email"
              :rules="emailRules"
              placeholder="john@vuetifyjs.com"
              type="email"
              autocomplete="email"
              outlined
              dense
              autofocus
            ></v-text-field>
          </v-row>
          <v-row class="mt-4 mb-n3 Titillium"
            ><strong>Mot de passe</strong></v-row
          >
          <v-row
            ><v-text-field
              ref="password"
              v-model="password"
              :rules="passwordRules"
              placeholder="Password"
              color="deep-purple"
              autocomplete="password"
              outlined
              dense
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show = !show"
              :type="show ? 'text' : 'password'"
            ></v-text-field>
          </v-row>
          <v-row justify="center">
            <v-btn
              class="text-none mt-4 px-6 Titillium button"
              :disabled="loading"
              :loading="loading"
              elevation="0"
              color="primary"
              height="45"
              @click="login()"
              >Je suis prêt.e à poster !</v-btn
            ></v-row
          >
          <v-row class="mb-16 hidden-sm-and-down"></v-row>
          <v-row class="mb-5"></v-row>
          <v-row class="pt-16" justify="center">
            <v-dialog v-model="dialog" persistent max-width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  text
                  color="#B9B9B9"
                  class="text-none caption Titillium"
                  v-bind="attrs"
                  v-on="on"
                  >Mot de passe oublié</v-btn
                >
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Mot de passe oublié ?
                </v-card-title>

                <v-card-text>
                  Saisissez l'adresse mail associée à votre compte. Nous allons
                  envoyer à cette adresse un lien vous permettant de
                  réinitialiser facilement votre mot de passe.
                  <v-form v-model="isFormValidEmailMiss"
                    ><v-text-field
                      ref="emailMiss"
                      v-model="emailMiss"
                      :rules="emailRules"
                      placeholder="john@doe.com"
                      type="email"
                    ></v-text-field></v-form
                ></v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="dialog = false">
                    Annuler
                  </v-btn>
                  <v-btn color="primary" text @click="resetPassword()">
                    Valider
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog></v-row
          >
          <v-row class="pb-10 mt-4" justify="center">
            <v-btn
              x-small
              bottom
              text
              color="primary"
              class="text-none caption Titillium"
              @click="$router.push('/inscription')"
            >
              <h4>Je crée un compte</h4></v-btn
            ></v-row
          >
        </v-col>
        <v-col xl="3" lg="3" md="3" sm="1" xs="1"></v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
const fb = require("@/firebase/init.js");
export default {
  name: "Login",
  data: () => ({
    email: null,
    emailMiss: null,
    dialog: false,
    formHasErrors: false,
    isFormValidEmailMiss: false,
    loading: false,
    password: null,
    show: false,
    passwordRules: [
      (v) => !!v || "Le mot de passe est nécessaire",
      (v) =>
        (v && v.length >= 6) ||
        "Le mot de passe doit contenir au moins 6 caractères",
      (v) =>
        (v && v.length <= 40) ||
        "Le mot de passe ne peut pas contenir plus de 40 caractères",
    ],
    emailRules: [
      (v) => !!v || "L'email est nécessaire, exemple : john@doe.fr",
      (v) =>
        (v && v.length <= 40) ||
        "L'email ne peut pas contenir plus de 40 caractères",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return (
          pattern.test(value) ||
          "L'email doit être valide, exemple : john@doe.fr"
        );
      },
    ],
  }),

  mounted() {
    if (this.user) {
      this.$router.push("/");
    } else {
    }
  },
  computed: {
    ...mapState(["user"]),
    form() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    async login() {
      this.loading = true;
      if (this.email && this.password) {
        this.formHasErrors = false;
        try {
          const val = await fb.auth.signInWithEmailAndPassword(
            this.email,
            this.password
          );
          this.$router.replace({ name: "Home" });
          this.$store.commit("setSnackbar", {
            color: "success",
            timeout: 3000,
            text: "Connexion réussie",
          });
        } catch (err) {
          if (
            err ==
            "FirebaseError: Firebase: The password is invalid or the user does not have a password. (auth/wrong-password)."
          ) {
            this.$store.commit("setSnackbar", {
              color: "error",
              timeout: 3000,
              text: "Oups, il semble que le mot de passe ne corresponde pas à cette adresse mail, veuillez corriger ou créer un compte.",
            });
          } else {
            this.loading = false;
            this.$store.commit("setSnackbar", {
              color: "error",
              timeout: 3000,
              text: "Oups, il semble qu’aucun compte n’est associé à cette adresse mail, veuillez corriger ou créer un compte.",
            });
          }
        }
        this.loading = false;
      } else {
        Object.keys(this.form).forEach((f) => {
          if (!this.form[f]) this.formHasErrors = true;
          this.$refs[f].validate(true);
          this.loading = false;
        });
      }
    },
    resetPassword() {
      var self = this;
      if (this.emailMiss && this.isFormValidEmailMiss) {
        // Send email Reset password
        fb.auth
          .sendPasswordResetEmail(this.emailMiss)
          .then(() => {
            self.$store.commit("setSnackbar", {
              color: "success",
              timeout: 3000,
              text:
                "Vous allez recevoir un email pour réinitialiser votre mot de passe à l'adresse " +
                self.emailMiss,
            });
          })
          .catch((error) => {
            self.$store.commit("setSnackbar", {
              color: "error",
              timeout: 3000,
              text: error.message + " code erreur : " + error.code,
            });
          });
      }
    },
  },
};
</script>
<style>
.errorM {
  text-align: center;
  color: red;
}
</style>
