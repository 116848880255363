<template>
  <v-container>
    <v-sheet class="hidden-md-and-up" elevation="1"
      ><PhotoViewBar
        ref="PhotoViewBarMobile"
        class="photo-view-bar-mobile"
        @filter="filter"
        @filterByNewest="filterByNewest"
        @filterByUse="filterByUse"
    /></v-sheet>
    <v-row dense class="mx-n4 mt-n1" style="background: #f5f5f5">
      <PhotoViewBar
        ref="PhotoViewBar"
        class="mb-n2 photo-view-bar hidden-sm-and-down"
        @filter="filter"
        @filterByNewest="filterByNewest"
        @filterByUse="filterByUse"
      />

      <v-col class="mt-12 hidden-sm-and-up" cols="12"><h4>Photos</h4></v-col>
      <v-col class="mt-n1" cols="12"></v-col>

      <v-row
        v-if="firstArray.length > 0"
        class="carrousel-mobile pt-3 hidden-sm-and-up mx-1"
        dense
      >
        <v-col cols="6">
          <v-card
            v-for="(item, index) in firstArrayMobile"
            :key="index"
            class="mt-2"
            elevation="1"
            max-width="200"
          >
            <v-img
              class="white--text align-end"
              min-height="90"
              max-height="180"
              :src="item.url"
            >
            </v-img>

            <v-card-actions>
              <v-chip x-small color="cyan accent-1 ml-1" label>{{
                item.format
              }}</v-chip>
              <v-chip x-small color="deep-purple lighten-4" class="ml-1" label>
                {{ item.categorie }}
              </v-chip></v-card-actions
            >

            <v-card-text class="ml-n4 mt-n8">
              <v-list-item one-line>
                <v-list-item-subtitle
                  class="font-text-card caption mr-n8 ml-n1"
                  >{{ item.caption }}</v-list-item-subtitle
                ></v-list-item
              ></v-card-text
            >
            <v-card-actions class="mt-n10 pb-1 mr-n1"
              ><v-spacer></v-spacer>
              <v-btn
                right
                icon
                color="black"
                class="text-none mt-n2"
                @click="saveImg(item.urlRaw, item.id)"
                ><v-icon small>mdi-arrow-collapse-down</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card
            v-for="(item, index) in secondArrayMobile"
            :key="index"
            elevation="1"
            class="mt-2"
            max-width="200"
          >
            <v-img
              class="white--text"
              min-height="90"
              max-height="180"
              :src="item.url"
            >
            </v-img>

            <v-card-actions>
              <v-chip x-small color="cyan accent-1 ml-1" label>{{
                item.format
              }}</v-chip>
              <v-chip x-small color="deep-purple lighten-4" class="ml-1" label>
                {{ item.categorie }}
              </v-chip></v-card-actions
            >

            <v-card-text class="ml-n4 mt-n8">
              <v-list-item one-line>
                <v-list-item-subtitle
                  class="font-text-card caption mr-n8 ml-n1"
                  >{{ item.caption }}</v-list-item-subtitle
                ></v-list-item
              ></v-card-text
            >
            <v-card-actions class="mt-n10 pb-1 mr-n1"
              ><v-spacer></v-spacer>
              <v-btn
                right
                icon
                color="black"
                class="text-none mt-n2"
                @click="saveImg(item.urlRaw, item.id)"
                ><v-icon small>mdi-arrow-collapse-down</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="mx-n3 pl-6 pt-4 hidden-sm-and-up" no-gutters>
        <p>
          Vous n’avez pas encore d’élément utilisé, mais c’est ici qu’ils
          apparaitront dès que ce sera le cas.
          <a @click="filterByUse()" style="color: #6868a8"
            ><strong>Revenir en arrière</strong></a
          >
        </p>
      </v-row>

      <v-row
        v-if="firstArray.length > 0"
        class="carrousel mx-n3 pt-4 hidden-xs-only"
        no-gutters
      >
        <v-col cols="3"
          ><v-col v-for="(item, index) in firstArray" :key="index" class="mt-2">
            <v-card min-width="180" max-width="750" elevation="1" class="mx-1">
              <v-img
                class="white--text align-end"
                min-height="150"
                max-height="320"
                :src="item.url"
              >
              </v-img>
              <v-card-subtitle class="pb-0">
                <v-row dense>
                  <v-chip small color="cyan accent-1" label>{{
                    item.format
                  }}</v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="ml-1"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="ml-n4 mt-n5">
                <v-list-item three-line>
                  <v-list-item-subtitle
                    class="font-text-card caption mr-n8 ml-n1"
                    >{{ item.caption }}</v-list-item-subtitle
                  ></v-list-item
                ></v-card-text
              >
              <v-card-actions class="mt-n16 pb-2"
                ><v-spacer></v-spacer>
                <v-btn
                  right
                  icon
                  color="grey"
                  class="text-none"
                  @click="saveImg(item.urlRaw, item.id)"
                  ><v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col></v-col
        >
        <v-col cols="3"
          ><v-col
            v-for="(item, index) in secondArray"
            :key="index"
            class="mt-2"
          >
            <v-card min-width="180" max-width="750" elevation="1" class="mx-1">
              <v-img
                class="white--text align-end"
                min-height="150"
                max-height="320"
                :src="item.url"
              >
              </v-img>
              <v-card-subtitle class="pb-0">
                <v-row dense>
                  <v-chip small color="cyan accent-1" label>
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="ml-1"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="ml-n4 mt-n5">
                <v-list-item three-line>
                  <v-list-item-subtitle
                    class="font-text-card caption mr-n8 ml-n1"
                    >{{ item.caption }}</v-list-item-subtitle
                  ></v-list-item
                ></v-card-text
              >
              <v-card-actions class="mt-n16 pb-2"
                ><v-spacer></v-spacer
                ><v-btn
                  right
                  icon
                  color="grey"
                  class="text-none"
                  @click="saveImg(item.urlRaw, item.id)"
                >
                  <v-icon>mdi-download</v-icon></v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col></v-col
        >
        <v-col cols="3"
          ><v-col v-for="(item, index) in thirdArray" :key="index" class="mt-2">
            <v-card min-width="180" max-width="750" elevation="1" class="mx-1">
              <v-img
                class="white--text align-end"
                min-height="150"
                max-height="320"
                :src="item.url"
              >
              </v-img>
              <v-card-subtitle class="pb-0">
                <v-row dense>
                  <v-chip small color="cyan accent-1" label>
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="ml-1"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="ml-n4 mt-n5">
                <v-list-item three-line>
                  <v-list-item-subtitle
                    class="font-text-card caption mr-n8 ml-n1"
                    >{{ item.caption }}</v-list-item-subtitle
                  ></v-list-item
                ></v-card-text
              >
              <v-card-actions class="mt-n16 pb-2"
                ><v-spacer></v-spacer
                ><v-btn
                  right
                  icon
                  color="grey"
                  class="text-none"
                  @click="saveImg(item.urlRaw, item.id)"
                >
                  <v-icon>mdi-download</v-icon></v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col></v-col
        >
        <v-col cols="3"
          ><v-col
            v-for="(item, index) in fourthArray"
            :key="index"
            class="mt-2"
          >
            <v-card min-width="180" max-width="750" elevation="1" class="mx-1">
              <v-img
                class="white--text align-end"
                min-height="150"
                max-height="320"
                :src="item.url"
              >
              </v-img>
              <v-card-subtitle class="pb-0">
                <v-row dense>
                  <v-chip small color="cyan accent-1" label>
                    {{ item.format }} </v-chip
                  ><v-chip
                    small
                    color="deep-purple lighten-4"
                    class="ml-1"
                    label
                  >
                    {{ item.categorie }}
                  </v-chip>
                </v-row>
              </v-card-subtitle>
              <v-card-text class="ml-n4 mt-n5">
                <v-list-item three-line>
                  <v-list-item-subtitle
                    class="font-text-card caption mr-n8 ml-n1"
                    >{{ item.caption }}</v-list-item-subtitle
                  ></v-list-item
                ></v-card-text
              ><v-card-actions class="mt-n16 pb-2"
                ><v-spacer></v-spacer
                ><v-btn
                  right
                  icon
                  color="grey"
                  class="text-none"
                  @click="saveImg(item.urlRaw, item.id)"
                >
                  <v-icon>mdi-download</v-icon></v-btn
                ></v-card-actions
              >
            </v-card>
          </v-col></v-col
        >
      </v-row>
      <v-row v-else class="carrousel mx-n3 pl-4 pt-4 hidden-xs-only" no-gutters>
        <p>
          Vous n’avez pas encore d’élément utilisé, mais c’est ici qu’ils
          apparaitront dès que ce sera le cas.
          <a @click="filterByUse()" style="color: #6868a8"
            ><strong>Revenir en arrière</strong></a
          >
        </p>
      </v-row>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapState } from "vuex";
import PhotoViewBar from "@/components/PhotoViewBar.vue";
const fb = require("@/firebase/init.js");

export default {
  name: "PhotoView",
  components: {
    PhotoViewBar,
  },
  data() {
    return {
      imgList: [],
      firstArray: [],
      firstArrayMobile: [],
      secondArray: [],
      secondArrayMobile: [],
      thirdArray: [],
      fourthArray: [],
      recentOrReset: false,
      useOrReset: false,
    };
  },
  created() {
    const self = this;
    fb.db
      .collection("images")
      .get()
      .then((snapshot) => {
        self.imgList = [];
        snapshot.forEach((doc) => {
          let imgUnit = doc.data();
          imgUnit.id = doc.id;
          self.imgList.push(imgUnit);
        });
      })
      .then(() => {
        let countMobile = 0;
        for (const [key, value] of Object.entries(self.imgList)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }
        let count = 0;
        for (const [key, value] of Object.entries(self.imgList)) {
          if (count === 0) {
            self.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            self.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            self.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            self.fourthArray.push(value);
            count = 0;
          }
        }
      })
      .catch((error) => {
        console.log("Error in created(): ", error);
      });
  },
  computed: {
    ...mapState(["user"]),
  },

  methods: {
    filter(firstArrayToFilter) {
      this.firstArrayMobile = [];
      this.secondArrayMobile = [];
      this.firstArray = [];
      this.secondArray = [];
      this.thirdArray = [];
      this.fourthArray = [];
      let imgListTempBis = [];
      imgListTempBis = structuredClone(this.imgList);
      for (let index = 0; index < firstArrayToFilter.length; index++) {
        if (firstArrayToFilter[index] === false) {
        } else {
          if (index === 0) {
            imgListTempBis = imgListTempBis.filter(
              (item) => item.categorie === firstArrayToFilter[index]
            );
          } else if (index === 1) {
            imgListTempBis = imgListTempBis.filter(
              (item) => item.format === firstArrayToFilter[index]
            );
          }
        }
      }
      let countMobile = 0;
      for (const [key, value] of Object.entries(imgListTempBis)) {
        if (countMobile === 0) {
          this.firstArrayMobile.push(value);
          countMobile = 1;
        } else if (countMobile === 1) {
          this.secondArrayMobile.push(value);
          countMobile = 0;
        }
      }

      let count = 0;
      for (const [key, value] of Object.entries(imgListTempBis)) {
        if (count === 0) {
          this.firstArray.push(value);
          count = 1;
        } else if (count === 1) {
          this.secondArray.push(value);
          count = 2;
        } else if (count === 2) {
          this.thirdArray.push(value);
          count = 3;
        } else if (count === 3) {
          this.fourthArray.push(value);
          count = 0;
        }
      }
    },
    filterByNewest() {
      if (this.recentOrReset) {
        this.recentOrReset = false;
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];
        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];

        let countMobile = 0;
        for (const [key, value] of Object.entries(this.imgList)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }

        let count = 0;
        for (const [key, value] of Object.entries(this.imgList)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      } else {
        this.recentOrReset = true;
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];
        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];
        let imgListTempBis = [];
        imgListTempBis = structuredClone(this.imgList);
        function compare(a, b) {
          if (a.date.seconds < b.date.seconds) {
            return 1;
          }
          if (a.date.seconds > b.date.seconds) {
            return -1;
          }
          return 0;
        }
        imgListTempBis.sort(compare);

        let countMobile = 0;
        for (const [key, value] of Object.entries(imgListTempBis)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }
        let count = 0;
        for (const [key, value] of Object.entries(imgListTempBis)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      }
    },
    filterByUse() {
      if (this.useOrReset) {
        this.useOrReset = false;
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];
        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];

        let countMobile = 0;
        for (const [key, value] of Object.entries(this.imgList)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }

        let count = 0;
        for (const [key, value] of Object.entries(this.imgList)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      } else {
        this.useOrReset = true;
        let listOfImageUseFilter = [];

        for (let index = 0; index < this.imgList.length; index++) {
          for (let i = 0; i < this.user.tagsImage.length; i++) {
            if (this.imgList[index].id === this.user.tagsImage[i]) {
              listOfImageUseFilter.push(this.imgList[index]);
            }
          }
        }
        this.firstArrayMobile = [];
        this.secondArrayMobile = [];

        this.firstArray = [];
        this.secondArray = [];
        this.thirdArray = [];
        this.fourthArray = [];
        let countMobile = 0;
        for (const [key, value] of Object.entries(listOfImageUseFilter)) {
          if (countMobile === 0) {
            this.firstArrayMobile.push(value);
            countMobile = 1;
          } else if (countMobile === 1) {
            this.secondArrayMobile.push(value);
            countMobile = 0;
          }
        }
        let count = 0;
        for (const [key, value] of Object.entries(listOfImageUseFilter)) {
          if (count === 0) {
            this.firstArray.push(value);
            count = 1;
          } else if (count === 1) {
            this.secondArray.push(value);
            count = 2;
          } else if (count === 2) {
            this.thirdArray.push(value);
            count = 3;
          } else if (count === 3) {
            this.fourthArray.push(value);
            count = 0;
          }
        }
      }
    },
    saveImg(url, id) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          var urlTemp = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = urlTemp;
          a.download = id + ".jpg";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          this.$store.commit("setSnackbar", {
            color: "success",
            timeout: 4000,
            text: "Le téléchargement de l'image a bien commencé",
          });
        })
        .catch((error) =>
          console.log("Authorization failed: " + error.message)
        );
    },
  },
};
</script>
<style scoped>
.photo-view-bar {
  position: relative;
  background-color: white;
  height: 70px;
  border: solid 2px var(--grey-100);
  border-radius: 5px;
}
.photo-view-bar-mobile {
  position: relative;
  background-color: white;
  height: 52px;
}
.carrousel {
  position: relative;
  background-color: #fafafa;
  border: inherit;
  border: solid 2px var(--grey-100);
}
.carrousel-mobile {
  position: relative;
  border: inherit;
}
</style>
