<template>
  <v-layout text-xs-center wrap align-center justify-center row fill-height>
    <v-flex xs12>
      <v-layout column align-center>
        <v-flex>
          <h2 class="font-weight-bold mb-3 mx-5">
            Merci de cliquer sur le bouton <strong>Upgrade</strong> pour mettre
            à niveau votre compte.
          </h2>
          <v-btn
            class="text-none mx-auto"
            color="primary"
            elevation="0"
            :loading="loading"
            @click="portailClient()"
            >Upgrade</v-btn
          >
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
import { loadStripe } from "@stripe/stripe-js";
const fb = require("@/firebase/init.js");
export default {
  name: "UpgradeAccount",
  data: () => ({
    loading: false,
    offer: null,
    billing: null,
    priceTemp: "price_1Lrd2BCxGLgtuUQ0AZaImdO3",
  }),
  created() {
    fb.db
      .collection("clients")
      .doc(fb.firebase.auth().currentUser.uid)
      .onSnapshot((doc) => {
        this.offer = doc.data().offer;
        this.billing = doc.data().billing;
        if (this.offer == "solo") {
          if (this.billing == "monthly") {
            this.priceTemp = "price_1Lrd2BCxGLgtuUQ0AZaImdO3";
          } else {
            this.priceTemp = "price_1Lrd2BCxGLgtuUQ0zvDRtGLO";
          }
        } else if (this.offer == "mult") {
          if (this.billing == "monthly") {
            this.priceTemp = "price_1Lrd2ICxGLgtuUQ0WmcyeiG3";
          } else {
            this.priceTemp = "price_1Lrd2ICxGLgtuUQ0c7Gbdlvb";
          }
        } else {
        }
      });
  },
  mounted() {},
  methods: {
    async portailClient() {
      this.loading = true;
      const docRef = fb.db
        .collection("customers")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("checkout_sessions")
        .add({
          price: this.priceTemp,
          allow_promotion_codes: true,
          success_url: window.location.origin,
          cancel_url: window.location.origin + "/waitcreationstripeaccount",
        })
        .then((docRef) => {
          docRef.onSnapshot(async (snap) => {
            const { error, sessionId } = snap.data();
            if (sessionId) {
              const stripe = await loadStripe(
                "pk_live_51LUpHgCxGLgtuUQ0OOH5mpWt6bCwUfNs3LgSdcf64LrqJ1km5ERmsqcPpyhAVUW21zECUbYr5XDRIKgHRHYMcelp003QhGsdO1"
              );
              await stripe.redirectToCheckout({ sessionId });
              this.loading = false;
            }
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>
<style></style>
