<template>
  <v-dialog v-model="dialogChooseCaption" :retain-focus="false" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        right
        outlined
        color="grey"
        class="text-none"
        v-bind="attrs"
        v-on="on"
        ><strong>Voir</strong> <v-icon x-small>mdi-plus</v-icon>
      </v-btn></template
    >
    <v-card min-height="100%" max-width="100%">
      <v-img
        v-if="typeof item.url === 'string'"
        class="white--text align-end"
        height="360px"
        :src="item.urlRaw"
        ><div
          class="d-flex darken-2 v-card--reveal text-h2 white--text"
          style="height: 170px"
        >
          <v-spacer></v-spacer>
          <v-btn
            v-if="item.visual"
            has-bg
            color="white"
            class="my-2 deep-purple--text text--primary-base"
            @click="copy(item.visual)"
            >Utiliser le visuel
          </v-btn>
          <v-btn
            v-else
            has-bg
            color="white"
            class="my-2 deep-purple--text text--primary-base"
            @click="saveImg(item.urlRaw, item.id)"
            >Télécharger l'image
          </v-btn>
          <v-spacer></v-spacer>
        </div>
      </v-img>
      <v-carousel v-else height="360px">
        <v-carousel-item
          v-for="(itemCarrousel, i) in item.url"
          :key="i"
          :src="itemCarrousel"
          reverse-transition="fade-transition"
          transition="fade-transition"
          class="white--text align-end"
        >
          <v-img class="mt-16 pb-9"></v-img>
          <div class="mt-16 mb-8"></div>
          <v-btn
            v-if="item.visual"
            has-bg
            color="white"
            class="my-2 deep-purple--text text--primary-base"
            @click="copy(item.visual)"
            >Utiliser le visuel
          </v-btn>
          <v-btn
            v-else
            has-bg
            color="white"
            class="my-2 deep-purple--text text--primary-base"
            @click="saveImg(item.urlRaw[i], item.id)"
            >Télécharger l'image
          </v-btn>
        </v-carousel-item>
      </v-carousel>
      <v-col class="mt-3 mb-n2 ml-3" align="start" cols="8"
        ><v-chip v-if="item" small color="cyan accent-1" label>{{
          item.format
        }}</v-chip
        ><v-chip
          v-if="item"
          small
          class="mx-1"
          color="deep-purple lighten-4"
          label
        >
          {{ item.categorie }}</v-chip
        ><v-select
          v-if="user"
          v-model="emailInstagramForPost"
          :items="user.emailInstagram"
          label="Compte"
          class="mt-4 mb-1"
        ></v-select
      ></v-col>
      <v-card-text>
        <v-textarea
          v-if="item"
          v-model="item.caption"
          outlined
          name="input-7-1"
          label="Template"
          auto-grow
        >
        </v-textarea>
      </v-card-text>
      <v-card-actions class="mt-n10">
        <v-spacer></v-spacer>
        <v-btn
          small
          color="grey"
          class="text-none mr-3"
          text
          @click="(dialogChooseCaption = false), (item.caption = captionTemp)"
        >
          Annuler
        </v-btn>
        <v-btn
          small
          color="primary"
          class="text-none mr-3 px-6"
          elevation="0"
          @click="saveTemplate(item.id)"
        >
          Sauvegarder
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
const fb = require("@/firebase/init.js");
export default {
  name: "ModifCaption",
  props: {
    item: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    captionTemp: null,
    dialogChooseCaption: false,
    emailInstagramForPost: null,
  }),
  created() {
    this.emailInstagramForPost = this.user.emailInstagram[0];
    this.captionTemp = this.item.caption;
  },
  computed: {
    ...mapState(["user"]),
  },
  watch: {},
  methods: {
    copy(url) {
      navigator.clipboard.writeText(url);
      this.$store.commit("setSnackbar", {
        color: "success",
        timeout: 5000,
        text: "L'URL du modèle a bien été copiée dans le presse papier",
      });
      window.open(url);
    },
    saveTemplate(id) {
      let tempTags = [];
      if (this.user.tagsCaption[0] === "") {
      } else {
        tempTags = this.user.tagsCaption;
      }
      let flag = false;
      for (let index = 0; index < tempTags.length; index++) {
        if (tempTags[index] === id) {
          flag = true;
        }
      }
      if (flag === true) {
      } else {
        tempTags.push(id);
      }
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .add({
          caption: this.item.caption,
          emailInstagram: this.emailInstagramForPost,
          format: this.item.format,
          categorie: this.item.categorie,
          day: 1,
          imgPost: this.item.url,
          list: 1,
          createdOn: fb.firebase.firestore.FieldValue.serverTimestamp(),
        })
        .catch((error) => {
          console.error("Error updated document: ", error);
        });
      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .update({
          tagsCaption: tempTags,
        })
        .then(() => {
          this.dialogChooseCaption = false;
          this.$store.commit("setSnackbar", {
            color: "success",
            timeout: 5000,
            text: "Le post a bien été créé dans vos posts non planifiés",
          });
        })
        .catch((error) => {
          this.dialogChooseCaption = false;
          console.error("Error updated document: ", error);
          this.$store.commit("setSnackbar", {
            color: "error",
            timeout: 5000,
            text: error,
          });
        });
    },
    saveImg(url, id) {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          var urlTemp = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = urlTemp;
          a.download = id + ".jpg";
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
          this.$store.commit("setSnackbar", {
            color: "success",
            timeout: 4000,
            text: "Le téléchargement de l'image a bien commencé",
          });
        })
        .catch((error) =>
          console.log("Authorization failed: " + error.message)
        );
    },
  },
};
</script>
<style scoped>
.transparent {
  background-color: white !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparentImg {
  background-color: transparent !important;
  opacity: 0.9;
  border-color: transparent !important;
}
.transparent-card {
  background-color: #fafafa;
  opacity: 0.9;
  border-color: transparent !important;
}
</style>
