<template>
  <v-layout text-xs-center wrap align-center justify-center row fill-height>
    <v-flex xs12>
      <v-layout column align-center>
        <v-flex>
          <h2 class="font-weight-bold mb-3 mx-5">
            Vous avez une facture impayée. Pour continuer à utiliser nos
            services, merci de régulariser votre dernière facture.
          </h2>
          <v-btn
            class="text-none mx-auto"
            color="primary"
            elevation="0"
            @click="portailClient()"
            >Régler ma facture</v-btn
          >
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
const fb = require("@/firebase/init.js");
export default {
  mounted() {},
  methods: {
    async portailClient() {
      const functionRef = fb.firebase
        .app()
        .functions("europe-west1")
        .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
      const { data } = await functionRef({
        returnUrl: window.location.origin,
      });
      window.location.assign(data.url);
    },
  },
};
</script>
<style></style>
