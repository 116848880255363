<template>
  <v-layout
    text-xs-center
    wrap
    align-center
    justify-center
    row
    fill-height
    @click="$router.push('/')"
  >
    <v-flex xs12>
      <v-layout column align-center>
        <v-flex>
          <h1 class="display-2 font-weight-bold mb-3">
            Votre inscription est bien prise en compte, vous allez être
            recontacté.e très rapidement !
          </h1>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>
<script>
const fb = require("@/firebase/init.js");
export default {
  async mounted() {
    try {
      const data = await fb.auth.signOut();
    } catch (err) {
      console.log(err);
    }
    setTimeout(function () {
      self.$router.push("/login");
    }, 2500);
  },
};
</script>
<style></style>
