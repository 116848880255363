<template>
  <li
    class="calendar-day"
    :class="{
      'calendar-day--not-current': !day.isCurrentMonth,
      'calendar-day--today': isToday,
      'calendar-day-move': postMove,
    }"
    @drop="onDrop($event, day.date)"
    @dragenter.prevent
    @dragover.prevent
  >
    <span @click="addPost(day, cardItemsFilter)">
      <font size="1">{{ label }}</font>
    </span>
    <AddPost
      ref="AddPost"
      :day="day"
      :button="true"
      :cardItemsFilter="cardItemsFilter"
      @newPost="newPost"
    />
    <div v-for="(cardItem, index) in cardItemsFilter" :key="cardItem.id">
      <ModifPost
        ref="ModifPost"
        :day="day"
        :cardItem="cardItem"
        :button="false"
        @newPost="newPost"
        @deleteFlag="deleteFlag"
      />
      <div
        class="drag-el mt-7 mx-auto cardMobile"
        max-width="90"
        max-height="50"
        elevation="1"
        draggable
        @dragstart="startDrag($event, cardItem)"
        @click="
          dataForModif(
            cardItem.caption,
            cardItem.emailInstagram,
            cardItem.categorie,
            cardItem.format,
            cardItem.id,
            index,
            cardItem.imgPost,
            flagErrorLoadImg
          )
        "
      >
        <v-img
          v-if="typeof cardItem.imgPost === 'string'"
          max-height="1"
          max-width="1"
          :src="cardItem.imgPost"
          @error="imageLoadError"
        ></v-img>
        <v-img
          v-else
          max-height="1"
          max-width="1"
          :src="cardItem.imgPost[0]"
          @error="imageLoadError"
        ></v-img>
        <v-row dense>
          <v-chip x-small class="pl-1 format-width" color="cyan accent-1" label>
            {{ cardItem.format }}
          </v-chip>
        </v-row>
        <v-row dense
          ><v-chip
            x-small
            class="pl-1"
            color="format-width deep-purple lighten-4"
            label
          >
            {{ cardItem.categorie }}
          </v-chip></v-row
        >
      </div>
    </div>
  </li>
</template>

<script>
import AddPost from "./AddPost";
import ModifPost from "./ModifPost";
import dayjs from "dayjs";
const fb = require("@/firebase/init.js");

export default {
  name: "CalendarMonthDayItemVersionMobile",
  components: {
    AddPost,
    ModifPost,
  },

  props: {
    day: {
      type: Object,
      required: true,
    },

    isCurrentMonth: {
      type: Boolean,
      default: false,
    },

    isToday: {
      type: Boolean,
      default: false,
    },
    cardItems: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    postMove: false,
    flagErrorLoadImg: false,
  }),
  created() {},
  computed: {
    cardItemsFilter() {
      return this.cardItems.filter((item) => item.list === this.day.date);
    },
    label() {
      return dayjs(this.day.date).format("D");
    },
  },
  methods: {
    addPost(day, cards) {
      this.$refs.AddPost.addPost(day, cards);
    },
    dataForModif(caption, email, categorie, format, id, index, img, flag) {
      if (flag) {
        img =
          "https://firebasestorage.googleapis.com/v0/b/pret-a-poster.appspot.com/o/images%2FImageParDefaut.png?alt=media&token=42434257-b9dd-4faa-bf4a-9f22d46c96a9";
      }
      this.$refs.ModifPost[index].dataForModif(
        caption,
        email,
        categorie,
        format,
        id,
        img
      );
    },
    deleteFlag() {
      this.flagErrorLoadImg = false;
    },
    imageLoadError() {
      this.flagErrorLoadImg = true;
    },
    newPost() {
      this.$emit("newPost");
    },
    onDrop(evt, list) {
      const self = this;
      const itemID = evt.dataTransfer.getData("itemID");
      const item = this.cardItems.find((item) => item.id == itemID);
      this.postMove = false;

      fb.db
        .collection("clients")
        .doc(fb.firebase.auth().currentUser.uid)
        .collection("cards")
        .doc(item.id)
        .update({
          caption: item.caption,
          format: item.format,
          categorie: item.categorie,
          day: list,
          imgPost: item.imgPost,
          list: list,
        })
        .then(() => {})
        .catch((error) => {});
      this.$emit("newPost");
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemID", item.id);
    },
  },
};
</script>
<style scoped>
.calendar-day {
  position: relative;
  max-width: 150px;
  min-height: 100px;
  font-size: 16px;
  background-color: #fafafa;
  color: var(--grey-800);
  padding: 5px;
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1px;
  width: var(--day-label-size);
  height: var(--day-label-size);
}

.calendar-day--not-current {
  background-color: #f5f5f5;
  color: var(--grey-300);
}

.calendar-day--today {
  padding-top: 4px;
}

.calendar-day--today > span {
  color: #fff;
  border-radius: 9999px;
  background-color: #6868a8;
}
.calendar-day-move {
  background-color: white;
  color: red;
  box-shadow: 3px 9px 9px 1px rgba(196, 196, 196, 0.73);
}
.cardMobile {
  background-color: #fafafa;
}
.format-width {
  width: 60px;
}
</style>
